import axios from 'axios';
const url = 'api/';

export default {
  state: { costs: [], prevMonth: [] },

  actions: {
    async getMoneyCosts({ state }) {
      const res = await axios.get(url + `money/costs`);
      state.costs = res.data.curMonth;
      state.prevMonth = res.data.prevMonth;

      return;
    },

    async addMoneyCost({ state }, cost) {
      let status = await axios
        .post(url + `money/costs/add`, cost)
        .then(res => {
          state.costs.push(res.data);
          return 200;
        })
        .catch(err => {
          return 409;
        });

      return status;
    },

    async editMoneyCost({ state }, { cost, editedIndex }) {
      let status = await axios
        .post(url + `money/costs/edit`, cost)
        .then(() => {
          Object.assign(state.costs[editedIndex], cost);
          return 200;
        })
        .catch(err => {
          return 409;
        });

      return status;
    },

    async deleteMoneyCost({ state }, { cost, editedIndex, isPrev }) {
      let status = await axios
        .delete(url + `money/costs/${cost._id}`)
        .then(() => {
          if (isPrev) state.prevMonth.splice(editedIndex, 1);
          else state.costs.splice(editedIndex, 1);

          return 200;
        })
        .catch(err => {
          return 409;
        });

      return status;
    },
  },
};
