<template>
  <apexchart
    height="200px"
    type="line"
    :options="options"
    :series="_series"
  ></apexchart>
</template>

<script>
export default {
  name: 'Chart',
  props: ['_stats', 'title'],

  data() {
    return {
      options: {
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: 'mine',
          style: {
            fontSize: '14px',
            fontFamily: 'Roboto',
          },
        },
        title: {
          text: '',
          align: 'center',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            color: '#e1e3e6',
          },
        },
        colors: [
          '#E6457A',
          '#937ff5',
          '#2EBFE6',
          '#FFC64D',
          '#96E946',
          '#FA71A8',
        ],

        chart: {
          sparkline: {
            enabled: true,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          foreColor: '#e1e3e6',
          id: '',
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        xaxis: {
          categories: [
            '0:00-09', '0:10-19', '0:20-29', '0:30-39', '0:40-49', '0:50-59', '1:00-09', '1:10-19', '1:20-29', '1:30-39', '1:40-49', '1:50-59', '2:00-09', '2:10-19', '2:20-29', '2:30-39', '2:40-49', '2:50-59', '3:00-09', '3:10-19', '3:20-29', '3:30-39', '3:40-49', '3:50-59', '4:00-09', '4:10-19', '4:20-29', '4:30-39', '4:40-49', '4:50-59', '5:00-09', '5:10-19', '5:20-29', '5:30-39', '5:40-49', '5:50-59', '6:00-09', '6:10-19', '6:20-29', '6:30-39', '6:40-49', '6:50-59', '7:00-09', '7:10-19', '7:20-29', '7:30-39', '7:40-49', '7:50-59', '8:00-09', '8:10-19', '8:20-29', '8:30-39', '8:40-49', '8:50-59', '9:00-09', '9:10-19', '9:20-29', '9:30-39', '9:40-49', '9:50-59', '10:00-09', '10:10-19', '10:20-29', '10:30-39', '10:40-49', '10:50-59', '11:00-09', '11:10-19', '11:20-29', '11:30-39', '11:40-49', '11:50-59', '12:00-09', '12:10-19', '12:20-29', '12:30-39', '12:40-49', '12:50-59', '13:00-09', '13:10-19', '13:20-29', '13:30-39', '13:40-49', '13:50-59', '14:00-09', '14:10-19', '14:20-29', '14:30-39', '14:40-49', '14:50-59', '15:00-09', '15:10-19', '15:20-29', '15:30-39', '15:40-49', '15:50-59', '16:00-09', '16:10-19', '16:20-29', '16:30-39', '16:40-49', '16:50-59', '17:00-09', '17:10-19', '17:20-29', '17:30-39', '17:40-49', '17:50-59', '18:00-09', '18:10-19', '18:20-29', '18:30-39', '18:40-49', '18:50-59', '19:00-09', '19:10-19', '19:20-29', '19:30-39', '19:40-49', '19:50-59', '20:00-09', '20:10-19', '20:20-29', '20:30-39', '20:40-49', '20:50-59', '21:00-09', '21:10-19', '21:20-29', '21:30-39', '21:40-49', '21:50-59', '22:00-09', '22:10-19', '22:20-29', '22:30-39', '22:40-49', '22:50-59', '23:00-09', '23:10-19', '23:20-29', '23:30-39', '23:40-49', '23:50-59',

          ],
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    };
  },

  async beforeMount() {
    this.options.title.text = this.title;
    this.options.chart.id = this.title;
  },

  computed: {
    _series() {
      const data = [];
      if (this._stats){
        for (const day of this._stats) {
          const _data = [];

          for (let i = 0; i < 144; i++) _data.push(0);

          for (const key in day.stats) {
            _data[key] = day.stats[key]
          }

          data.push({
            name: day.title,
            data: _data,
          });

          const _data1 = [];
          _data1[0] = _data[0]
          for (let i = 1; i < 144; i++) {
            _data1[i] = _data[i] + _data1[i-1]
          }

          data.push({
            name: "Всего " + day.title,
            data: _data1,
          });
        }
      }


      return data;
    },
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';
</style>
