<template>
  <div class="main">
    <v-app-bar>
      <v-tabs v-model="tab" centered>
        <v-tab>Задачи</v-tab>
        <v-tab>Прокси</v-tab>
      </v-tabs>
    </v-app-bar>

    <v-dialog
      v-model="dialogDelete"
      v-if="dialogDelete"
      persistent
      max-width="500">
      <v-card>
        <v-card-title class="headline">
          Вы уверены, что хотите удалить
          <span class="server-name">Задачу {{ deletingItem.name }}</span> ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn
            color="error"
            text
            @click="deleteItemConfirm"
            :loading="deletingId == deletingItem._id">
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main>
      <v-tabs-items v-model="tab" touchless id="scroll">
        <v-tab-item class="main-outter">
          <v-data-table
            v-if="!loading"
            v-model="selectedItems"
            :headers="headers"
            :item-class="isTaskEnd"
            :items-per-page="100"
            :items="tasks"
            :loading="loading"
            :search="search"
            :single-select="false"
            checkbox-color="primary"
            class="elevation-1"
            item-key="_id"
            loading-text="Задачи загружаются..."
            multi-sort
            show-select>
            <template v-slot:top>
              <v-toolbar flat>
                <div>Задачи</div>
                <v-spacer />
                <v-autocomplete
                  v-model="search"
                  :items="search_items"
                  dense
                  clearable
                  label="Search" />
                <v-spacer />
                <newTask />
              </v-toolbar>
            </template>

            <template v-slot:[`item.perDay`]="{ item }">
              <div class="perDay">
                <div>
                  <div style="font-weight: bold">
                    {{ item.perDay }}
                  </div>
                  <div class="color">{{ item.stats }}</div>
                </div>
                <div>
                  {{ item.allStats }}
                </div>
              </div>
            </template>

            <template v-slot:[`item.url`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.url.split('/')[2]
                    }}{{
                      item.url.split('/').length > 3
                        ? item.url.split('/')[3].length > 1
                          ? '...'
                          : ''
                        : ''
                    }}
                  </span>
                </template>
                <span>{{ item.url }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.depth`]="{ item }">
              <span> {{ item.depth.min }} - {{ item.depth.max }} </span>
            </template>

            <template v-slot:[`item.referer`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.referer.split('/')[2]
                    }}{{
                      item.referer.split('/').length > 3
                        ? item.referer.split('/')[3].length > 1
                          ? '...'
                          : ''
                        : ''
                    }}
                  </span>
                </template>
                <span>{{ item.referer }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.selector`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" v-if="item.selector">
                    <span v-if="item.selector && item.selector_2">
                      2 Селектора
                    </span>
                    <span v-else style="word-break: break-word">
                      {{ item.selector.split('>')[0]
                      }}{{
                        item.selector.split('>').length > 1 ? '...' : ''
                      }}</span
                    >
                  </span>
                </template>
                <div>{{ item.selector }}</div>
                <div>{{ item.selector_2 }}</div>
              </v-tooltip>
            </template>

            <template v-slot:[`item.dateStart`]="{ item }">
              <span>
                {{ new Date(item.dateStart).toLocaleDateString() }}
              </span>
            </template>
            <template v-slot:[`item.dateEnd`]="{ item }">
              <span>
                {{ new Date(item.dateEnd).toLocaleDateString() }}
              </span>
            </template>

            <template v-slot:[`item.proxy`]="{ item }">
              <MironovProxy :Task="item" :selectedItems="selectedItems" />
              <v-spacer></v-spacer>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                @click.stop.prevent="handleChangePause(item)"
                :loading="item.pause == 'loading'">
                <v-icon
                  v-if="item.status == 'online'"
                  :size="20"
                  :title="item.pause ? 'Play' : 'Pause'"
                  class="icon">
                  {{ item.pause ? 'mdi-play' : 'mdi-pause' }}
                </v-icon>
                <v-icon
                  v-else
                  :size="20"
                  :title="
                    item.status.includes('selector')
                      ? item.status == 'selector'
                        ? 'Ошибка селектора'
                        : 'Ошибка селектора 2'
                      : 'Ошибка загрузки'
                  "
                  class="icon"
                  color="error">
                  mdi-alert
                </v-icon>
              </v-btn>

              <v-btn
                icon
                @click.stop.prevent="editTask(item)"
                :loading="deletingId == item._id"
                title="Edit">
                <v-icon :size="20" class="icon"> mdi-pencil </v-icon>
              </v-btn>

              <v-btn
                icon
                @click.stop.prevent="deleteTask(item)"
                :loading="deletingId == item._id"
                title="Delete">
                <v-icon :size="20" class="icon"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <editTask
            :_dialog="dialogEditing"
            :item="editingItem"
            :setDialog="setDialog" />
        </v-tab-item>

        <v-tab-item class="main-outter">
          <Proxies />
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { mironov_headers } from '@/resources/js/data/cabinet.js';

import Proxies from '@/components/Cabinet/tabs/Proxies/Proxies';

import newTask from './newTask';
import editTask from './editTask';
import MironovProxy from './MironovProxy';

export default {
  name: 'Start',

  components: {
    Proxies,

    newTask,
    editTask,

    MironovProxy,
  },

  data() {
    return {
      loading: true,
      tab: 0,

      dialogDelete: false,
      dialogEditing: false,

      deletingId: null,
      deletingItem: null,
      editingItem: null,

      selectedItems: [],

      headers: mironov_headers,
      search: '',
    };
  },

  computed: {
    ...mapState({
      tasks: state => state.mironov.mironovTasks,
    }),

    search_items() {
      let uniqueNames = this.tasks.reduce(
        (acc, elem) => acc.add(elem.name),
        new Set()
      );

      uniqueNames = Array.from(uniqueNames);

      return uniqueNames;
    },
  },

  async beforeMount() {
    await Promise.all([this.getMironovTasks(), this.getProxiesCollections()]);
    this.loading = false;

    let sec = 10;

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    let interval = async () => {
      if (this.tab == 0) {
        console.log(
          `${new Date().toLocaleString('ru', options)} Loading Tasks...`
        );
        await this.getMironovTasks();
        sec = 5;
      }

      setTimeout(interval, sec * 1000);
    };

    setTimeout(interval, sec * 1000);
  },

  async mounted() {
    try {
      this.signin_log({
        city: ymaps.geolocation.city,
        display: `${window.screen.width}x${window.screen.height}`,
      });
    } catch (e) {
      await new Promise(resolve => setTimeout(() => resolve(), 2000));
      this.signin_log({
        city: ymaps.geolocation.city,
        display: `${window.screen.width}x${window.screen.height}`,
      });
    }
  },

  methods: {
    ...mapActions([
      'getProxiesCollections',
      'getMironovTasks',
      'editMironovTask',
      'deleteMironovTask',
      'signin_log',
    ]),

    isTaskEnd(item) {
      if (new Date(item.dateEnd).getTime() < new Date().getTime())
        return 'taskEnd';
    },

    async handleChangePause(item) {
      this.editMironovTask({ _id: item._id, pause: !item.pause });
      item.pause = 'loading';
    },

    deleteTask(item) {
      if (this.selectedItems.length > 1)
        this.deletingItem = {
          name:
            this.selectedItems.length +
            ' ' +
            this.tasksString(this.selectedItems.length),
          _id: 'length',
        };
      else this.deletingItem = item;

      this.dialogDelete = true;
    },

    tasksString(n) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return 'Задач';
      }
      if (n1 > 1 && n1 < 5) {
        return 'Задачи';
      }
      if (n1 == 1) {
        return 'Задачу';
      }
      return 'Задач';
    },

    editTask(item) {
      this.editingItem = item;
      this.dialogEditing = true;
    },

    setDialog(val) {
      this.dialogEditing = val;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletingItem = null;
      });
    },

    async deleteItemConfirm() {
      if (this.selectedItems.length > 1) {
        this.deletingId = 'length';

        for (let task of this.selectedItems) {
          await this.deleteMironovTask(task._id);
        }
      } else {
        this.deletingId = this.deletingItem._id;

        await this.deleteMironovTask(this.deletingItem._id);
      }
      this.deletingId = null;
      this.deletingItem = null;
      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.main-outter {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  .elevation-1 {
    min-width: 80%;
  }
}

.perDay {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.color {
  color: $gray_200;
}
.color-2 {
  color: $gray_400;
}

.taskEnd {
  opacity: 0.5 !important;
}
</style>
