<template>
  <div id="app">
    <Start />
  </div>
</template>

<script>
import Start from "./components/Start.vue";

import store from "./resources/js/store";

export default {
  name: "App",
  components: {
    Start,
  },
  store, //Connect store
};
</script>

<style lang="scss">
html {
  overflow-y: auto;
  font-family: Roboto, sans-serif;
}
body {
  margin: 0;
}

::-webkit-scrollbar {
  background: transparent;
  left: -100px;
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 32px;
  background-color: #555;
  box-shadow: inset 0 0 2px #333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
