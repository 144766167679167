<template>
  <div>
    <v-btn v-if="Server.pause" icon class="mr5px" title="Сервер на паузе">
      <v-icon :size="22" color="errorAccent"> mdi-pause </v-icon>
    </v-btn>

    <v-btn
      v-else-if="Server.status == 'error'"
      @click.stop.prevent="Start(Server.ip)"
      icon
      class="mr5px"
      title="Сервер не отвечает"
    >
      <v-icon :size="22" color="errorAccent"> mdi-alert-circle </v-icon>
    </v-btn>

    <v-btn
      v-else-if="Server.status == 'deployError'"
      @click.stop.prevent="startDeploy({ server: Server })"
      icon
      class="mr5px"
      title="Произошла ошибка при установке Worker'а"
    >
      <v-icon :size="22" color="errorAccent"> mdi-wrench </v-icon>
    </v-btn>
    <v-btn
      v-else-if="Server.status == 'notDeployed'"
      @click.stop.prevent="startDeploy({ server: Server })"
      icon
      class="mr5px"
      title="Worker не установлен"
    >
      <v-icon :size="22" color="accent"> mdi-close-circle-outline </v-icon>
    </v-btn>
    <v-btn
      v-else-if="Server.status == 'deploying'"
      @click.stop.prevent=""
      icon
      class="mr5px"
      title="Worker устанавливается"
    >
      <v-icon :size="22" color="warning"> mdi-progress-clock </v-icon>
    </v-btn>

    <v-btn
      v-else-if="Server.status == 'online'"
      @click.stop.prevent="startItem(Server)"
      icon
      class="mr5px"
      title="Worker запущен"
    >
      <v-icon :size="22" color="success"> mdi-circle-slice-8 </v-icon>
    </v-btn>
    <v-btn
      v-else-if="Server.status == 'offline'"
      @click.stop.prevent="startItem(Server)"
      icon
      class="mr5px"
      title="Worker не запущен"
    >
      <v-icon :size="22" color="error"> mdi-circle-slice-8 </v-icon>
    </v-btn>
    <v-btn
      v-else-if="Server.status == 'loading'"
      icon
      :loading="true"
      class="mr5px"
      title="Worker не запущен"
    >
      <v-icon :size="22" color="error"> mdi-circle-slice-8 </v-icon>
    </v-btn>
    <v-btn
      v-else-if="Server.status == 'statsError'"
      @click.stop.prevent="startItem(Server)"
      icon
      class="mr5px"
      title="Нет загрузок"
    >
      <v-icon :size="22" color="error"> mdi-message-alert </v-icon>
    </v-btn>

    <v-btn
      v-else
      @click.stop.prevent="startItem(Server)"
      :title="Server.status"
      icon
      class="mr5px"
    >
      <v-icon :size="22" color="error"> mdi-alert </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ControlPanel',
  props: ['Server', 'startItem'],

  methods: {
    ...mapActions(['startDeploy']),

    Start(ip) {
      alert('Необходимо перезапустить сервер: ' + ip);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.mr5px {
  margin-right: 5px;
}
</style>
