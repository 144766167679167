export const serversAsTextRules = [
  v => !!v || 'Необходимо ввести данные',
  v => {
    if (v != undefined) {
      let check = v.split('\n');
      let flag = true;
      for (let str of check) {
        if (
          !/.+?:([0-9]{1,3}[\.]){3}[0-9]{1,3}:.+?:.+?:(?![^:]*www\.).+?:.+?:.+/.test(
            str
          )
        ) {
          flag = false;
          break;
        }
      }
      return flag ? !!v : 'Введены некорректные данные';
    }
  },
];

export const nameRules = [v => !!v || 'Необходимо ввести название сервера'];

export const usernameRules = [
  v => !!v || 'Необходимо ввести имя пользователя сервера',
];

export const passwordRules = [v => !!v || 'Необходимо ввести пароль сервера'];

export const portRules = [
  v => !!v || "Необходимо ввести port сервера (если порта нет, то введите '22'",
  v => /^[0-9]{1,5}$/.test(v) || 'Неверно введен port',
];

export const urlRules = [
  v => !!v || 'Необходимо ввести url сайта',
  v => {
    if (v != undefined) {
      let check = v.split(',');
      let flag = true;
      for (let str of check) {
        if (
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&!\/=\[\]]*)$/.test(
            str
          ) ||
          str == ''
        ) {
          flag = false;
          break;
        }
      }
      return flag ? !!v : 'Введены некорректные данные';
    }
  },
];

export const site_typeRules = [v => !!v || 'Необходимо выбрать тип сайта'];

export const player_typeRules = [v => !!v || 'Необходимо выбрать тип плеера'];

export const worker_typeRules = [v => !!v || 'Необходимо выбрать тип Worker`а'];

export const ipRules = [
  v => !!v || 'Необходимо ввести ip сервера',
  v => /^([0-9]{1,3}[\.]){3}[0-9]{1,3}$/.test(v) || 'Неверно введен ip',
];
