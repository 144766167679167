<template>
  <div class="tabs">
    <v-app-bar>
      <v-tabs v-model="tab" centered>
        <v-tab>Proxies</v-tab>
        <v-tab>Stats</v-tab>
        <v-tab>Errors</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-tabs-items v-model="tab" style="margin-top: 10px">
      <v-tab-item> <Proxies /> </v-tab-item>
      <v-tab-item> <ProxiesStats /> </v-tab-item>
      <v-tab-item style="margin-top: 10px">
        <ProxiesErrors v-if="tab == 2" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Proxies from './Proxies';
import ProxiesStats from './ProxiesStats';
import ProxiesErrors from './Errors/ProxiesErrors';

export default {
  name: '',

  props: [],

  components: { Proxies, ProxiesStats, ProxiesErrors },

  data() {
    return {
      tab: 0,
    };
  },

  computed: {},

  watch: {
    tab(val) {
      if (val == 0) {
        this.url = '';
      }
      localStorage.setItem('proxiesTab', JSON.stringify(val));
    },
  },

  async beforeMount() {
    this.tab = JSON.parse(localStorage.getItem('proxiesTab'));
  },

  async mounted() {},

  methods: {},
};
</script>

<style scoped lang="scss">
.tabs {
  width: 100%;
}
</style>
