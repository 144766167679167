export default [
  {
    text: 'Название',
    value: 'name',
    divider: true,
    align: 'center',
    width: 150,
  },

  {
    text: 'Домен',
    value: 'domain',
    divider: true,
    align: 'center',
    width: 200,
  },
  {
    text: 'Запросы',
    value: 'requestMapLength',
    align: 'center',
    divider: true,
    sortable: false,
    cellClass: 'requests',
  },

  {
    text: 'Данные',
    value: 'data',
    sortable: false,
    divider: true,
  },
  {
    text: 'Проценты',
    value: 'percents',
    sortable: false,
    divider: true,
  },

  {
    text: 'Профили',
    value: 'profileNames',
    sortable: false,
    divider: true,
    cellClass: 'profiles',
  },
  {
    text: 'Интервалы',
    value: 'intervals',
    divider: true,
    align: 'center',
    cellClass: 'intervals',
  },

  { text: 'Дата', value: 'date', align: 'center', divider: true },

  {
    text: 'actions',
    value: 'actions',
    align: 'center',
    width: 150,
    sortable: false,
  },
];
