<template>
  <div @click.shift.right.prevent="copy($event)">
    <v-expansion-panels accordion v-if="loading_proxies">
      <v-expansion-panel v-for="(proxy, index) in 15" :key="index">
        <v-expansion-panel-header>
          <v-skeleton-loader type="text" max-width="500" max-height="15" />
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-else style="padding:10px 20px">
      <v-btn v-if="!groupByIP" @click="groupByIP = true" class="mb-8">
        Группировать по IP
      </v-btn>
      <v-btn v-else @click="groupByIP = false" class="mb-8">
        Группировать по Логину
      </v-btn>
      <div
        v-for="(data, index) in groups"
        :key="'proxies' + index"
        style="margin-bottom:20px"
      >
        <div style="font-weight:bold;font-size:16px">{{ data.header }}</div>
        <div>
          <v-expansion-panels accordion v-model="proxiesPanel[index]">
            <v-expansion-panel
              v-for="(proxy, index) in data.proxies"
              :key="'proxy' + index"
              @click="scrollToView($event, proxy.proxy)"
            >
              <v-expansion-panel-header>
                {{ proxy.proxy }} - {{ proxy.count }}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-skeleton-loader v-if="loading" type="list-item-avatar" />
                <v-skeleton-loader v-if="loading" type="list-item-avatar" />
                <v-skeleton-loader v-if="loading" type="list-item-avatar" />

                <v-timeline v-if="!loading">
                  <v-timeline-item
                    v-for="(ip, ip_index) in ips"
                    :key="ip._id"
                    class="mb-2"
                    color="primary"
                    small
                    fill-dot
                    :style="ip_index % 2 ? 'text-align: right;' : ''"
                  >
                    <template v-slot:icon>
                      {{ ip_index + 1 }}
                    </template>

                    <span :slot="ip_index % 2 ? 'default' : 'opposite'">
                      {{ ip.ip }}
                    </span>

                    <span
                      class="gray_400--text"
                      :slot="ip_index % 2 ? 'opposite' : 'default'"
                    >
                      {{ ip.date }}-{{ ip.date_end }}
                    </span>
                  </v-timeline-item></v-timeline
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>

    <v-snackbar v-model="copy_snackbar" timeout="1500" rounded>
      <div style="text-align:center">
        <v-icon color="success" class="mr-2">
          mdi-check-circle-outline
        </v-icon>
        <span>Прокси скопировано</span>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ListAddresses',

  props: ['proxies_cnt'],

  data() {
    return {
      proxies: [],

      groups: [],
      ips: [],
      proxiesPanel: {},
      panels: [],
      loading_proxies: true,
      loading: true,
      copy_snackbar: false,

      groupByIP: false,
    };
  },

  components: {},

  computed: {
    ...mapState([]),
  },

  watch: {
    groupByIP(val) {
      const groups = {};
      let i = 0;

      for (const proxy of this.proxies) {
        const group = val
          ? proxy.proxy
              .split(':')[0]
              .split('.')
              .slice(0, 3)
              .join('.') + '.x'
          : proxy.login;

        if (!groups[group]) {
          groups[group] = {
            proxies: [],
            header:
              proxy.login +
              ' - ' +
              proxy.proxy
                .split(':')[0]
                .split('.')
                .slice(0, 3)
                .join('.') +
              '.x',
          };

          this.panels.push(i++);
          this.proxiesPanel[group] = -1;
        }

        groups[group].proxies.push(proxy);
      }

      this.groups = groups;
    },
  },

  async beforeMount() {
    const proxies = await this.getMobileProxies();
    this.proxies = proxies;
    const groups = {};
    let i = 0;

    for (const proxy of proxies) {
      const group = this.groupByIP
        ? proxy.proxy
            .split(':')[0]
            .split('.')
            .slice(0, 3)
            .join('.') + '.x'
        : proxy.login;

      if (!groups[group]) {
        groups[group] = {
          proxies: [],
          header:
            proxy.login +
            ' - ' +
            proxy.proxy
              .split(':')[0]
              .split('.')
              .slice(0, 3)
              .join('.') +
            '.x',
        };

        this.panels.push(i++);
        this.proxiesPanel[group] = -1;
      }

      groups[group].proxies.push(proxy);
    }

    this.groups = groups;

    this.loading_proxies = false;
  },

  methods: {
    ...mapActions(['getMobileProxies', 'getMobileProxies_list_proxies']),

    async copy(event) {
      await navigator.clipboard.writeText(event.target.outerText.split(' ')[0]);
      this.copy_snackbar = true;
    },

    async scrollToView(event, proxy) {
      this.loading = true;

      this.ips = await this.getMobileProxies_list_proxies(proxy);

      this.loading = false;

      await new Promise(resolve => setTimeout(resolve, 0.3 * 1000));

      event.target.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';
</style>
