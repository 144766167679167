<template>
  <div class="d-flex align-center">
    <div>
      <pre>Имя:  <b>{{ profile.collection_name }} |</b></pre>
    </div>
    <pre> Используется: </pre>
    <template>
      <div>
        <v-checkbox
          v-model="isChecked"
          @change="handleCheckboxChange"
        />
      </div>
    </template>
    <br>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import rules from './rules';

export default {
  name: 'TaskProfile',

  props: ['profile', 'taskProfiles', 'updateRequests'],

  data() {
    return {
      ...rules(),

      saveTimeout: null,
    };
  },

  components: {},

  computed: {
    isChecked: {
      // Getter возвращает значение, показывающее, активирован ли чекбокс
      get() {
        return this.taskProfiles?.profiles && this.taskProfiles.profiles.includes(this.profile._id);
      },
      // Setter обновляет данные при изменении состояния чекбокса
      set(newValue) {
        if (newValue) {
          // Логика добавления request._id в taskRequests.requests
          // Если request._id еще не в массиве, добавляем его
          if (!this.taskProfiles.profiles.includes(this.profile._id)) {
            this.taskProfiles.profiles.push(this.profile._id);
          }
        } else {
          // Логика удаления request._id из taskRequests.requests
          // Если request._id есть в массиве, удаляем его
          const index = this.taskProfiles.profiles.indexOf(this.profile._id);
          if (index > -1) {
            this.taskProfiles.profiles.splice(index, 1);
          }
        }
        // Здесь вы можете вызвать методы для обновления данных на сервере или в Vuex store
      }
    }
  },

  watch: {},

  async beforeMount() {},

  async mounted() {},

  methods: {
    ...mapActions('yandex', ['editRequest', 'splitRequest']),


    handleCheckboxChange(newValue) {
      // Отправка нового значения в родительский компонент или другая логика
      this.$emit('checkbox-changed', { profileId: this.profile._id, newValue: newValue, taskId: this.taskProfiles._id });
    },



  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.stats {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 65px;
  border-radius: 4px;

  border: 1px solid rgba(255, 255, 255, 0.24);
  padding: 2px 12px;

  font-size: 16px;

  .caption {
    font-size: 12px !important;
  }
}

.icon {
  cursor: pointer;
  transition: all 0.25 ease-in-out;

  &:hover {
    color: $pink;
    transform: scale(1.4);
  }
}
pre {
  font-size: 26px; /* Замените на нужный вам размер */
}
</style>
