<template>
  <v-card elevation="15" width="800" class="version">
    <v-card-title> </v-card-title>
    <v-card-subtitle></v-card-subtitle>
    <v-card-text>
      <div class="version-title">{{ data.version }}</div>
      <div class="version-subtitle">{{ data.date }}</div>
      <VueShowdown :markdown="data.markdown"></VueShowdown>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Version',

  props: ['data'],

  data() {
    return {};
  },

  components: {},

  computed: {},

  watch: {},

  async beforeMount() {},

  async mounted() {},

  methods: {},
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.version {
  margin: 20px 0;
  text-align: left;

  &-title {
    text-align: left;
    font-size: 26px;
    color: white;
  }
  &-subtitle {
    font-size: 14px;
    text-align: left;
    color: $gray_400;
    margin-bottom: 20px;
  }

  &-data {
    margin-bottom: 30px;
    h2 {
      color: white;
      margin-bottom: 10px;
    }

    &-text {
      font-size: 16px;
      color: $gray_100;
      margin-bottom: 5px;
    }
  }
}
</style>
