import axios from 'axios';
const url = 'api/yandex/';

async function getIntervals({}, taskId) {

  const res = await axios.get(url + `intervals/${taskId}`);

  let intervals = []
  for (const interval of res.data) {
    let from = {}
    let to = {}
    let newInterval = {}
    from.h = Math.floor(interval.from / 60)
    from.m  = interval.from % 60
    to.h = Math.floor(interval.to / 60)
    to.m  = interval.to % 60
    newInterval.from = from
    newInterval.to = to
    newInterval._id = interval._id
    newInterval.taskId =  interval.taskId
    newInterval.percentage =  interval.percentage
    intervals.push(newInterval)
  }
  return intervals;
}
async function addInterval({}, { taskId, interval }) {
  const res = await axios.post(url + `interval/add/${taskId}`, {
    interval,
  });
  return res.data;
}
async function editInterval({}, interval) {
  const res = await axios.post(url + `interval/edit`, { interval });
}
async function deleteInterval({}, taskId) {
  const res = await axios.delete(url + `interval/${taskId}`);

  return res.data;
}


export {
  getIntervals,
  addInterval,
  editInterval,
  deleteInterval,
}
