<template>
  <div @click.shift.right.prevent="copy($event)">
    <v-expansion-panels accordion v-if="loading_addresses">
      <v-expansion-panel
        v-for="(address, index) in 15"
        :key="index"
        @click.native="scrollToView($event, address.ip)"
      >
        <v-expansion-panel-header>
          <v-skeleton-loader type="text" max-width="500" max-height="15" />
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels accordion v-else v-model="panel">
      <v-expansion-panel
        v-for="(address, index) in addresses.slice(
          (page - 1) * 15,
          (page - 1) * 15 + 15
        )"
        :key="index"
        @click="scrollToView($event, address.ip)"
      >
        <v-expansion-panel-header>
          {{ address.ip }} - {{ address.count }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-skeleton-loader v-if="loading" type="list-item-avatar" />
          <v-skeleton-loader v-if="loading" type="list-item-avatar" />
          <v-skeleton-loader v-if="loading" type="list-item-avatar" />

          <v-timeline v-if="!loading">
            <v-timeline-item
              v-for="(proxy, proxy_index) in proxies"
              :key="proxy._id"
              class="mb-2"
              color="primary"
              small
              fill-dot
              :style="proxy_index % 2 ? 'text-align: right;' : ''"
            >
              <span :slot="proxy_index % 2 ? 'default' : 'opposite'">
                {{ proxy.proxy }}
              </span>

              <span
                class="gray_400--text"
                :slot="proxy_index % 2 ? 'opposite' : 'default'"
              >
                {{ proxy.date }}-{{ proxy.date_end }}
              </span>
            </v-timeline-item></v-timeline
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-pagination
      v-model="page"
      :length="Math.ceil(addresses_cnt / 15)"
      circle
      :total-visible="15"
    ></v-pagination>
    <v-snackbar v-model="copy_snackbar" timeout="1500" rounded>
      <div style="text-align:center">
        <v-icon color="success" class="mr-2">
          mdi-check-circle-outline
        </v-icon>
        <span>Прокси скопировано</span>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ListAddresses',

  props: ['addresses_cnt'],

  data() {
    return {
      page: 1,
      panel: -1,
      addresses: [],
      proxies: [],
      loading_addresses: true,
      loading: true,
      copy_snackbar: false,
    };
  },

  components: {},

  computed: {
    ...mapState([]),
  },

  watch: {
    async page() {
      this.panel = -1;
    },
  },

  async beforeMount() {
    this.addresses = await this.getMobileAddresses(0);
    this.loading_addresses = false;
  },

  methods: {
    ...mapActions(['getMobileAddresses', 'getMobileProxies_list_addresses']),

    async copy(event) {
      await navigator.clipboard.writeText(event.target.outerText.split(' ')[0]);
      this.copy_snackbar = true;
    },

    async scrollToView(event, ip) {
      this.loading = true;
      event.target.scrollIntoView();

      this.proxies = await this.getMobileProxies_list_addresses(ip);

      this.loading = false;

      await new Promise((resolve) => setTimeout(resolve, 0.3 * 1000));
      event.target.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';
</style>
