<template>
  <div @mousemove="handleMouseMove" class="inner">
    <SpoofSitesNew :dialog.sync="newSitesDialog" />
    <SpoofSitesDelete :dialog.sync="deleteSitesDialog" :ids="deletingIDs" />

    <div class="sites">
      <div class="tooltip">
        <v-spacer />
        <v-btn
          @click="handleSelectAll"
          :color="selectedItems.length ? 'primary' : ''">
          {{ selectedItems.length ? 'Снять выделение' : 'Выделить все' }}
        </v-btn>
        <v-spacer />
        <v-autocomplete
          v-model="search"
          :search-input.sync="searchValue"
          :items="searchItems"
          dense
          clearable
          label="Search" />
        <v-spacer />
        <v-btn
          @click="isWithError = !isWithError"
          :color="isWithError ? 'primary' : ''"
          class="mr-2">
          {{ isWithError ? 'Все' : 'С ошибками' }}
        </v-btn>
        <v-btn
          @click="handleOnlyHosts"
          :color="isOnlyHosts != 0 ? 'primary' : ''"
          class="mr-2">
          {{
            isOnlyHosts == 0
              ? 'В hosts'
              : isOnlyHosts == 1
              ? 'Не в hosts'
              : 'Все'
          }}
        </v-btn>
        <v-btn @click="newSitesDialog = true">New Sites</v-btn>
      </div>
      <v-virtual-scroll
        :items="filteredSites"
        ref="vScroll"
        height="1000"
        item-height="32"
        max-width="100%">
        <template v-slot:default="{ item }">
          <div @mouseenter="handleEnter(item._id)" class="site">
            <div></div>
            <div></div>
            <div>
              <v-simple-checkbox
                @mousedown="handleDown(item._id)"
                :value="isSelected(item._id)"
                hide-details
                color="primary" />
            </div>
            <div>{{ item.site }}</div>
            <div>{{ item.urls }}</div>
            <div>
              {{ new Date(item.createdAt).toLocaleString() }}
            </div>

            <SpoofSitesStatus :status="item.status" />

            <div>
              <v-simple-checkbox
                @click="handleHosts(item)"
                :disabled="isDisable(item._id)"
                :value="item.hosts" />
            </div>
            <div>
              <v-btn
                @click="handleDeleteSites(item._id)"
                :disabled="isDisable(item._id)"
                icon
                title="Delete">
                <v-icon :size="20" class="icon"> mdi-delete </v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-virtual-scroll>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SpoofSitesNew from './SpoofSitesNew';
import SpoofSitesDelete from './SpoofSitesDelete';

import SpoofSitesStatus from './SpoofSitesStatus';

import headers from './sitesHeaders.js';

export default {
  name: 'SpoofSites',

  data() {
    return {
      newSitesDialog: false,
      deleteSitesDialog: false,

      headers,

      search: '',
      searchValue: '',
      selectedItems: [],

      isOnlyHosts: 0,
      isWithError: false,

      isDown: false,

      deletingIDs: null,
    };
  },

  components: { SpoofSitesNew, SpoofSitesDelete, SpoofSitesStatus },

  computed: {
    ...mapState('spoof', ['sites']),

    filteredSites() {
      const sites = this.withErrorFilteredSites;

      if (this.searchValue)
        return sites.filter(site =>
          site.site.toLowerCase().includes(this.searchValue.toLowerCase())
        );

      return sites;
    },

    hostsFilteredSites() {
      return this.isOnlyHosts == 0
        ? this.sites
        : this.sites.filter(site => site.hosts == (this.isOnlyHosts == 1));
    },
    withErrorFilteredSites() {
      return this.hostsFilteredSites.filter(site =>
        this.isWithError ? site.status != 'allOk' : true
      );
    },

    searchItems() {
      return [
        ...this.filteredSites.reduce(
          (acc, elem) => acc.add(elem.site),
          new Set()
        ),
      ];
    },

    vScrollEl() {
      return this.$refs.vScroll.$el;
    },
  },

  watch: {
    deleteSitesDialog(val) {
      if (!val) {
        this.selectedItems = [];
      }
    },

    searchValue: 'resetSelected',
    isWithError: 'resetSelected',
    isOnlyHosts: 'resetSelected',
  },

  async beforeMount() {
    document.addEventListener('mouseup', () => {
      this.isDown = false;
    });
  },

  methods: {
    ...mapActions('spoof', ['editSites']),

    resetSelected() {
      this.selectedItems = [];
    },

    async handleDeleteSites(_id) {
      this.deletingIDs =
        this.selectedItems.length > 0 ? this.selectedItems : [_id];

      this.deleteSitesDialog = true;
    },

    async handleHosts(site) {
      const ids =
        this.selectedItems.length > 0 ? this.selectedItems : [site._id];

      const flag = !site.hosts;

      for (const id of ids) {
        const site = this.sites.find(site => id === site._id);
        if (site) site.hosts = flag;
      }

      await this.editSites({ ids, flag });
    },

    isDisable(_id) {
      if (this.selectedItems.length == 0) return false;

      return !this.isSelected(_id);
    },
    isSelected(_id) {
      return this.selectedItems.includes(_id);
    },

    handleSelectAll() {
      this.selectedItems = this.selectedItems.length
        ? []
        : this.sites.map(site => site._id);
    },
    handleOnlyHosts() {
      switch (this.isOnlyHosts) {
        case 0:
          this.isOnlyHosts = 1;
          break;
        case 1:
          this.isOnlyHosts = 2;
          break;
        case 2:
          this.isOnlyHosts = 0;
          break;
      }
    },

    handleDown(_id) {
      this.isDown = true;

      if (this.isSelected(_id))
        this.selectedItems.splice(
          this.selectedItems.findIndex(s => s == _id),
          1
        );
      else this.selectedItems.push(_id);
    },
    handleEnter(_id) {
      if (!this.isDown) return;

      if (this.isSelected(_id))
        this.selectedItems.splice(
          this.selectedItems.findIndex(s => s == _id),
          1
        );
      else this.selectedItems.push(_id);
    },

    handleMouseMove(event) {
      if (!this.isDown) return;

      if (window.innerHeight - 150 < event.clientY)
        this.vScrollEl.scrollBy({ top: 15 });
      else if (event.clientY < 200) this.vScrollEl.scrollBy({ top: -15 });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.inner {
  color: $gray_100;

  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  .sites {
    width: 100%;
    margin-bottom: 25px;

    .tooltip {
      width: 80%;
      margin: auto;
      display: flex;
      align-items: center;

      margin-bottom: 10px;
    }

    .site {
      display: grid;
      grid-template-columns: repeat(2, 1fr) 25px repeat(4, 1fr) 25px 25px repeat(
          2,
          1fr
        );
      align-items: center;
    }
  }
}
</style>
