<template>
  <!-- Диалоговое окно для создания новой задачи -->
  <v-dialog v-model="dialog" persistent max-width="550px">
    <!-- Кнопка, которая открывает диалоговое окно при нажатии -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        Новая задача
      </v-btn>
    </template>
    <!-- Карточка для формы создания новой задачи -->
    <v-card>
      <v-card-title>
        <span class="headline">Новая задача</span>
      </v-card-title>

      <!-- Форма создания новой задачи -->
      <v-form v-model="valid" ref="form">
        <v-container class="text-center">
          <!-- Поле для ввода названия задачи -->
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Название задачи"
            required />

          <!-- Поле для ввода ссылки -->
          <v-text-field
            v-model="domain"
            label="Домен"
            required />

          <!-- Поля для ввода диапазона глубины -->
<!--          <div class="row" style="margin: 0">-->
<!--            <v-text-field-->
<!--              v-model.number="depth.min"-->
<!--              :rules="numberRules"-->
<!--              class="mr-5"-->
<!--              label="Минимальная глубина"-->
<!--              required-->
<!--              type="number" />-->
<!--            <v-text-field-->
<!--              v-model.number="depth.max"-->
<!--              :rules="numberRules"-->
<!--              label="Максимальная глубина"-->
<!--              required-->
<!--              type="number" />-->
<!--          </div>-->

          <!-- Поля для ввода с какой и по какую страницу искать -->
          <div class="row" style="margin: 0">
            <v-text-field
              v-model.number="pagesLook.from"
              :rules="numberRules"
              class="mr-5"
              label="С какой страницы смотреть"
              required
              type="number" />
            <v-text-field
              v-model.number="pagesLook.to"
              :rules="numberRules"
              label="До какой страницы смотреть"
              required
              type="number" />
          </div>
          <div class="row" style="margin: 0">
            <v-text-field
              v-model.number="depth.from"
              class="mr-5"
              label="Глубина от"
              required
              type="number" />
            <v-text-field
              v-model.number="depth.to"
              label="Глубина до"
              required
              type="number" />
          </div>
          <div class="row" style="margin: 0">
            <v-text-field
              v-model.number="timeWalk.from"
              class="mr-5"
              label="Нахождение на сайте от"
              required
              type="number" />
            <v-text-field
              v-model.number="timeWalk.to"
              label="Нахождение на сайте до"
              required
              type="number" />
          </div>
          <v-text-field
            v-model.number="goneWithoutCookie"
            :rules="numberRules"
            label="Без кук, сразу гуляем сколько сайтов? (0 = с профилями)"
            required
            type="number" />
          <!-- Поля для ввода если уже найден страница, то на сколько назад и вперед от нее отойти -->
          <div class="row" style="margin: 0">
            <v-text-field
              v-model.number="beforeAndAfterLook.before"
              :rules="numberRules"
              class="mr-5"
              label="Отход назад от найденной"
              required
              type="number" />
            <v-text-field
              v-model.number="beforeAndAfterLook.after"
              :rules="numberRules"
              label="Вперед от найденной"
              required
              type="number" />
          </div>

<!--          <div class="row" style="margin: 0">-->
<!--            <v-text-field-->
<!--              v-model.number="time.min"-->
<!--              :rules="numberRules"-->
<!--              class="mr-5"-->
<!--              label="Минимальное время"-->
<!--              required-->
<!--              type="number" />-->
<!--            <v-text-field-->
<!--              v-model.number="time.max"-->
<!--              :rules="numberRules"-->
<!--              label="Максимальное время"-->
<!--              required-->
<!--              type="number" />-->
<!--          </div>-->

<!--          <v-slider-->
<!--            v-model.number="refusal"-->
<!--            class="mr-2 mt-4"-->
<!--            label="% Отказа"-->
<!--            min="0"-->
<!--            max="100"-->
<!--            required-->
<!--            thumb-label="always" />-->

          <v-slider
            v-model.number="mobiles"
            class="mr-2 mt-2"
            label="% Телефонов"
            min="0"
            max="100"
            required
            thumb-label="always" />

          <!-- Поля для выбора даты начала и окончания работы над задачей -->
          <div class="row" style="margin: 0">
            <DatePicker
              v-model="dateStart"
              :label="'Дата начала работы'"
              :rules="dateRules" />
            <DatePicker
              v-model="dateEnd"
              :label="'Дата окончания работы'"
              :rules="dateRules" />
          </div>
          <div class="d-flex align-center">
            Блочить Я.Метрику?
            <template>
              <div>
                <v-checkbox v-model="blockYa"/>
              </div>
            </template>
          </div>
          <div>PROXY (выбери одно): </div>
          <v-virtual-scroll
            :items="proxieCollections"
            ref="vScroll"
            height="150"
            item-height="50"
            max-width="100%"
            :class="highlightClass">
            <template v-slot:default="{ item }">
              <div class="site" @mouseenter="handleEnterProxy(item._id, item.collection_name)">
                <v-simple-checkbox
                  @mousedown="handleDownProxy(item._id, item.collection_name)"
                  :value="isSelectedProxy(item._id)"
                  hide-details
                  color="primary" />
                <div class="proxy-name">{{ item.collection_name }}</div>
              </div>
            </template>
          </v-virtual-scroll>
        </v-container>
      </v-form>
      <!-- Кнопки для закрытия диалогового окна или сохранения новой задачи -->
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false">Закрыть</v-btn>
        <v-btn color="primary" text @click="save">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex';

import DatePicker from './DatePicker';

import { nameRules } from '@/resources/js/rules/cabinet.js';

import rules from './rules';

export default {
  name: 'NewTask',

  data() {
    return {
      dialog: false,
      valid: false,
      proxieCollections: [],
      selectedProxy: '',
      selectedProxyName: '',
      isDown: false,
      blockYa: false,
      name: '',
      domain: '',
      depth: { from: '', to: '' },
      timeWalk: { from: '', to: '' },
      time: { min: '', max: '' },
      badClicks: { min: 0, max: 0 },
      refusal: 5,
      mobiles: 0,

      pagesLook: { from: '', to: '' },
      beforeAndAfterLook: { before: '', after: '' },

      dateStart: '',
      dateEnd: '',

      goneWithoutCookie: 0,

      nameRules,
      ...rules.call(this),
    };
  },

  components: { DatePicker },

  computed: {
    highlightClass() {
      return { 'highlight-scroll': !this.selectedProxy };
    },
  },

  watch: {
    dialog() {
      this.name = '';
      this.domain = '';
      this.depth = { from: '', to: '' };
      this.time = { min: '', max: '' };
      this.badClicks = { min: 0, max: 0 };
      this.refusal = 0;
      this.mobiles = 0;

      this.pagesLook = { from: '', to: '' };
      this.goneWithoutCookie = 0;
      this.beforeAndAfterLook = { before: '', after: '' };

      this.dateStart = '';
      this.dateEnd = '';

      this.$refs.form?.resetValidation();
    },

    // При изменении даты начала или окончания работы над задачей выполняем валидацию формы
    dateStart: 'validateForm',
    dateEnd: 'validateForm',
  },

  async beforeMount() {
    this.proxieCollections = await this.getProxiesCollections()
    this.selectedProxy = ""
    this.selectedProxyName = this.proxieCollections[0].collection_name
    document.addEventListener('mouseup', () => {
      this.isDown = false;
    });
  },

  async mounted() {},

  methods: {
    ...mapActions(['getProxiesCollections']),
    ...mapActions('yandex', ['newTask', 'getProxieCollections']),

    // Сохраняем новую задачу при нажатии кнопки "OK"
    async save() {
      this.validateForm();

      if (this.valid) {
        const res = await this.newTask({
          name: this.name,
          domain: this.domain,
          depth: this.depth,
          timeWalk: this.timeWalk,
          time: this.time,
          badClicks: this.badClicks,
          refusal: this.refusal,
          mobiles: this.mobiles,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          pagesLook: this.pagesLook,
          goneWithoutCookie: this.goneWithoutCookie,
          beforeAndAfterLook: this.beforeAndAfterLook,
          blockYa: this.blockYa,
          proxies: this.selectedProxyName,
        });

        if (!res) this.dialog = false;
      }
    },

    // Выполняем валидацию формы
    validateForm() {
      this.$refs.form.validate();
    },
    handleEnterProxy(_id, name) {
      if (!this.isDown) return;

      this.selectedProxy = _id
      this.selectedProxyName = name
    },
    handleDownProxy(_id, name) {
      this.isDown = true;
      this.selectedProxy = _id
      this.selectedProxyName = name

    },
    isSelectedProxy(_id, name) {
      return (this.selectedProxy == _id);
    },
  },
};
</script>
<style scoped lang="scss">
.highlight-scroll {
  border: 2px solid red; /* Пример подсветки границы */
}
.site {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; /* Добавляет 10px отступа снизу */
}
</style>
