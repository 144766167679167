import axios from 'axios';
const url = 'api/proxiesErrors/';

export default {
  namespaced: true,

  state: {
    abortController: null,

    groupedData: {},
    selectedItems: [],
    logins: [],
  },

  mutations: {
    updateAbortController(state) {
      try {
        state.abortController.abort();
      } catch (err) {}

      state.abortController = new AbortController();
    },

    setSelectedItems(state, items) {
      state.selectedItems = items;
    },
  },

  actions: {
    async getData({ dispatch, commit, state }) {
      commit('updateAbortController');

      state.groupedData = (
        await axios.get(url, {
          signal: state.abortController.signal,
        })
      ).data;

      dispatch('getLogins');
    },

    async startCheck({ dispatch, commit, state }, item) {
      commit('updateAbortController');

      if (state.selectedItems.length > 1) {
        for (const item of state.selectedItems)
          if (item.status == 'AvailSuccess') continue;
          else item.checking = true;

        await axios.post(`${url}/startCheck`, {
          _ids: state.selectedItems
            .filter(item => item.status != 'AvailSuccess')
            .map(item => item._id),
        });

        for (const item of state.selectedItems)
          if (item.status == 'AvailSuccess') continue;
          else item.status = '';

        state.selectedItems = [];
      } else {
        item.checking = true;

        await axios.post(`${url}startCheck`, {
          _ids: [item._id],
        });

        item.status = '';
      }

      dispatch('getData');
    },

    async setAsInWork({ dispatch, commit, state }, item) {
      commit('updateAbortController');
      const inWork = !item.inWork;

      if (state.selectedItems.length > 1)
        for (const item of state.selectedItems)
          if (item.status == 'AvailSuccess') continue;
          else item.inWork = inWork;
      else item.inWork = inWork;

      const _ids =
        state.selectedItems.length > 1
          ? state.selectedItems
              .filter(item => item.status != 'AvailSuccess')
              .map(item => item._id)
          : [item._id];

      await axios.post(`${url}setAsInWork`, {
        _ids,
        inWork,
      });

      dispatch('getData');
      state.selectedItems = [];
    },

    async getLogins({ state }) {
      state.logins = (await axios.get(`${url}logins`)).data;
    },

    async checkLogin({ dispatch }, login) {
      await axios.post(`${url}/checkLogin`, {
        login,
      });

      dispatch('getData');
    },
  },
};
