<template>
  <div class="stats-outter">
    <span class="count"><p style="font-size: 40px;">  {{ this.search.name }}</p></span>
    <span style="font-size: 30px;">{{ this.dateYa }}</span>
    <div class="stats-all">
      <div class="item">
        <span class="count">{{ graphsYa[0] }}</span>
        <span class="name">Всех капч за сутки</span>
      </div>
    </div>

    <div class="days">
      <div class="item">
        <v-menu
          v-model="date_picker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon title="Выбрать дату">
              <v-icon :size="22" color="primary"> mdi-calendar </v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="dateYa"
            color="primary"
            header-color="gray_800"
            @input="getClick"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>

    <v-autocomplete
      v-model="search"
      :items="search_items"
      item-text="name"
      :item-value="item => item"
      dense
      clearable
      label="Search"
      @change="getClick"
    >
      <template v-slot:selection="data">
        <v-chip close>
          {{ data.item.name }}
        </v-chip>
      </template>
    </v-autocomplete>

    <div style="width: 95%; margin-bottom: 40px">
      <Chart :_stats.sync="graphsYa[1]" :title="'Всего попыток решить капчу'" />
    </div>
    <div class="inner">
      <div style="width: 45%">
        <Chart :_stats.sync="graphsYa[2]" :title="'Успешных решений капчи'" />
      </div>
      <div style="width: 45%">
        <Chart :_stats.sync="graphsYa[3]" :title="'Неуспешных решений капчи'" />
      </div>
    </div>
<!--    <v-data-table-->
<!--      :headers="headers"-->
<!--      :items="tableItems"-->
<!--      class="elevation-1"-->
<!--    >-->
<!--      <template v-slot:top>-->
<!--        <tr>-->
<!--          <th colspan="7" style="text-align: center; width: 200px"></th>-->
<!--          <th colspan="7" style="text-align: center; width: 450px">На какой странице находил</th>-->
<!--          <th colspan="7" style="text-align: center; width: 450px">Кликов в день совершено</th>-->
<!--        </tr>-->
<!--      </template>-->
<!--      <template v-slot:item="{ item }">-->
<!--        <tr>-->
<!--          <td>{{ item.req }}</td>-->
<!--          <td :style="getStyle(item, 'page', 'page-1')">{{ item.page }}</td>-->
<!--          <td :style="getStyle(item, 'page-1', 'page-2')">{{ item['page-1'] }}</td>-->
<!--          <td :style="getStyle(item, 'page-2', 'page-3')">{{ item['page-2'] }}</td>-->
<!--          <td :style="getStyle(item, 'page-3', 'page-4')">{{ item['page-3'] }}</td>-->
<!--          <td :style="getStyle(item, 'page-4', 'page-5')">{{ item['page-4'] }}</td>-->
<!--          <td :style="getStyle(item, 'page-5', 'page-6')">{{ item['page-5'] }}</td>-->
<!--          <td class="border-right">{{ item['page-6'] }}</td>-->
<!--          <td>{{ item.click }}</td>-->
<!--          <td>{{ item['click-1'] }}</td>-->
<!--          <td>{{ item['click-2'] }}</td>-->
<!--          <td>{{ item['click-3'] }}</td>-->
<!--          <td>{{ item['click-4'] }}</td>-->
<!--          <td>{{ item['click-5'] }}</td>-->
<!--          <td>{{ item['click-6'] }}</td>-->
<!--        </tr>-->
<!--      </template>-->
<!--    </v-data-table>-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Chart from './chart';

export default {
  name: 'YandexStats',

  data() {
    return {

      dateYa: new Date(Date.now() + 10800000).toISOString().substr(0, 10),
      id: '',

      tableItems: [],
      graphsYa: [],
      search: {},
      search_items: [],
      // isServers: false,

      date_picker: false,
      // dates: [new Date(Date.now() + 10800000).toISOString().substr(0, 10)], // 10800000 for Moscow timezone
    };
  },

  components: {
    Chart,
  },

  computed: {
    ...mapState('yandex', ['statsYa']),

    today() {

      return (
        this.dateYa == new Date(Date.now() + 10800000).toISOString().substr(0, 10)
      );
    },
    headers() {

      let dates = [];
      let baseDate = new Date(this.dateYa);

      for (let i = 1; i <= 6; i++) {
        let newDate = new Date(baseDate);
        newDate.setDate(newDate.getDate() - i);
        dates.push(newDate.toISOString().substr(0, 10));
      }

      return [
        { text: 'Запрос', value: 'req', width: '200px' },
        { text: this.dateYa, value: 'page', width: '50px' },
        { text: dates[0], value: 'page-1', width: '50px' },
        { text: dates[1], value: 'page-2', width: '50px' },
        { text: dates[2], value: 'page-3', width: '50px' },
        { text: dates[3], value: 'page-4', width: '50px' },
        { text: dates[4], value: 'page-5', width: '50px' },
        { text: dates[5], value: 'page-6', width: '50px' },
        { text: this.dateYa, value: 'click', width: '50px' },
        { text: dates[0], value: 'click-1', width: '50px' },
        { text: dates[1], value: 'click-2', width: '50px' },
        { text: dates[2], value: 'click-3', width: '50px' },
        { text: dates[3], value: 'click-4', width: '50px' },
        { text: dates[4], value: 'click-5', width: '50px' },
        { text: dates[5], value: 'click-6', width: '50px' },
      ];
    },
    yesterday() {

      return (
        this.dateYa == new Date(Date.now() + 10800000 - 86400000).toISOString().substr(0, 10)
      );
    },

    // today_yesterday() {
    //   return (
    //     this.dates.length == 2 &&
    //     new Date(Date.now() + 10800000).toISOString().substr(0, 10) ==
    //     this.dates[0] &&
    //     new Date(Date.now() + 10800000 - 86400000)
    //       .toISOString()
    //       .substr(0, 10) == this.dates[1]
    //   );
    // },

    // search_items() {
    //
    //   return [{id: "!1111", name: "2222"},{id: "!3333", name: "444"},{id: "!5555", name: "66333333333333333333336"}]
    // },


  },

  async beforeMount() {

    this.search_items = await this.getSearchProfileItems();
    this.search_items = this.search_items.reverse()
    this.search = this.search_items[0]

    await this.getClick();


  },

  async mounted() {

    // window.addEventListener('keyup', this.getKeyboard);
  },

  watch: {

  },

  methods: {
    ...mapActions('yandex', ['getClicksProfile', 'getSearchProfileItems']),


    async getClick() {

      this.graphsYa = await this.getClicksProfile({date: this.dateYa, id: this.search.id});
      //this.tableItems = await this.getTableProfile({date: this.dateYa, id: this.search.id})

    },

    getStyle(item, key1, key2) {
      if (!(item[key2] && item[key1])) {
        return ''
      }
      let style = 'color: black;'; // Устанавливаем текст внутри ячейки черным
      if (item[key1] > item[key2]) {
        style += 'background-color: red;';
      } else if (item[key1] < item[key2]) {
        style += 'background-color: green;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },

  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';



.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.width {
  width: 200px; /* Пример ширины для широких столбцов */
}
.notwidth {
  width: 50px; /* Пример ширины для более узких столбцов */
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.stats-outter {
  width: 100%;
  margin: 10px 0 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  .table {
    min-width: 35%;
  }
  .border-right {
    border-right: 3px ridge grey; /* или любой другой цвет */
  }
  .my-custom-table .v-data-table-header th:nth-child(7) {
    border-right: 3px ridge grey;
  }
  .stats-all {
    display: flex;
    justify-content: center;

    // width: 60%;
    margin-bottom: 20px;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: right;

      .count {
        color: $gray_100;
        font-size: 20px;
      }

      .count-yesterday {
        color: $gray_200;
        font-size: 18px;
      }

      .count-0 {
        color: $gray_100;
        font-size: 20px;
      }
      .count-1 {
        color: $gray_200;
        font-size: 18px;
      }
      .count-2 {
        color: $gray_300;
        font-size: 16px;
      }
      .count-3 {
        color: $gray_400;
        font-size: 16px;
      }
      .count-4 {
        color: $gray_500;
        font-size: 16px;
      }

      .name {
        margin-top: 5px;
        color: $gray_400;
        font-size: 14px;
      }
    }
    .item:not(:last-child) {
      margin-right: 40px;
    }
  }

  .days {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .item:not(:last-child) {
      margin-right: 20px;
    }
  }

  .inner {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 20px;

    .chart {
      width: 45%;
    }
  }
}
</style>

<style>
.apexcharts-theme-mine {
  background: #555;
}
</style>
