<template>
  <div>
    <v-snackbar v-model="success" :timeout="5000" color="success">
      Добавлено {{ successSites }} {{ labels[declByNum(successSites)] }}
    </v-snackbar>
    <v-snackbar v-model="error" :timeout="5000" color="error">
      Произошла ошибка при добавлении
    </v-snackbar>

    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">New Sites</v-card-title>
        <v-form v-model="formValid" ref="form">
          <v-container class="text-center">
            <v-textarea
              v-model="sites"
              :rules="sitesRules"
              auto-grow
              counter
              label="Сайты"
              required
            >
              <template v-slot:counter>
                {{
                  sites.split(sites.includes(',') ? ',' : '\n').length -
                  (formValid ? 0 : 1)
                }}
              </template>
            </v-textarea>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('update:dialog', false)"
            :disabled="loading"
            text
          >
            Cancel
          </v-btn>
          <v-btn @click="save" :loading="loading" color="primary" text>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import declByNum from '@/resources/js/utils/declByNum.js';

export default {
  name: 'SpoofSitesNew',

  props: ['dialog'],

  components: {},

  data() {
    return {
      labels: ['сайт', 'сайта', 'сайтов'],

      formValid: false,

      sites: '',
      sitesRules: [
        v => !!v || 'Необходимо ввести данные',
        v => {
          if (v == undefined) return;

          const sites = v.split(v.includes(',') ? ',' : '\n');
          let flag = true;
          for (const str of sites) {
            if (
              !/^[\p{L}0-9-]+(\.[\p{L}0-9-]+)*\.[\p{L}]{2,}$/u.test(str) ||
              str == ''
            ) {
              flag = false;
              break;
            }
          }
          return flag ? !!v : 'Введены некорректные данные';
        },
      ],

      loading: false,
      success: false,
      successSites: 0,
      error: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.formValid = false;
        this.sites = '';
        this.loading = false;
        this.success = false;
        this.error = false;
      }
    },

    sites(text) {
      // Регулярное выражение для поиска доменов
      const domainRegex =
        /(?:https?:\/\/)?(?:www\.)?([\p{L}0-9.-]+)\.([\p{L}]{2,})/gu;

      // Извлечение доменов из текста
      const domains = [];
      let match;
      while ((match = domainRegex.exec(text)) !== null) {
        domains.push(match[0]);
      }

      this.sites = domains.join('\n');
    },
  },

  methods: {
    ...mapActions('spoof', ['addSites']),
    declByNum,

    async save() {
      this.$refs.form.validate();
      if (!this.formValid) return;

      this.loading = true;
      try {
        const cnt = await this.addSites(this.sites);

        this.successSites = cnt;
        this.success = true;
        this.loading = false;
        this.$emit('update:dialog', false);
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
