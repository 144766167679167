<template>
  <div>
    <v-icon :color="color">{{ icon }}</v-icon>
  </div>
</template>

<script>
export default {
  name: 'SpoofSitesStatus',

  props: ['status'],

  computed: {
    icon() {
      switch (this.status) {
        case 'parsing':
          return 'mdi-progress-wrench';
        case 'siteError':
          return 'mdi-web-off';
        case 'urlsError':
          return 'mdi-less-than';
        case 'allOk':
          return 'mdi-circle-slice-8';
      }

      return '';
    },

    color() {
      switch (this.status) {
        case 'parsing':
          return 'primary';
        case 'siteError':
          return 'error';
        case 'urlsError':
          return 'error';
        case 'allOk':
          return 'success';
      }

      return '';
    },
  },
};
</script>

<style scoped lang="scss"></style>
