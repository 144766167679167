export const main_headers = [
  { text: 'Name', value: 'name', divider: true },
  {
    text: 'IP',
    align: 'start',
    sortable: false,
    value: 'ip',
  },

  { text: 'url', value: 'url', divider: true },
  {
    text: 'site type',
    value: 'site_type',
    align: 'center',
  },
  {
    text: 'player',
    value: 'player_type',
    align: 'center',
    width: 150,
  },

  {
    text: 'worker',
    value: 'worker_type',
    align: 'center',
    width: 150,
  },
  {
    text: 'mobiles',
    value: 'mobiles',
    align: 'center',
  },

  {
    text: 'CPU',
    value: 'cpu_usage',
    width: 80,
    sortable: false,
    align: 'center',
  },

  {
    text: 'Status',
    value: 'status',
    width: 80,
    sortable: false,
    align: 'center',
    divider: true,
  },

  {
    text: 'Actions',
    align: 'center',
    value: 'actions',
    sortable: false,
    width: 150,
  },
];

export const dev_headers = [
  { text: 'Name', value: 'name', divider: true },
  {
    text: 'IP',
    align: 'start',
    sortable: false,
    value: 'ip',
  },

  { text: 'url', value: 'url', divider: true },
  {
    text: 'player',
    value: 'player_type',
    align: 'center',
    width: 150,
  },
  {
    text: 'worker',
    value: 'worker_type',
    align: 'center',
    width: 150,
    divider: true,
  },

  {
    text: 'pause',
    value: 'pause',
    align: 'center',
    sortable: false,
    width: 120,
  },

  {
    text: 'cookies',
    value: 'use_cookies',
    align: 'center',
    sortable: false,
  },

  {
    text: 'blocking',
    value: 'blocking',
    align: 'center',
    sortable: false,
    divider: true,
  },

  {
    text: 'Status',
    value: 'status',
    width: 80,
    sortable: false,
    align: 'center',
    divider: true,
  },

  {
    text: 'Actions',
    align: 'center',
    value: 'actions',
    sortable: false,
    width: 150,
  },
];

export const proxy_headers = [
  { text: 'Name', value: 'name', divider: true },
  {
    text: 'IP',
    align: 'start',
    sortable: false,
    value: 'ip',
  },

  { text: 'url', value: 'url', divider: true },

  {
    text: 'proxy',
    value: 'proxy',
    width: 200,

    sortable: false,
    divider: true,
  },

  {
    text: 'player',
    value: 'player_type',
    align: 'center',
    width: 150,
  },
  {
    text: 'worker',
    value: 'worker_type',
    align: 'center',
    width: 150,
    divider: true,
  },

  {
    text: 'Status',
    value: 'status',
    width: 80,
    sortable: false,
    align: 'center',
    divider: true,
  },
];

export const mironov_headers = [
  {
    text: 'Название',
    value: 'name',
    divider: true,
    align: 'center',
    width: 320,
  },

  {
    text: 'Количество заходов в день',
    value: 'perDay',
    divider: true,
    align: 'center',

    width: 150,
  },

  { text: 'Ссылка', value: 'url', divider: true, align: 'center' },
  { text: 'Глубина', value: 'depth', align: 'center' },
  { text: 'Время на сайте', value: 'time', align: 'center' },
  { text: '% Отказа', value: 'refusal', align: 'center', sortable: false },
  {
    text: 'Referer',
    value: 'referer',
    divider: true,
    align: 'center',
    sortable: false,
  },
  { text: 'Selector', value: 'selector', divider: true, align: 'center' },
  {
    text: '% Телефонов',
    value: 'mobiles',
    divider: true,
    align: 'center',
    sortable: false,
  },

  { text: 'Дата начала работы', value: 'dateStart', align: 'center' },
  {
    text: 'Дата окончания работы',
    value: 'dateEnd',
    divider: true,
    align: 'center',
  },

  {
    text: 'proxy',
    value: 'proxy',
    width: 200,

    sortable: false,
    divider: true,
  },

  {
    text: 'actions',
    value: 'actions',
    align: 'center',
    width: 150,
    sortable: false,
  },
];
