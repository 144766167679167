<template>
  <div v-if="proxies_cnt != 0">
    <v-app-bar>
      <v-tabs v-model="tab" centered>
        <v-tab>Список Прокси</v-tab>
        <v-tab>Список Адресов</v-tab>
        <!-- <v-tab>Проценты</v-tab> -->
      </v-tabs>
    </v-app-bar>

    <v-tabs-items v-model="tab" style="margin-top: 10px">
      <v-tab-item>
        <ListProxies :proxies_cnt.sync="proxies_cnt" />
      </v-tab-item>
      <v-tab-item>
        <ListAddresses :addresses_cnt.sync="addresses_cnt" />
      </v-tab-item>
      <v-tab-item> </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ListProxies from './ListProxies';
import ListAddresses from './ListAddresses';

export default {
  name: 'MobileProxies',

  data() {
    return {
      proxies_cnt: 0,
      addresses_cnt: 0,

      tab: 0,
    };
  },

  components: { ListProxies, ListAddresses },

  computed: {
    ...mapState([]),
  },

  watch: {
    async tab(newVal) {
      if (newVal == 0) this.proxies_cnt = await this.getMobileProxies_cnt();
      else if (newVal == 1)
        this.addresses_cnt = await this.getMobileAddresses_cnt();
    },
  },

  async beforeMount() {},

  async mounted() {
    this.proxies_cnt = await this.getMobileProxies_cnt();
    this.addresses_cnt = await this.getMobileAddresses_cnt();

    // setInterval(async () => {
    //   await this.getProxiesCollections();
    // }, 10 * 1000);
  },

  methods: {
    ...mapActions(['getMobileProxies_cnt', 'getMobileAddresses_cnt']),
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';
</style>
