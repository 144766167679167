import axios from 'axios';

const url = 'api/';

export async function editServer_DeviceType({}, { server, new_device_type }) {
  let res = await axios
    .post(url + 'servers/edit_device/' + server._id, {
      device_type: new_device_type,
    })
    .then(response => response)
    .catch(err => {
      if (err.message.indexOf(409) != -1)
        return {
          status: '409',
        };
      else
        return {
          status: '404',
        };
    });

  return res;
}

export async function editServer_NewCookies({}, { server, use_cookies }) {
  let res = await axios.post(url + 'servers/edit_use_cookies', {
    use_cookies: use_cookies,
    _ids: [server._id],
  });

  return res;
}

export async function editServer_Blocking({}, { server, blocking }) {
  let res = await axios
    .post(url + 'servers/edit_blocking/' + server._id, {
      blocking,
    })
    .then(response => response)
    .catch(err => {
      if (err.message.indexOf(409) != -1)
        return {
          status: '409',
        };
      else
        return {
          status: '404',
        };
    });

  return res;
}

export async function editServers_Proxies({}, { servers, proxies }) {
  let res = await axios
    .post(url + 'servers/edit_proxies', {
      servers,
      proxies,
    })
    .then(response => response)
    .catch(err => {
      if (err.message.indexOf(409) != -1)
        return {
          status: '409',
        };
      else
        return {
          status: '404',
        };
    });

  if (res.status == '201') {
    for (let i in servers) servers[i].proxies = proxies;
  }

  return res;
}

export async function editServers_Pause({}, { servers, pause }) {
  let res = await axios
    .post(url + 'servers/edit_pause', {
      _ids: servers.map(s => s._id),
      pause,
    })
    .then(response => response)
    .catch(err => {
      if (err.message.indexOf(409) != -1)
        return {
          status: '409',
        };
      else
        return {
          status: '404',
        };
    });

  if (res.status == '201') {
    for (let i in servers) servers[i].pause = pause;
  }

  return res;
}
