<template>
  <div class="proxy">
    <div class="proxy-outer">
      <div>
        <v-data-table
          :headers="headers"
          :items="collections"
          :items-per-page="collections.length"
          :search="search"
          class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <span>Proxies</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-autocomplete
                v-model="search"
                :items="
                  collections.map(collection => collection.collection_name)
                "
                dense
                clearable
                label="Search"></v-autocomplete>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogAdd" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on">
                    New Proxy
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline"> Add Collection </v-card-title>
                  <v-form v-model="addValid" ref="addForm">
                    <v-container class="text-center" v-if="add_groups">
                      <v-textarea
                        v-model="groups"
                        :rules="groupsRules"
                        label="Группа коллекций"
                        required></v-textarea>
                    </v-container>

                    <v-container class="text-center" v-else>
                      <v-text-field
                        v-model="editedItem.collection_name"
                        :rules="nameRules"
                        label="Название коллекции"
                        required></v-text-field>

                      <v-text-field
                        v-model="editedItem.description"
                        label="Описание"></v-text-field>
                    </v-container>

                    <v-btn text @click="add_groups = !add_groups">
                      <span v-if="add_groups">Добавить одну коллекцию</span>
                      <span v-else>Добавить группой</span>
                    </v-btn>
                  </v-form>
                  <v-alert v-if="error" dense outlined type="error">
                    <div class="myError">
                      Данное название коллекции уже используется
                    </div>
                  </v-alert>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-btn color="primary" text @click="addItemConfirm">
                      OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.collection_name="{ item }">
            <v-tooltip bottom min-width="25%" content-class="proxy-description">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent="addProxies(item)">
                  {{ item.collection_name }}
                </div>
              </template>
              <div>
                {{ item.description }}
              </div>
            </v-tooltip>
          </template>

          <template v-slot:item.mobile="{ item }">
            <div style="display: flex; justify-content: center">
              <v-checkbox
                v-model="item.mobile"
                hide-details
                :dense="true"
                @change="change_mobile(item)" />
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon @click.stop.prevent="editItem(item)" title="Edit">
              <v-icon :size="20" class="icon"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon @click.stop.prevent="deleteItem(item)" title="Delete">
              <v-icon :size="20" class="icon"> mdi-delete </v-icon>
            </v-btn>

            <v-btn
              v-if="user.admin"
              @click.stop.prevent="showProxiesHandler(item)"
              icon
              title="Show Proxies">
              <v-icon :size="20" class="icon"> mdi-eye </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="dialogEdit" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Edit Collection {{ editedItem.collection_name }}
        </v-card-title>
        <v-form v-model="editValid" ref="editForm">
          <v-container class="text-center">
            <v-text-field
              v-model="editedItem.collection_name"
              :rules="nameRules"
              label="Название коллекции"
              required></v-text-field>

            <v-text-field
              v-model="editedItem.description"
              label="Описание"></v-text-field>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" text @click="editItemConfirm"> OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Вы уверены, что хотите удалить коллекцию
          <span class="collection-name">{{ editedItem.collection_name }}</span>
          ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="deleteItemConfirm"> OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAdd_area"
      persistent
      max-width="500px"
      v-if="dialogAdd_area">
      <v-card>
        <v-card-title class="headline">
          Количество прокси в БД:
          {{ getCount() }}
        </v-card-title>

        <v-form v-model="textValid" ref="newProxies">
          <v-container class="text-center">
            <v-textarea
              v-model="proxies"
              :rules="proxiesRules"
              label="Новые прокси"
              required
              :autofocus="true"
              :auto-grow="true"></v-textarea>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" text @click="newProxies()" :loading="loading">
            Save
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="dialog_show"
      v-model="dialog_show"
      persistent
      scrollable
      max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Введите код из телеграмма
        </v-card-title>

        <v-form v-model="textValid" ref="newProxies">
          <v-container class="text-center">
            <v-text-field
              v-model="code"
              label="Код"
              required
              :autofocus="true" />
          </v-container>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="download">Download</v-btn>
          <v-btn text @click="close">Exit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Proxies',

  data() {
    return {
      proxies: '',
      loading: false,
      collection_name: 0,
      textValid: false,

      error: false,
      dialogAdd: false,
      add_groups: false,
      addValid: false,

      dialogEdit: false,
      editValid: false,

      dialogDelete: false,

      dialogAdd_area: false,

      dialog_show: false,

      groups: '',

      code: '',

      editedItem: {
        collection_name: '',
        description: '',
      },

      headers: [
        {
          text: 'Name',
          value: 'collection_name',
        },
        {
          text: 'Count',
          align: 'center',
          value: 'count',
          divider: true,
        },
        {
          text: 'Mobile',
          align: 'center',
          value: 'mobile',
          width: 100,
          divider: true,
        },
        {
          text: 'Actions',
          align: 'center',
          value: 'actions',
          sortable: false,
          width: 150,
        },
      ],
      search: '',

      nameRules: [v => !!v || 'Необходимо ввести название'],

      proxiesRules: [
        v => !!v || 'Необходимо ввести прокси',
        v => {
          if (v != undefined) {
            let check = v.split('\n');
            let flag = true;
            for (let str of check) {
              if (
                !/^[\S]{0,}?:[\S]{0,}?@([0-9]{1,3}[\.]){3}[0-9]{1,3}?:[\S]{0,}$|^[\S]{0,}?:[\S]{0,}?@(?!www)+([\da-z\.-]+\.[a-z\.]{2,6}[\/\w.-]*)*\/?:[\S]{0,}$/.test(
                  str
                )
              ) {
                flag = false;
                break;
              }
            }
            return flag ? !!v : 'Введены некорректные данные';
          }
        },
      ],

      groupsRules: [
        v => !!v || 'Необходимо ввести группы',
        v => {
          if (v != undefined) {
            let check = v.split('\n');
            let flag = true;
            for (let str of check) {
              if (
                !/^[\S]{0,}?:[\S]{0,}?@([0-9]{1,3}[\.]){3}[0-9]{1,3}?:[\S]{0,}$/.test(
                  str
                )
              ) {
                flag = false;
                break;
              }
            }
            return flag ? !!v : 'Введены некорректные данные';
          }
        },
      ],
    };
  },

  components: {},

  computed: {
    ...mapState(['user', 'collections']),
  },

  watch: {
    async collection_name(newVal, oldVal) {
      if (newVal == undefined) {
        this.collection_name = oldVal;
        newVal = oldVal;
      }
    },
  },

  async beforeMount() {
    this.collection_name = 0;
  },

  async mounted() {
    setInterval(async () => {
      await this.getProxiesCollections();
    }, 10 * 1000);
  },

  methods: {
    ...mapActions([
      'getProxiesCollections',
      'getProxiesCount',
      'postProxies',
      'addProxyCollection',
      'addProxyCollections_byGroups',
      'editProxyCollection',
      'editProxyCollectionMobile',
      'deleteProxyCollection',

      'showProxies',
      'downloadProxies',
    ]),

    async close() {
      await this.getProxiesCollections();

      this.dialogAdd = false;
      this.dialogEdit = false;
      this.dialogDelete = false;
      this.dialogAdd_area = false;
      this.dialog_show = false;

      this.editedItem = {
        collection_name: '',
        description: '',
      };

      this.code = '';
    },

    async download() {
      await this.downloadProxies({
        code: this.code,
        collection_name: this.editedItem.collection_name,
      });

      this.code = '';

      this.close();
    },

    async addProxies(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogAdd_area = true;
    },

    async editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    async deleteItem(item) {
      this.editedItem = item;
      this.dialogDelete = true;
    },

    async showProxiesHandler(item) {
      this.editedItem = Object.assign({}, item);
      await this.showProxies();
      this.dialog_show = true;
    },

    getCount() {
      return this.collections.find(
        item => item.collection_name == this.editedItem.collection_name
      ).count;
    },

    async change_mobile(item) {
      await this.editProxyCollectionMobile(item);
    },

    async addItemConfirm() {
      this.error = false;
      this.$refs.addForm.validate();
      if (this.addValid && this.add_groups) {
        let res = await this.addProxyCollections_byGroups({
          groups: this.groups,
        });
        if (res == 'error') {
          this.error = true;
        } else {
          this.close();
        }
      } else if (this.addValid) {
        let res = await this.addProxyCollection({
          collection: this.editedItem,
        });
        if (res == 'error') {
          this.error = true;
        } else {
          this.close();
        }
      }
    },

    async editItemConfirm() {
      this.$refs.editForm.validate();
      if (this.editValid) {
        await this.editProxyCollection({
          id: this.editedItem._id,
          collection: this.editedItem,
        });

        this.close();
      }
    },

    async deleteItemConfirm() {
      await this.deleteProxyCollection(this.editedItem.collection_name);
      this.editedItem = {
        collection_name: '',
        description: '',
      };

      this.close();
    },

    async newProxies() {
      this.$refs.newProxies.validate();
      if (this.textValid) {
        this.loading = true;
        let proxies = this.proxies;
        this.proxies = '';
        await this.postProxies({
          proxies,
          collection_name: this.editedItem.collection_name,
        });

        this.loading = false;
        this.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.myError {
  white-space: pre-wrap;
  text-align: center;
}

.proxy {
  width: 100%;
  justify-content: center;
  display: flex;

  &-outer {
    width: 90%;
  }
}

.headline {
  div {
    width: 100%;
  }
  word-break: break-word;

  .collection-name {
    color: $gray_450;
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
@import '@/assets/_colors.scss';

.proxy-description {
  color: $gray-200;
}
</style>
