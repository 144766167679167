<template>
  <div>
    <SitesNew :dialog.sync="newSitesDialog" :playerData.sync="playerData" />

    <v-snackbar v-model="deletingSnack" :timeout="5000" color="warning">
      Другие сайты удаляются, подождите...
    </v-snackbar>
    <v-snackbar v-model="errorSnack" :timeout="5000" color="error">
      Произошла ошибка на сервере, попробуйте позже.
    </v-snackbar>

    <div v-if="playerData" @mouseup="handleUp" class="wrapper">
      <div class="tooltip">
        <v-text-field
          v-model="search"
          dense
          clearable
          label="Поиск"
          hide-details
        />

        <v-btn @click="newSitesDialog = true"> Добавить сайты </v-btn>
      </div>
      <div class="sites">
        <div class="mb-4">
          <v-btn @click="handleSelectAll">
            {{ selectedItems.length ? 'Снять выделение' : 'Выделить все' }}
          </v-btn>
        </div>
        <v-virtual-scroll
          :items="sites"
          ref="vScroll"
          height="1000"
          item-height="32"
          max-width="100%"
        >
          <template v-slot:default="{ item }">
            <div class="site-item" @mouseenter="handleMouseEnter(item)">
              <v-simple-checkbox
                @mousedown="handleCheckbox(item)"
                :value="isSelected(item)"
                hide-details
                color="primary"
              />
              <div>{{ item }}</div>
              <v-spacer />
              <v-btn
                @click="handleDelete(item)"
                :disabled="isDisable(item)"
                icon
                title="Delete"
              >
                <v-icon :size="20" class="icon"> mdi-delete </v-icon>
              </v-btn>
            </div>
          </template>
        </v-virtual-scroll>
      </div>
    </div>
  </div>
</template>

<!-- MARK: script -->

<script setup>
import SitesNew from './SitesNew.vue';

import { onBeforeMount, ref, computed, watch } from 'vue';
import axios from 'axios';

const props = defineProps({});
const playerData = ref(null);

onBeforeMount(async () => {
  try {
    playerData.value = (
      await axios.get('api/spoof/players/sites/DZ_LORD')
    ).data;
  } catch (err) {
    errorSnack.value = true;
  }
});

const newSitesDialog = ref(false);

const search = ref('');
const selectedItems = ref([]);
const disabledItems = ref([]);

const errorSnack = ref(false);
const deletingSnack = ref(false);

const sites = computed(() => {
  return playerDataSites.value.filter(site =>
    site.toLowerCase().includes(search.value.toLowerCase())
  );
});

const playerDataSites = computed(() => {
  return playerData.value.sites.split(',').sort();
});

async function handleDelete(item) {
  if (disabledItems.value.length) {
    deletingSnack.value = true;
    return;
  }

  if (selectedItems.value.length) {
    disabledItems.value = selectedItems.value.includes(item)
      ? selectedItems.value
      : [item];
  } else {
    disabledItems.value = [item];
  }

  const newSites = playerDataSites.value.filter(
    site => !disabledItems.value.includes(site)
  );
  try {
    await axios.put('api/spoof/players/sites/DZ_LORD', {
      sites: newSites.join(','),
    });

    selectedItems.value = [];

    playerData.value.sites = newSites.join(',');
  } catch (err) {
    errorSnack.value = true;
  } finally {
    disabledItems.value = [];
  }
}

function isDisable(item) {
  return disabledItems.value.includes(item);
}

function handleSelectAll() {
  selectedItems.value = selectedItems.value.length ? [] : sites.value;
}

const isMouseDown = ref(0);
function handleCheckbox(item) {
  if (isSelected(item)) {
    selectedItems.value = selectedItems.value.filter(i => i !== item);
    isMouseDown.value = 2;
  } else {
    selectedItems.value.push(item);
    isMouseDown.value = 1;
  }
}
function handleMouseEnter(item) {
  if (isMouseDown.value == 1 && !isSelected(item))
    selectedItems.value.push(item);
  if (isMouseDown.value == 2 && isSelected(item))
    selectedItems.value = selectedItems.value.filter(i => i !== item);
}

function handleUp() {
  isMouseDown.value = 0;
}

function isSelected(item) {
  return selectedItems.value.includes(item);
}

watch(search, () => {
  selectedItems.value = [];
});
</script>

<!-- MARK: style -->
<style scoped lang="scss">
@import '@/assets/_colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // height: 100%;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .tooltip {
    width: 30%;
    border-radius: 8px;
    background-color: #272727;
    padding: 10px 15px 15px;

    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  .sites {
    width: 40%;
    border-radius: 8px;
    background-color: #272727;
    padding: 15px;

    .site-item {
      display: flex;

      // justify-content: center;
      align-items: center;
    }
  }
}
</style>
