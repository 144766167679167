<template>
  <div class="main">
    <v-app-bar>
      <v-tabs v-model="tab" centered>
        <v-tab>Задачи</v-tab>
        <v-tab>Прокси</v-tab>
<!--        <v-tab>Запросы</v-tab>-->
        <v-tab>Профили</v-tab>
        <v-tab>Стата</v-tab>
        <v-tab>Капчи Задачи</v-tab>
        <v-tab>Капчи Профили</v-tab>
      </v-tabs>
    </v-app-bar>

    <DeleteDialog
      :deletingItem.sync="deletingItem"
      :deletingId.sync="deletingId"
      :selectedItems="selectedItems"
      ref="DeleteDialogRef" />

    <v-main>
      <v-tabs-items v-model="tab" touchless id="scroll">
        <v-tab-item class="main-outter">
          <v-data-table
            v-if="!loading"
            v-model="selectedItems"
            @click:row="rowClick"
            :headers="headers"
            :items-per-page="tasks.length"
            :items="tasks"
            :loading="loading"
            :single-select="false"
            :item-class="isTaskEnd"
            checkbox-color="primary"
            class="elevation-1"
            item-key="_id"
            loading-text="Задачи загружаются..."
            multi-sort
            show-select>
            <template v-slot:top>
              <v-toolbar flat>
                <div>Задачи</div>
                <v-spacer />
                <NewTask />
              </v-toolbar>
            </template>

            <template v-slot:[`item.domain`]="{ value }">
              <span>{{ value }}</span>
            </template>

            <template v-slot:[`item.requestMapLength`]="{ value }">
              <span style="cursor: pointer">
                {{ value }}
              </span>
            </template>

            <template v-slot:[`item.data`]="{ item }">
              <div class="my-3" style="min-width: 120px">
<!--                <div>-->
<!--                  Конкуренты:-->
<!--                  <b>{{ item.badClicks.min }} - {{ item.badClicks.max }}</b>-->
<!--                </div>-->
<!--                <div>-->
<!--                  Глубина: <b>{{ item.depth.min }} - {{ item.depth.max }}</b>-->
<!--                </div>-->
<!--                <div>-->
<!--                  Время: <b>{{ item.time.min }} - {{ item.time.max }}</b>-->
<!--                </div>-->
                <div>
                  Страницы: <b>{{ item.pagesLook?.from || 0}} - {{ item.pagesLook?.to || 0}}</b>
                </div>
                <div>
                  Разброс: <b>{{ item.beforeAndAfterLook?.before || 0}} - {{ item.beforeAndAfterLook?.after || 0}}</b>
                </div>
                <div>
                  Глубина: <b>{{ item.depth?.from || 0 }} - {{ item.depth?.to || 0}}</b>
                </div>
                <div>
                  Нахождение на сайте (секунд): <b>{{ item.timeWalk?.from || 0}} - {{ item.timeWalk?.to || 0}}</b>
                </div>
                <div>
                  Без профилей? тогда скок гулять: <b>{{ item.goneWithoutCookie }} </b>
                </div>
                <div>
                  Блочить Я.Метрику?: {{ item.blockYa }}
                </div>
                <div>
                  Используемые прокси: <b>{{ item.proxies }}</b>
                </div>
              </div>
            </template>

            <template v-slot:[`item.percents`]="{ item }">
              <div class="my-3" style="min-width: 120px">
<!--                <div>-->
<!--                  Отказы: <b>{{ item.refusal }}%</b>-->
<!--                </div>-->
                <div>
                  Телефоны: <b>{{ item.mobiles }}%</b>
                </div>
              </div>
            </template>

            <template v-slot:[`item.profileNames`]="{ value }">
              <span style="cursor: pointer">
                {{ value }}
              </span>
            </template>

            <template v-slot:[`item.intervals`]="{ value }">
              <span style="cursor: pointer">
                {{ value }}
              </span>
            </template>

            <template v-slot:[`item.date`]="{ item }">
              <div class="my-3" style="min-width: 190px">
                <div>
                  Дата начала:
                  {{ new Date(item.dateStart).toLocaleDateString() }}
                </div>
                <div>
                  Дата окончания:
                  {{ new Date(item.dateEnd).toLocaleDateString() }}
                </div>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-btn
                icon
                @click.stop.prevent="handleChangePause(item)"
                :loading="item.pause == 'loading'">
                <v-icon
                  v-if="item.status == 'online'"
                  :size="20"
                  :title="item.pause ? 'Play' : 'Pause'"
                  class="icon">
                  {{ item.pause ? 'mdi-play' : 'mdi-pause' }}
                </v-icon>
                <v-icon
                  v-else
                  :size="20"
                  :title="
                    item.status.includes('selector')
                      ? item.status == 'selector'
                        ? 'Ошибка селектора'
                        : 'Ошибка селектора 2'
                      : 'Ошибка загрузки'
                  "
                  class="icon"
                  color="error">
                  mdi-alert
                </v-icon>
              </v-btn> -->

              <v-btn
                icon
                @click.stop.prevent="handleEdit(item)"
                :loading="deletingId == item._id"
                title="Edit">
                <v-icon :size="20" class="icon"> mdi-pencil </v-icon>
              </v-btn>

              <v-btn
                icon
                @click.stop.prevent="deleteTask(item)"
                :loading="deletingId == item._id"
                title="Delete">
                <v-icon :size="20" class="icon"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <EditTask :task="editingTask" ref="EditTaskRef" />
          <TaskRequests ref="TaskRequestsRef" />
          <TaskProfiles ref="TaskProfilesRef" />
          <TaskIntervals ref="TaskIntervalsRef" />
        </v-tab-item>

        <v-tab-item class="main-outter">
          <Proxies />
        </v-tab-item>
<!--        <v-tab-item class="main-outter">-->
<!--          <Requests />-->
<!--        </v-tab-item>-->
        <v-tab-item class="main-outter">
          <Profiles />
        </v-tab-item>
        <v-tab-item class="main-outter">
          <Stats />
        </v-tab-item>
        <v-tab-item class="main-outter">
          <StatsTaskCaptcha />
        </v-tab-item>
        <v-tab-item class="main-outter">
          <StatsProfileCaptcha />
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import yandexHeaders from '@/resources/js/data/yandexHeaders.js';

import DeleteDialog from './DeleteDialog';
import NewTask from './NewTask';
import EditTask from './EditTask';
//import Proxy from './Proxy';

import TaskIntervals from './TaskIntervals/TaskIntervals.vue';
import TaskProfiles from './TaskProfiles.vue';
import TaskRequests from './TaskRequests/TaskRequests';

import Proxies from '@/components/Cabinet/tabs/Proxies/Proxies';
// import Requests from '@/components/Cabinet/tabs/Yandex/Requests.vue';
import Profiles from '@/components/Cabinet/tabs/Yandex/Profiles.vue';
import Stats from '@/components/Cabinet/tabs/Yandex/Stats.vue';
import StatsTaskCaptcha from '@/components/Cabinet/tabs/Yandex/StatsTaskCaptcha.vue';
import StatsProfileCaptcha from '@/components/Cabinet/tabs/Yandex/StatsProfileCaptcha.vue';

export default {
  name: 'Yandex',

  data() {
    return {
      loading: true,
      tab: 0,

      deletingId: null,
      deletingItem: null,
      editingTask: null,

      selectedItems: [],

      headers: yandexHeaders,
    };
  },

  components: {
//    Proxy,

    NewTask,
    EditTask,
    DeleteDialog,

    TaskIntervals,
    TaskProfiles,
    TaskRequests,

    Proxies,
    // Requests,
    Profiles,
    Stats,
    StatsTaskCaptcha,
    StatsProfileCaptcha,
  },

  computed: {
    ...mapState('yandex', ['tasks']),
  },

  async created() {
    await Promise.all([this.getTasks(), this.getProxiesCollections()]);
    this.loading = false;

    let sec = 10;
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    let interval = async () => {
      if (this.tab === 0) {
        console.log(
          `${new Date().toLocaleString('ru', options)} Loading Tasks...`
        );
        await this.getTasks();
        sec = 5;
      }

      setTimeout(interval, sec * 1000);
    };

    setTimeout(interval, sec * 1000);
  },

  methods: {
    ...mapActions(['getProxiesCollections']),
    ...mapActions('yandex', ['getTasks', 'editTask']),

    rowClick(item, b, event) {
      const srcElement = event.srcElement;
      if (
        srcElement.className.includes('requests') ||
        srcElement.parentElement.className.includes('requests')
      ) {
        this.$refs.TaskRequestsRef.task = item;
      }
      if (
        srcElement.className.includes('profiles') ||
        srcElement.parentElement.className.includes('profiles')
      ) {
        this.$refs.TaskProfilesRef.task = item;
      }
      if (
        srcElement.className.includes('intervals') ||
        srcElement.parentElement.className.includes('intervals')
      ) {
        this.$refs.TaskIntervalsRef.task = item;
      }
    },

    handleEdit(item) {
      this.editingTask = item;
      this.$refs.EditTaskRef.dialog = true;
    },

    deleteTask(item) {
      if (this.selectedItems.length > 1)
        this.deletingItem = {
          name:
            this.selectedItems.length +
            ' ' +
            this.tasksString(this.selectedItems.length),
          _id: 'length',
        };
      else this.deletingItem = item;

      this.$refs.DeleteDialogRef.dialog = true;
    },

    isTaskEnd(item) {
      if (new Date(item.dateEnd).getTime() < new Date().getTime())
        return 'taskEnd';
    },

    tasksString(n) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return 'Задач';
      }
      if (n1 > 1 && n1 < 5) {
        return 'Задачи';
      }
      if (n1 == 1) {
        return 'Задачу';
      }
      return 'Задач';
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/_colors.scss';

.main {
  color: $gray_100;

  .main-outter {
    position: relative;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .elevation-1 {
      min-width: 80%;
    }
  }

  .mr5px {
    margin-right: 5px;
  }

  .ml5px {
    margin-left: 5px;
  }

  .icon:hover {
    color: $pink;
  }
}
</style>

<style>
.taskEnd {
  opacity: 0.5 !important;
}
</style>
