<template>
  <div class="main">
    <div v-if="_id == '5fbcc32523786b5cf0c4716e'">
      <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
            New Map
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">New Map</span>
          </v-card-title>
          <v-form v-model="valid" ref="form">
            <v-container class="text-center">
              <v-select
                v-model="type"
                :items="types"
                :rules="typeRules"
                label="Назначение"
                required
              ></v-select>
              <v-textarea
                v-model="data"
                :rules="dataRules"
                label="Данные"
              ></v-textarea>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-btn text @click="close">Cancel</v-btn>
            <v-btn color="primary" text @click="save">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-tabs v-model="tab" centered>
      <v-tab>Adminer</v-tab>
      <v-tab>Worker</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="tab-outter">
        <v-timeline>
          <v-timeline-item v-for="(map, i) in adminerMaps" :key="i + 'Adminer'">
            <template v-slot:icon
              ><v-icon v-if="map.check">mdi-check-all</v-icon></template
            >
            <v-card width="1000px" color="gray_800">
              <v-card-text>
                <ul>
                  <li v-for="item in map.data" :key="item" class="data-text">
                    {{ item }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-tab-item>
      <v-tab-item class="tab-outter">
        <v-timeline>
          <v-timeline-item v-for="(map, i) in workerMaps" :key="i + 'Adminer'">
            <template v-slot:icon
              ><v-icon v-if="map.check">mdi-check-all</v-icon></template
            >
            <v-card width="1000px" color="gray_800">
              <v-card-text>
                <ul>
                  <li v-for="item in map.data" :key="item" class="data-text">
                    {{ item }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline></v-tab-item
      >
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Roadmap",

  data() {
    return {
      tab: 0,

      dialog: false,
      valid: false,

      workerMaps: [],
      adminerMaps: [],

      type: "",
      types: ["Adminer", "Worker"],
      typeRules: [(v) => !!v || "Необходимо выбрать назначение"],

      data: "",
      dataRules: [
        (v) => {
          if (v != undefined) {
            let check = v.split("\n");
            let flag = true;
            for (let str of check) {
              if (!/[.]$/.test(str)) {
                flag = false;
                break;
              }
            }
            return flag ? !!v : "Введены некорректные данные";
          }
        },
      ],
    };
  },

  components: {},

  computed: { ...mapState(["_id"]) },

  watch: {},

  async beforeMount() {
    let data = await this.getMaps();

    for (let map of data) {
      if (map.type == "Worker") this.workerMaps.push(map);
      else this.adminerMaps.push(map);
    }
  },

  async mounted() {},

  methods: {
    ...mapActions(["createMap", "getMaps"]),

    close() {
      this.dialog = false;
      this.valid = false;
      this.type = "";
      this.data = "";
    },
    async save() {
      this.$refs.form.validate();
      if (this.valid) {
        let res = await this.createMap({
          map: {
            type: this.type,
            data: this.data,
          },
        });
        if (res == "200") {
          this.close();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_colors.scss";
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tab-outter {
    .data-text {
      font-size: 16px;
      color: $gray_100;
      margin-bottom: 5px;
    }
  }
}
</style>