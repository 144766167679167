import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

import {
  editServer_DeviceType,
  editServer_Blocking,
  editServers_Proxies,
  editServers_Pause,
  editServer_NewCookies,
} from './servers';

import {
  getProxiesCount,
  getProxiesCollections,
  postProxies,
  addProxyCollection,
  addProxyCollections_byGroups,
  editProxyCollection,
  editProxyCollectionMobile,
  deleteProxyCollection,
  showProxies,
  downloadProxies,
} from './proxies';

import money from './money';
import mobile_proxies from './mobile_proxies';
import yandex from './stores/yandex/yandex';
import mironov from './mironov';
import spoof from './spoof';
import proxiesStats from './stores/proxiesStats';
import proxiesErrors from './stores/proxiesErrors';

Vue.use(Vuex);

const url = 'api/';

export default new Vuex.Store({
  modules: {
    money,
    mobile_proxies,
    yandex,

    mironov,
    spoof,
    proxiesStats,
    proxiesErrors,
  },

  state: {
    _id: -1,
    user_login: null,
    users: undefined,

    logError: 0,
    user: undefined,
    isLogin: false,
    isReady: false,

    servers: [],

    stats: {
      entries_all: 0,
      shows_all: 0,
      recycling_all: 0,
    },
    graphs: null,

    stats_dates: [new Date(Date.now() + 10800000).toISOString().substr(0, 10)],
    table_time: 0,
    show_more: 0,
    show_more_type: 0,
    isServersStats: true,

    deletingId: -1,

    Server: null,

    collections: [],

    lastGetTime: null,
    abortController: null,
  },
  mutations: {
    setLogError(state, error) {
      state.logError = error;
    },

    setNewUserLogin(state, user_login) {
      state.user_login = user_login;

      const newUser = state.users.find(user => user.login === user_login);
      state._id = newUser._id;
    },

    setLogin(state, { user, users }) {
      state.isLogin = true;
      state._id = user._id;
      state.user = user;
      state.users = users;

      state.user_login = user.login;
    },
    logOut(state) {
      state._id = -1;
      state.logError = 0;
      state.login = '';
      state.isLogin = false;

      localStorage.removeItem('login');
      localStorage.removeItem('password');
    },
    //Fun for loader cmp
    setIsReady(state, ready) {
      state.isReady = ready;
    },

    setServers(state, servers) {
      state.servers = servers;
    },
    addServer(state, server) {
      state.servers.push(server);
    },
    deleteServer(state, id) {
      const index = state.servers.findIndex(server => server._id == id);
      state.servers.splice(index, 1);
    },

    setStatsDate(state, dates) {
      state.stats_dates = dates;
    },
    setTableTime(state, table_time) {
      state.table_time = table_time;
    },
    setShowMore(state, show_more) {
      state.show_more = show_more;
    },
    setShowMoreType(state, show_more_type) {
      state.show_more_type = show_more_type;
    },
    setIsServersStats(state, isServers) {
      state.isServersStats = isServers;
    },

    updateAbortController(state, time) {
      try {
        state.abortController.abort();
      } catch (err) {}

      state.abortController = new AbortController();
      state.lastGetTime = time;
    },
  },

  actions: {
    editServer_DeviceType,
    editServer_Blocking,
    editServers_Proxies,
    editServers_Pause,
    editServer_NewCookies,

    getProxiesCount,
    getProxiesCollections,
    postProxies,
    addProxyCollection,
    addProxyCollections_byGroups,
    editProxyCollection,
    editProxyCollectionMobile,
    deleteProxyCollection,
    showProxies,
    downloadProxies,

    async getServers({ state, commit }) {
      try {
        const res = await axios.get(
          url + `servers/${state._id}.${state.user.admin}`
        );
        res.data.sort((prev, next) => {
          if (prev.name < next.name) return -1;
          if (prev.name < next.name) return 1;
        });
        state.servers = res.data;
      } catch (e) {}
    },

    async getStats({ state, commit }) {
      try {
        const start_time = performance.now();
        commit('updateAbortController', start_time);

        const res = await axios.get(
          url +
            `stats/${state._id}.${JSON.stringify(state.stats_dates)}.${
              state.table_time
            }.${state.show_more}.${state.show_more_type}.${
              state.isServersStats
            }`,
          { signal: state.abortController.signal }
        );

        state.stats = res.data.stats;

        if (state.isServersStats)
          for (const i in state.stats[0].tableData) {
            const serverIP = state.stats[0].tableData[i].ip;
            const serverUrl = state.servers.find(
              server => server.ip == serverIP
            )?.url;

            state.stats[0].tableData[i].url = serverUrl;
          }

        state.graphs = res.data.graphs;
      } catch (e) {}
    },

    async deleteStats({ state }) {
      const res = await axios.delete(url + `stats/${state._id}`);

      state.stats = res.data;
    },

    async getVIDsStats({ state }) {
      const res = await axios.get(url + `stats/VIDs`);

      return res.data;
    },

    async createServers({ state, commit }, { servers }) {
      let res = await axios
        .post(url + 'servers/create', {
          user_id: state._id,
          servers,
        })
        .then(response => response)
        .catch(err => {
          return {
            status: '404',
          };
        });

      if (res.status == '201')
        for (let server of res.data.serversGood) commit('addServer', server);
      if (res.data.serversBad.length > 0)
        res = {
          status: '409',
          serversBad: res.data.serversBad,
        };
      return res;
    },

    async getVersions({ state }) {
      let res = await axios.get(url + `info/versions/${state._id}`);
      state.user.last_version = 'true';

      return res.data;
    },

    async getMaps() {
      let res = await axios.get(url + 'info/maps');
      return res.data;
    },

    async createVersion({ state, commit }, { version }) {
      let res = await axios
        .post(url + 'info/version', {
          user_id: state._id,
          version,
        })
        .then(response => response)
        .catch(err => {
          return {
            status: '404',
          };
        });
      return res.status;
    },

    async createMap({ state, commit }, { map }) {
      let res = await axios
        .post(url + 'info/map', {
          user_id: state._id,
          map,
        })
        .then(response => response)
        .catch(err => {
          return {
            status: '404',
          };
        });
      return res.status;
    },

    async editServer({ state, commit }, server) {
      let res = await axios
        .post(url + 'servers/edit/' + server._id, {
          user_id: state._id,
          server,
        })
        .then(response => response)
        .catch(err => {
          if (err.message.indexOf(409) != -1)
            return {
              status: '409',
            };
          else
            return {
              status: '404',
            };
        });

      return res;
    },

    async editServers({}, { servers, data, fields, urlDistr }) {
      let res = await axios
        .post(url + 'servers/edit_many', {
          servers,
          data,
          fields,
          urlDistr,
        })
        .then(response => response)
        .catch(err => {
          if (err.message.indexOf(409) != -1)
            return {
              status: '409',
            };
          else
            return {
              status: '404',
            };
        });

      return res;
    },

    async _deleteServer({ state, commit }, { id }) {
      state.deletingId = id;
      let res = await axios.delete(url + `servers/${id}`).catch(err => {});
      state.deletingId = -1;

      commit('deleteServer', id);
    },

    async startDeploy({ state, dispatch }, { server }) {
      server.status = 'deploying';

      let res = await axios
        .post(url + `servers/deploy/${server._id}`, {
          ip: server.ip,
          port: server.port,
          username: server.username,
          password: server.password,
          redeploy: server.deploied,
          user_id: state._id,
        })
        .then(response => response)
        .catch(err => {});

      dispatch('getServers');
    },

    async startWorker({ dispatch }, { server }) {
      server.status = 'loading';

      let res = await axios
        .post(url + `servers/startWorker/${server._id}`, {
          ip: server.ip,
          port: server.port,
          username: server.username,
          password: server.password,
        })
        .then(response => response)
        .catch(err => {});

      dispatch('getServers');
    },

    async signup({ commit }, { login, password }) {
      try {
        const res = await axios.post(url + 'auth/signup', {
          login,
          password,
        });

        localStorage.setItem('login', login);
        localStorage.setItem('password', password);

        commit('setLogin', {
          login: login,
          id: res.data,
        });
      } catch (err) {
        if (err.response) commit('setLogError', err.response.status);
        return 409;
      }
    },

    async signin({ commit }, { login, password }) {
      try {
        const res = await axios.post(url + 'auth/signin', {
          login,
          password,
        });

        localStorage.setItem('login', login);
        localStorage.setItem('password', password);

        let users;
        if (res.data.user.admin) users = await axios.get(url + 'auth/users');

        if (res.status == 200) {
          commit('setLogin', {
            user: res.data.user,
            users: users
              ? [{ login: 'all~~', type: 'all~~', _id: 'all~~' }, ...users.data]
              : undefined,
          });
        }
      } catch (err) {
        commit('setLogError', err.response.status);
        return 404;
      }
    },

    async signin_log({ state }, { city, display }) {
      try {
        await axios.post(url + 'auth/signin_log', {
          user: state.user,
          city,
          display,
        });
      } catch (err) {}
    },
  },
});
