import { render, staticRenderFns } from "./Log_Reg.vue?vue&type=template&id=3332a129&scoped=true"
import script from "./Log_Reg.vue?vue&type=script&lang=js"
export * from "./Log_Reg.vue?vue&type=script&lang=js"
import style0 from "./Log_Reg.vue?vue&type=style&index=0&id=3332a129&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3332a129",
  null
  
)

export default component.exports