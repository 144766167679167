<template>
  <apexchart
    height="200px"
    type="line"
    :options="options"
    :series="_series"
  ></apexchart>
</template>

<script>
export default {
  name: 'Chart',
  props: ['_series', 'title'],

  data() {
    return {
      options: {
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: 'mine',
          style: {
            fontSize: '14px',
            fontFamily: 'Roboto',
          },
        },
        title: {
          text: '',
          align: 'center',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            color: '#e1e3e6',
          },
        },
        colors: [
          '#E6457A',
          '#937ff5',
          '#2EBFE6',
          '#FFC64D',
          '#96E946',
          '#FA71A8',
        ],

        chart: {
          sparkline: {
            enabled: true,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          foreColor: '#e1e3e6',
          id: '',
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        xaxis: {
          categories: [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23,
          ],
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    };
  },

  async beforeMount() {
    this.options.title.text = this.title;
    this.options.chart.id = this.title;
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';
</style>
