<template>
  <span :class="{ AvailSuccess }">
    {{ item.data }}
  </span>
</template>

<script>
export default {
  name: 'TableItemData',

  props: ['item'],

  components: {},

  data() {
    return {};
  },

  computed: {
    AvailSuccess() {
      return this.item.status == 'AvailSuccess';
    },
  },

  watch: {},

  async beforeMount() {},

  async mounted() {},

  methods: {},
};
</script>

<style scoped lang="scss"></style>
