import axios from 'axios';
const url = 'api/';

export default {
  actions: {
    async getMobileProxies_cnt({}) {
      try {
        const res = await axios.get(url + `mobile_proxies/proxies_cnt`);
        return res.data.length;
      } catch (e) {}
    },
    async getMobileProxies({}) {
      try {
        const res = await axios.get(url + `mobile_proxies/proxies/`);
        return res.data;
      } catch (e) {}
    },

    async getMobileAddresses_cnt({}) {
      try {
        const res = await axios.get(url + `mobile_proxies/addresses_cnt`);
        return res.data.length;
      } catch (e) {}
    },
    async getMobileAddresses({}, page) {
      try {
        const res = await axios.get(url + `mobile_proxies/addresses/${page}`);
        return res.data;
      } catch (e) {}
    },

    async getMobileProxies_list_proxies({}, proxy) {
      try {
        const res = await axios.get(
          url + `mobile_proxies/list_proxies/${proxy}`
        );
        return res.data;
      } catch (e) {}
    },
    async getMobileProxies_list_addresses({}, ip) {
      try {
        const res = await axios.get(
          url + `mobile_proxies/list_addresses/${ip}`
        );
        return res.data;
      } catch (e) {}
    },
  },
};
