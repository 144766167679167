<template>
  <div class="proxy">
    <div class="proxy-outer">
      <div>
        <v-data-table
          :headers="headers"
          :items="profiles"
          :items-per-page="profiles.length"
          :search="search"
          class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <span>Proxies</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-autocomplete
                v-model="search"
                :items="
                  profiles.map(profile => profile.collection_name)
                "
                dense
                clearable
                label="Search"></v-autocomplete>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogAdd" persistent max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on">
                    New Profile
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline"> Add Profiles </v-card-title>
                  <v-form v-model="addValid" ref="addForm">
                    <v-container class="text-center">
                      <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        label="Название коллекции профилей"
                        required />
                      <v-text-field
                        v-model="numProfiles"
                        :rules="numberRules"
                        label="Количество профилей создать и нагулять"
                        required />
                      <v-text-field
                        v-model="numProfilesPerOnce"
                        :rules="numberRules"
                        label="Сколько за раз делать"
                        required />
                      <v-text-field
                        v-model.number="numSites"
                        :rules="numberRules"
                        class="mr-5"
                        label="Сколько сайтов посетить"
                        required
                        type="number" />
                      <v-text-field
                        v-model.number="dayCreate"
                        :rules="numberRules"
                        class="mr-5"
                        label="Сколько создавать в день (0 = все)"
                        required
                        type="number" />
                      <div class="row" style="margin: 0">
                        <v-text-field
                          v-model.number="numSitesFrom"
                          :rules="numberRules"
                          class="mr-5"
                          label="Мин Сколько сайтов посетить"
                          required
                          type="number" />
                        <v-text-field
                          v-model.number="numSitesTo"
                          :rules="numberRules"
                          label="Макс Сколько сайтов посетить"
                          required
                          type="number" />
                      </div>

                      <div class="row" style="margin: 0">
                        <v-text-field
                          v-model.number="numSitesPerOneWalk.from"
                          :rules="numberRules"
                          class="mr-5"
                          label="Мин скок посещать за раз"
                          required
                          type="number" />
                        <v-text-field
                          v-model.number="numSitesPerOneWalk.to"
                          :rules="numberRules"
                          label="Макс скок посещать за раз"
                          required
                          type="number" />
                      </div>
                        <div class="row" style="margin: 0">
                      <v-text-field
                        v-model.number="depth.from"
                        :rules="numberRules"
                        class="mr-5"
                        label="Мин глубина"
                        required
                        type="number" />
                      <v-text-field
                        v-model.number="depth.to"
                        :rules="numberRules"
                        label="Макс глубина"
                        required
                        type="number" />
                        </div>
                          <div class="row" style="margin: 0">
                      <v-text-field
                        v-model.number="timeWalk.from"
                        :rules="numberRules"
                        class="mr-5"
                        label="Мин гулять по сайту"
                        required
                        type="number" />
                      <v-text-field
                        v-model.number="timeWalk.to"
                        :rules="numberRules"
                        label="Макс гулять по сайту"
                        required
                        type="number" />
                          </div>
                      <div class="row" style="margin: 0">
                        <v-text-field
                          v-model.number="yandexWalk.from"
                          :rules="numberRules"
                          class="mr-5"
                          label="Мин гулять в Я"
                          required
                          type="number" />
                        <v-text-field
                          v-model.number="yandexWalk.to"
                          :rules="numberRules"
                          label="Макс гулять в Я"
                          required
                          type="number" />
                      </div>

<!--                        <v-text-field-->
<!--                          v-model.number="countDogul"-->
<!--                          :rules="numberRules"-->
<!--                          class="mr-5"-->
<!--                          label="Сколько сайтов догуливать"-->
<!--                          required-->
<!--                          type="number" />-->
<!--                        <v-text-field-->
<!--                          v-model.number="hoursDogul"-->
<!--                          :rules="numberRules"-->
<!--                          label="Через сколько часов после создания догуливать"-->
<!--                          required-->
<!--                          type="number" />-->
<!--                      <v-text-field-->
<!--                        v-model.number="usesDogul"-->
<!--                        :rules="numberRules"-->
<!--                        label="Через сколько использований после создания догуливать"-->
<!--                        required-->
<!--                        type="number" />-->


                      <v-text-field
                        v-model.number="hoursWait"
                        :rules="numberRules"
                        class="mr-5"
                        label="Через сколько часов после создания использовать профиль"
                        required
                        type="number" />
                      <v-text-field
                        v-model.number="usesInOneWork"
                        :rules="numberRules"
                        label="Сколько раз использовать профиль в одной задаче"
                        required
                        type="number" />

                      <v-text-field
                        v-model.number="timeBetweenReqMinutes"
                        :rules="numberRules"
                        class="mr-5"
                        label="Сколько минут ждать между посещение кластера"
                        required
                        type="number" />
                      <div>PROXY (выбери одно): </div>
                      <v-virtual-scroll
                        :items="proxieCollections"
                        ref="vScroll"
                        height="150"
                        item-height="50"
                        max-width="100%"
                        :class="highlightClass">
                        <template v-slot:default="{ item }">
                          <div class="site" @mouseenter="handleEnterProxy(item._id, item.collection_name)">
                            <v-simple-checkbox
                              @mousedown="handleDownProxy(item._id, item.collection_name)"
                              :value="isSelectedProxy(item._id)"
                              hide-details
                              color="primary" />
                            <div class="proxy-name">{{ item.collection_name }}</div>
                          </div>
                        </template>
                      </v-virtual-scroll>
                      <v-slider
                        v-model.number="procentMob"
                        class="mr-2 mt-2"
                        label="% Телефонов"
                        min="0"
                        max="100"
                        required
                        thumb-label="always" />
                      <v-slider
                        v-model.number="procentMan"
                        class="mr-2 mt-2"
                        label="% Мужчин (остальные женщины)"
                        min="0"
                        max="100"
                        required
                        thumb-label="always" />
                      <v-slider
                        v-model.number="procentNotYandex"
                        class="mr-2 mt-2"
                        label="% прямых заходов (остальное Я)"
                        min="0"
                        max="100"
                        required
                        thumb-label="always" />
                      <div>Тематика:</div>
                      <v-virtual-scroll
                        :items="sites"
                        ref="vScroll"
                        height="400"
                        item-height="50"
                        max-width="100%"
                        :class="{'highlight-scroll': !isSitesSelectionValid}">
                        <template v-slot:default="{ item }">
                          <div class="site" @mouseenter="handleEnter(item._id)">
                            <v-simple-checkbox
                              @mousedown="handleDown(item._id)"
                              :value="isSelected(item._id)"
                              hide-details
                              color="primary" />
                            <div class="site-name">{{ item.name }}</div>
                            <v-text-field
                              v-model.number="item.procentUrls"
                              class="procent-field"
                              label="процент"
                              required
                              type="number" />
                          </div>
                        </template>
                      </v-virtual-scroll>

                    </v-container>

                  </v-form>
                  <v-alert v-if="error" dense outlined type="error">
                    <div class="myError">
                      Данное название коллекции уже используется
                    </div>
                  </v-alert>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">Cancel</v-btn>
                    <v-btn :disabled="!isProxyAndSitesSelectionValid" color="primary" text @click="addItemConfirm">
                      OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>


          <template v-slot:item.done="{ item }">
            Готово: {{ item.doneProfiles }} | Создано: {{ item.allCreatedProfiles }} |
            Создано за сегодня {{ item.dayCreateAlready }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon @click.stop.prevent="editItem(item)" title="Edit">
              <v-icon :size="20" class="icon"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon @click.stop.prevent="deleteItem(item)" title="Delete">
              <v-icon :size="20" class="icon"> mdi-delete </v-icon>
            </v-btn>

          </template>

          <template v-slot:[`item.data`]="{ item }">
            <div class="my-3" style="min-width: 120px">
              <div>
                Создать профилей:
                <b>{{item.numProfiles}}</b>
              </div>
              <div>
                За раз создается:
                <b>{{item.numProfilesPerOnce}}</b>
              </div>
              <div>
                Сайтов посетить:
                <b>{{item.numSitesFrom}} - {{item.numSitesTo}}</b>
              </div>
              <div>
                Посещений за раз: <b> {{item.numSitesPerOneWalk.from || 0}} - {{item.numSitesPerOneWalk.to || 0}}</b>
              </div>
              <div>
                Глубина: <b> {{item.depth?.from || 0 }} - {{item.depth?.to || 0 }}</b>
              </div>
              <div>
                Нахождение на сайте: <b> {{item.timeWalk?.from || 0 }} - {{item.timeWalk?.to || 0 }}</b>
              </div>
              <div>
                Гулять в Я: <b> {{item.yandexWalk?.from || 0 }} - {{item.yandexWalk?.to || 0 }}</b>
              </div>
              <div>
                Ожидание между посещениями: <b>{{item.timeBetweenReqMinutes}}</b> минут
              </div>
              <div>
                Мобилок: <b>{{ item.procentMob }}</b>%
              </div>
              <div>
                Мужчин (остальные женщины): <b>{{ item.procentMan || 0}}</b>%
              </div>
              <div>
                % прямых заходов (остальное Я): <b>{{ (100 - item.procentNotYandex) || 100 }}</b>%
              </div>
<!--              <div>-->
<!--                Скок сайтов догулять если надо: <b>{{ item.countDogul || 0 }}</b>-->
<!--              </div>-->
<!--              <div>-->
<!--                Через скок часов после создания надо догуливать: <b>{{ item.hoursDogul || 0 }}</b>-->
<!--              </div>-->
<!--              <div>-->
<!--                Через скок использований надо догуливать: <b>{{ item.usesDogul || 0 }}</b>-->
<!--              </div>-->
              <div>
                Через сколько часов после создания запускать в работу: <b>{{ item.hoursWait || 0 }}</b>
              </div>
              <div>
                Сколько раз использовать профиль в одной задаче: <b>{{ item.usesInOneWork || 0 }}</b>
              </div>
              <div>
                Создавать профилей в день: <b>{{ item.dayCreate || 0 }}</b>
              </div>
              <div>
                Используемые прокси: <b>{{ item.proxie }}</b>
              </div>
              <div v-for="(site, index) in item.selectedSites" :key="index">
                <div><b>{{ site.name }} - {{ site.procentUrls }}%</b></div>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>


    <v-dialog v-model="dialogEdit" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Edit Collection {{ editedItem.collection_name }}
        </v-card-title>
        <v-form v-model="editValid" ref="editForm">
          <v-container class="text-center">
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Название коллекции профилей"
              required />
            <v-text-field
              v-model="numProfiles"
              :rules="numberRules"
              label="Количество профилей создать и нагулять"
              required />
            <v-text-field
              v-model="numProfilesPerOnce"
              :rules="numberRules"
              label="Сколько за раз делать"
              required />
            <v-text-field
              v-model.number="numSites"
              :rules="numberRules"
              class="mr-5"
              label="Сколько сайтов посетить"
              required
              type="number" />
            <v-text-field
              v-model.number="dayCreate"
              :rules="numberRules"
              class="mr-5"
              label="Сколько создавать в день (0 = все)"
              required
              type="number" />
            <div class="row" style="margin: 0">
              <v-text-field
                v-model.number="numSitesFrom"
                :rules="numberRules"
                class="mr-5"
                label="Мин Сколько сайтов посетить"
                required
                type="number" />
              <v-text-field
                v-model.number="numSitesTo"
                :rules="numberRules"
                label="Макс Сколько сайтов посетить"
                required
                type="number" />
            </div>
            <div class="row" style="margin: 0">
              <v-text-field
                v-model.number="numSitesPerOneWalk.from"
                :rules="numberRules"
                class="mr-5"
                label="Мин скок посещать за раз"
                required
                type="number" />
              <v-text-field
                v-model.number="numSitesPerOneWalk.to"
                :rules="numberRules"
                label="Макс скок посещать за раз"
                required
                type="number" />
            </div>
            <div class="row" style="margin: 0">
            <v-text-field
              v-model.number="depth.from"
              :rules="numberRules"
              class="mr-5"
              label="Мин глубина"
              required
              type="number" />
            <v-text-field
              v-model.number="depth.to"
              :rules="numberRules"
              label="Макс глубина"
              required
              type="number" />
            </div>
            <div class="row" style="margin: 0">
            <v-text-field
              v-model.number="timeWalk.from"
              :rules="numberRules"
              class="mr-5"
              label="Мин гулять по сайту"
              required
              type="number" />
            <v-text-field
              v-model.number="timeWalk.to"
              :rules="numberRules"
              label="Макс гулять по сайту"
              required
              type="number" />
            </div>
            <div class="row" style="margin: 0">
              <v-text-field
                v-model.number="yandexWalk.from"
                :rules="numberRules"
                class="mr-5"
                label="Мин гулять в Я"
                required
                type="number" />
              <v-text-field
                v-model.number="yandexWalk.to"
                :rules="numberRules"
                label="Макс гулять в Я"
                required
                type="number" />
            </div>
<!--              <v-text-field-->
<!--                v-model.number="countDogul"-->
<!--                :rules="numberRules"-->
<!--                class="mr-5"-->
<!--                label="Сколько сайтов догуливать"-->
<!--                required-->
<!--                type="number" />-->
<!--              <v-text-field-->
<!--                v-model.number="hoursDogul"-->
<!--                :rules="numberRules"-->
<!--                label="Через сколько часов после создания догуливать"-->
<!--                required-->
<!--                type="number" />-->
<!--            <v-text-field-->
<!--              v-model.number="usesDogul"-->
<!--              :rules="numberRules"-->
<!--              label="Через сколько использований после создания догуливать"-->
<!--              required-->
<!--              type="number" />-->

              <v-text-field
                v-model.number="hoursWait"
                :rules="numberRules"
                class="mr-5"
                label="Через сколько часов после создания использовать профиль"
                required
                type="number" />
              <v-text-field
                v-model.number="usesInOneWork"
                :rules="numberRules"
                label="Сколько раз использовать профиль в одной задаче"
                required
                type="number" />

            <v-text-field
              v-model.number="timeBetweenReqMinutes"
              :rules="numberRules"
              class="mr-5"
              label="Сколько минут ждать между посещение кластера"
              required
              type="number" />
            <div>PROXY (выбери одно): </div>
            <v-virtual-scroll
              :items="proxieCollections"
              ref="vScroll"
              height="150"
              item-height="50"
              max-width="100%"
              :class="{'highlight-scroll': this.selectedProxy.length == 0}">
              <template v-slot:default="{ item }">
                <div class="site" @mouseenter="handleEnterProxy(item._id, item.collection_name)">
                  <v-simple-checkbox
                    @mousedown="handleDownProxy(item._id, item.collection_name)"
                    :value="isSelectedProxy(item._id)"
                    hide-details
                    color="primary" />
                  <div class="proxy-name">{{ item.collection_name }}</div>
                </div>
              </template>
            </v-virtual-scroll>
            <v-slider
              v-model.number="procentMob"
              class="mr-2 mt-2"
              label="% Телефонов"
              min="0"
              max="100"
              required
              thumb-label="always" />
            <v-slider
              v-model.number="procentMan"
              class="mr-2 mt-2"
              label="% Мужчин (остальные женщины)"
              min="0"
              max="100"
              required
              thumb-label="always" />
            <v-slider
              v-model.number="procentNotYandex"
              class="mr-2 mt-2"
              label="% прямых заходов (остальное Я)"
              min="0"
              max="100"
              required
              thumb-label="always" />
            <div>Тематика:</div>
            <v-virtual-scroll
              :items="sites"
              ref="vScroll"
              height="400"
              item-height="50"
              max-width="100%"
              :class="{'highlight-scroll': !isSitesSelectionValid}">
              <template v-slot:default="{ item }">
                <div class="site" @mouseenter="handleEnter(item._id)">
                  <v-simple-checkbox
                    @mousedown="handleDown(item._id)"
                    :value="isSelected(item._id)"
                    hide-details
                    color="primary" />
                  <div class="site-name">{{ item.name }}</div>
                  <v-text-field
                    v-model.number="item.procentUrls"
                    class="procent-field"
                    label="процент"
                    required
                    type="number" />
                </div>
              </template>
            </v-virtual-scroll>

          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn :disabled="!isProxyAndSitesSelectionValid" color="primary" text @click="editItemConfirm"> OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Вы уверены, что хотите удалить коллекцию
          <span class="collection-name">{{ editedItem.collection_name }}</span>
          ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="deleteItemConfirm"> OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getProxiesCollections } from '@/resources/js/proxies';

export default {
  name: 'Profiles',

  data() {
    return {
      proxies: '',
      loading: false,
      collection_name: 0,
      textValid: false,
      profiles: [],
      sites: [],
      proxieCollections: [],
      selectedProxy: '',
      selectedProxyName: '',
      error: false,
      dialogAdd: false,
      add_groups: false,
      addValid: false,
      id: '',
      dialogEdit: false,
      editValid: false,
      searchValue: '',
      dialogDelete: false,
      numSites: 0,
      numSitesPerOneWalk: {
        from: 0,
        to: 0,
      },
      depth: {
        from: 0,
        to: 0,
      },
      timeWalk: {
        from: 0,
        to: 0,
      },
      yandexWalk: {
        from: 0,
        to: 0,
      },
      hoursWait: 0,
      usesInOneWork: 0,
      countDogul: 0,
      hoursDogul: 0,
      usesDogul: 0,
      numProfiles: 0,
      hoursDelete: 0,
      usesDelete: 0,
      numProfilesPerOnce: 0,
      dayCreate: 0,
      numSitesFrom: 0,
      numSitesTo: 0,
      procentMob: 0,
      procentMan: 0,
      procentNotYandex: 0,
      timeBetweenReqMinutes: 0,
      procentUrls: 0,
      dialogAdd_area: false,

      dialog_show: false,

      groups: '',
      name: '',
      code: '',
      isDown: false,

      editedItem: {},
      selectedItems: [],
      headers: [
        {
          text: 'Name',
          value: 'collection_name',
          divider: true,
        },
        {
          text: 'Done',
          align: 'center',
          value: 'done',
          divider: true,
        },
        {
          text: 'Данные',
          value: 'data',
          sortable: false,
          divider: true,
        },
        {
          text: 'Actions',
          align: 'center',
          value: 'actions',
          sortable: false,
          width: 150,
        },
      ],
      search: '',

      nameRules: [v => !!v || 'Необходимо ввести название'],

      numberRules: [
        v => {
          if (!isNaN(parseFloat(v)) && v >= -1) return true;
          return 'Необходимо ввести число';
        },
      ],

    };
  },

  components: {},

  computed: {
    ...mapState(['user', 'collections']),
    highlightClass() {
      return { 'highlight-scroll': !this.selectedProxy };
    },
    isProxySelected() {
      return !!this.selectedProxy; // true, если selectedProxy — непустая строка
    },
    isSitesSelectionValid() {
      // Здесь принудительно отслеживаем изменения procentUrls для каждого сайта
      const isValid = this.sites.some(site => {
        return this.isSelected(site._id) && typeof site.procentUrls === 'number' && site.procentUrls > 0;
      });
      return isValid;
    },
    isProxyAndSitesSelectionValid() {
      // Возвращаем true, если выбрано прокси и условия выбора сайтов выполнены
      return this.isProxySelected && this.isSitesSelectionValid;
    }
  },

  watch: {
    // async collection_name(newVal, oldVal) {
    //   if (newVal == undefined) {
    //     this.collection_name = oldVal;
    //     newVal = oldVal;
    //   }
    // },
    deleteSitesDialog(val) {
      if (!val) {
        this.selectedItems = [];
      }
    },
    searchValue: 'resetSelected',
  },

  async beforeMount() {

    this.profiles = await this.getProfiles();
    this.sites = await this.getUrls();

    this.proxieCollections = await this.getProxiesCollections()

    this.selectedProxy = ""
    this.selectedProxyName = this.proxieCollections[0].collection_name
    document.addEventListener('mouseup', () => {
      this.isDown = false;
    });
  },

  async mounted() {
    setInterval(async () => {

      await this.getProfiles();
    }, 10 * 1000);
  },

  methods: {
    ...mapActions(['getProxiesCollections']),
    ...mapActions('yandex', [
      'getProfiles',
      'getUrls',
      'addProfileCollection',
      'deleteProfileCollection',
      'getProxieCollections',

      'addProxyCollection',
      'addProxyCollections_byGroups',
      'editProxyCollection',
      'editProxyCollectionMobile',
      'deleteProxyCollection',

      'showProxies',
      'downloadProxies',

      'editProfileCollection',
    ]),

    isSelected(_id) {
      return this.selectedItems.includes(_id);
    },

    resetSelected() {
      this.selectedItems = [];
    },

    handleEnter(_id) {
      if (!this.isDown) return;

      if (this.isSelected(_id))
        this.selectedItems.splice(
          this.selectedItems.findIndex(s => s == _id),
          1
        );
      else this.selectedItems.push(_id);
    },


    handleDown(_id) {
      this.isDown = true;

      if (this.isSelected(_id))
        this.selectedItems.splice(
          this.selectedItems.findIndex(s => s == _id),
          1
        );
      else this.selectedItems.push(_id);
    },
    handleEnterProxy(_id, name) {
      if (!this.isDown) return;

      this.selectedProxy = _id
      this.selectedProxyName = name
    },


    handleDownProxy(_id, name) {
      this.isDown = true;
      this.selectedProxy = _id
      this.selectedProxyName = name

    },
    isSelectedProxy(_id, name) {
      return (this.selectedProxy == _id);
    },
    async close() {


      this.dialogAdd = false;
      this.dialogEdit = false;
      this.dialogDelete = false;
      this.dialogAdd_area = false;
      this.dialog_show = false;
      this.numProfiles = 0;
      this.numProfilesPerOnce = 0;
      this.dayCreate = 0;
      this.numSitesFrom = 0;
      this.numSitesTo = 0;
      this.name = "";
      this.numSites = 0;
      this.numSitesPerOneWalk.from = 0;
      this.numSitesPerOneWalk.to = 0;
      this.depth.from = 0;
      this.depth.to = 0;
      this.timeWalk.from = 0;
      this.timeWalk.to = 0;
      this.yandexWalk.from = 0;
      this.yandexWalk.to = 0;
      this.timeBetweenReqMinutes = 0;
      this.procentMob = 0;
      this.procentMan = 0;
      this.procentNotYandex = 0;
      this.selectedItems = [];
      this.countDogul = 0;
      this.hoursDogul = 0;
      this.usesDogul = 0;
      this.hoursDelete = 0;
      this.usesDelete = 0;
      this.hoursWait = 0;
      this.usesInOneWork = 0;
      this.profiles = await this.getProfiles();
      this.sites = await this.getUrls();

      this.proxieCollections = await this.getProxiesCollections()

      this.selectedProxy = ""
      this.editedItem = {
        collection_name: '',
        description: '',
      };

      this.code = '';
    },

    async download() {
      await this.downloadProxies({
        code: this.code,
        collection_name: this.editedItem.collection_name,
      });

      this.code = '';

      this.close();
    },

    // async addProxies(item) {
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogAdd_area = true;
    // },

    async editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
      this.id = this.editedItem._id;
      this.name = this.editedItem.collection_name;
      this.numProfiles = this.editedItem.numProfiles || 0;
      this.numProfilesPerOnce = this.editedItem.numProfilesPerOnce || 0; // Пример для поля 'numProfilesPerOnce'
      this.numSitesFrom = this.editedItem.numSitesFrom || 0;
      this.numSitesTo =this.editedItem.numSitesTo || 0;
      this.numSites = this.editedItem.numSites || 0; // Пример для поля 'numSites'
      this.numSitesPerOneWalk.from = this.editedItem.numSitesPerOneWalk.from || 0; // Пример для поля 'numSitesPerOneWalk.from'
      this.numSitesPerOneWalk.to = this.editedItem.numSitesPerOneWalk.to || 0; // Пример для поля 'numSitesPerOneWalk.to'
      this.depth.from = this.editedItem.depth?.from || 0;
      this.depth.to = this.editedItem.depth?.to || 0;
      this.timeWalk.from = this.editedItem.timeWalk?.from || 0;
      this.timeWalk.to = this.editedItem.timeWalk?.to || 0;
      this.yandexWalk.from = this.editedItem.yandexWalk?.from || 0;
      this.yandexWalk.to = this.editedItem.yandexWalk?.to || 0;
      this.timeBetweenReqMinutes = this.editedItem.timeBetweenReqMinutes || 0; // Пример для поля 'timeBetweenReqMinutes'
      this.procentMob = this.editedItem.procentMob || 0; // Пример для поля 'procentMob'
      this.procentMan = this.editedItem.procentMan || 0;
      this.procentNotYandex = this.editedItem.procentNotYandex || 0;
      this.hoursDelete = this.editedItem.hoursDelete || 0; // Пример для поля 'hoursDelete'
      this.usesDelete = this.editedItem.usesDelete || 0; // Пример для поля 'usesDelete'
      this.countDogul = this.editedItem.countDogul || 0; // Пример для поля 'countDogul'
      this.hoursDogul = this.editedItem.hoursDogul || 0; // Пример для поля 'hoursDogul'
      this.usesDogul = this.editedItem.usesDogul || 0; // Пример для поля 'usesDogul'
      this.dayCreate = this.editedItem.dayCreate || 0;
      this.selectedItems = this.editedItem.selectedSites.map(site => site._id);
      this.hoursWait = this.editedItem.hoursWait || 0;
      this.usesInOneWork = this.editedItem.usesInOneWork || 0;

      // Обновление процентов для каждого сайта в 'sites'
      this.sites.forEach(site => {
        const selectedSite = this.editedItem.selectedSites.find(s => s._id === site._id);
        site.procentUrls = selectedSite ? selectedSite.procentUrls : 0;
      });
    },

    async deleteItem(item) {
      this.editedItem = item;
      this.dialogDelete = true;
    },

    async showProxiesHandler(item) {
      this.editedItem = Object.assign({}, item);
      await this.showProxies();
      this.dialog_show = true;
    },

    getCount() {
      return this.collections.find(
        item => item.collection_name == this.editedItem.collection_name
      ).count;
    },

    async change_mobile(item) {
      await this.editProxyCollectionMobile(item);
    },

    async addItemConfirm() {
      this.$refs.addForm.validate();
      if (this.addValid) {
        const selectedSites = this.sites.filter(site => this.selectedItems.includes(site._id));
        await this.addProfileCollection({
          selectedSites: selectedSites,
          procentMob: this.procentMob,
          procentMan: this.procentMan,
          procentNotYandex: this.procentNotYandex,
          timeBetweenReqMinutes: this.timeBetweenReqMinutes,
          numSitesPerOneWalk: this.numSitesPerOneWalk,
          depth: this.depth,
          timeWalk: this.timeWalk,
          yandexWalk: this.yandexWalk,
          numSites: this.numSites,
          name: this.name,
          numProfiles: this.numProfiles,
          numProfilesPerOnce: this.numProfilesPerOnce,
          numSitesFrom: this.numSitesFrom,
          numSitesTo: this.numSitesTo,
          proxie: this.selectedProxyName,
          countDogul: this.countDogul,
          hoursDogul: this.hoursDogul,
          usesDogul: this.usesDogul,
          hoursDelete: this.hoursDelete,
          usesDelete: this.usesDelete,
          dayCreate: this.dayCreate,
          hoursWait: this.hoursWait,
          usesInOneWork: this.usesInOneWork,
        });
        this.selectedProxy = ""
        this.selectedProxyName = this.proxieCollections[0].collection_name
        this.numProfiles = 0;
        this.numProfilesPerOnce = 0;
        this.numSitesFrom = 0;
        this.numSitesTo = 0
        this.name = "";
        this.numSites = 0;
        this.numSitesPerOneWalk.from = 0;
        this.numSitesPerOneWalk.to = 0;
        this.depth.from = 0;
        this.depth.to = 0;
        this.timeWalk.from = 0;
        this.timeWalk.to = 0;
        this.yandexWalk.from = 0;
        this.yandexWalk.to = 0;
        this.timeBetweenReqMinutes = 0;
        this.procentMob = 0;
        this.procentMan = 0;
        this.procentNotYandex = 0;
        this.selectedItems = [];
        this.countDogul = 0;
        this.hoursDogul = 0;
        this.usesDogul = 0;
        this.hoursDelete = 0;
        this.usesDelete = 0;
        this.dayCreate = 0;
        this.hoursWait = 0;
        this.usesInOneWork = 0;

        this.close();

      }
    },

    async editItemConfirm() {
      this.$refs.editForm.validate();
      if (this.editValid) {
        const selectedSites = this.sites.filter(site => this.selectedItems.includes(site._id));
        await this.editProfileCollection({
          id: this.id,
          selectedSites: selectedSites,
          procentMob: this.procentMob,
          procentMan: this.procentMan,
          procentNotYandex: this.procentNotYandex,
          timeBetweenReqMinutes: this.timeBetweenReqMinutes,
          numSitesPerOneWalk: this.numSitesPerOneWalk,
          depth: this.depth,
          timeWalk: this.timeWalk,
          yandexWalk: this.yandexWalk,
          numSites: this.numSites,
          name: this.name,
          numProfiles: this.numProfiles,
          numProfilesPerOnce: this.numProfilesPerOnce,
          numSitesFrom: this.numSitesFrom,
          numSitesTo: this.numSitesTo,
          proxie: this.selectedProxyName,
          countDogul: this.countDogul,
          hoursDogul: this.hoursDogul,
          usesDogul: this.usesDogul,
          hoursDelete: this.hoursDelete,
          usesDelete: this.usesDelete,
          dayCreate: this.dayCreate,
          hoursWait: this.hoursWait,
          usesInOneWork: this.usesInOneWork,
        });
        this.id = "";
        this.selectedProxy = ""
        this.selectedProxyName = this.proxieCollections[0].collection_name
        this.numProfiles = 0;
        this.numProfilesPerOnce = 0;
        this.numSitesFrom = 0;
        this.numSitesTo = 0;
        this.name = "";
        this.numSites = 0;
        this.numSitesPerOneWalk.from = 0;
        this.numSitesPerOneWalk.to = 0;
        this.depth.from = 0;
        this.depth.to = 0;
        this.timeWalk.from = 0;
        this.timeWalk.to = 0;
        this.yandexWalk.from = 0;
        this.yandexWalk.to = 0;
        this.timeBetweenReqMinutes = 0;
        this.procentMob = 0;
        this.procentMan = 0;
        this.procentNotYandex = 0;
        this.selectedItems = [];
        this.countDogul = 0;
        this.hoursDogul = 0;
        this.usesDogul = 0;
        this.hoursDelete = 0;
        this.usesDelete = 0;
        this.dayCreate = 0;
        this.hoursWait = 0;
        this.usesInOneWork = 0;

        this.close();
      }
    },

    async deleteItemConfirm() {
      await this.deleteProfileCollection({id: this.editedItem._id});
      this.editedItem = {
        collection_name: '',
        description: '',
        _id: '',
      };
      this.close();

    },

    // async newProxies() {
    //   this.$refs.newProxies.validate();
    //   if (this.textValid) {
    //     this.loading = true;
    //     let proxies = this.proxies;
    //     this.proxies = '';
    //     await this.postProxies({
    //       proxies,
    //       collection_name: this.editedItem.collection_name,
    //     });
    //
    //     this.loading = false;
    //     this.close();
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.myError {
  white-space: pre-wrap;
  text-align: center;
}

.proxy {
  width: 100%;
  justify-content: center;
  display: flex;

  &-outer {
    width: 90%;
  }
}

.headline {
  div {
    width: 100%;
  }
  word-break: break-word;

  .collection-name {
    color: $gray_450;
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
@import '@/assets/_colors.scss';

.proxy-description {
  color: $gray-200;
}
.site {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; /* Добавляет 10px отступа снизу */
}

.site-name {
  margin: 0 10px; /* Небольшой отступ между чекбоксом и именем */
  width: 300px;
}
.proxy-name {
  margin: 0 10px; /* Небольшой отступ между чекбоксом и именем */
  width: 700px;
  text-align: left;
}
.procent-field {
  width: 100px; /* или другая фиксированная ширина, если нужно */
}
.highlight-scroll {
  border: 2px solid red; /* Пример подсветки границы */
}
</style>
