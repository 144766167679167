import axios from 'axios';
const url = 'api/yandex/';

async function getRequestsList ({ rootState, state }, account) {
  const res = await axios.get(url + `requestsList`);

  state.requests = res.data;
  return res.data;
}
async function addNewRequest( {} , {taskId, parsedFile} ) {
  try {
    const res = await axios.post(url + `request/add`, {
      taskId,
      parsedFile});

    return res.data;
  } catch (err) {
    return 'error';
  }
}
async function deleteOneRequest( {} , id ) {
  try {
    const res = await axios.post(url + `request/delete`, {id});

    return res.status;
  } catch (err) {
    return 'error';
  }
}
async function downloadOneRequest( {} , reId ) {
  try {

    const res = await axios.get(url + `request/download/${reId}`, {name});

    return res.data;
  } catch (err) {
    return 'error';
  }
}

async function editRequestionName( {} , {id, newName} ) {
  try {

    const res = await axios.post(url + `request/update`, {
      id,
      newName});

    return res.status;
  } catch (err) {
    return 'error';
  }
}

async function deleteOnlyOneRequest( {} , {name, id} ) {
  try {

    const res = await axios.post(url + `request/oneDelete`, {name, id});

    return res.status;
  } catch (err) {
    return 'error';
  }
}

async function editOneRequest( {} , {originalReq, request, volume,geo,page,todayWalk, currentId, taskId } ) {
  try {
    const res = await axios.post(url + `request/updateOne`, {
      originalReq,
      request,
      volume,geo,page,todayWalk,
      currentId, taskId  });

    return res.data;
  } catch (err) {
    return 'error';
  }
}

async function addOneRequest({}, { taskId } ) {
  try {
  const res = await axios.post(url + `requestOne/add/${taskId}`,);
  return res.data;
  } catch (err) {
    return 'error';
  }
}

async function addOrDeleteRequest({}, { requestId, val, taskId } ) {
  try {
    const res = await axios.post(url + `addOrDeleteRequest`, {
      requestId,
      val,
      taskId
    });

    return res.status;
  } catch (err) {
      return 'error';
    }
}

export {
  getRequestsList,
  addNewRequest,
  deleteOneRequest,
  downloadOneRequest,
  editRequestionName,
  deleteOnlyOneRequest,
  editOneRequest,
  addOneRequest,
  addOrDeleteRequest
}