import axios from 'axios';
const url = 'api/yandex/';

async function getProfiles({ rootState, state }, ) {

  const res = await axios.get(url + `profiles`);

  state.profiles = res.data;
  return res.data;

}
async function getUrls ({ rootState, state }, ) {

  const res = await axios.get(url + `urls`);


  return res.data;

}

async function getProxieCollections ({ rootState, state }, ) {

  const res = await axios.get(url + `proxies/collections/${state.user.login}`);


  return res.data;

}

async function addProfileCollection ({}, {selectedSites, procentMob, procentMan, procentNotYandex, timeBetweenReqMinutes,depth, timeWalk, yandexWalk, numSitesPerOneWalk,  numSites,  name, numProfiles, numProfilesPerOnce,numSitesFrom,numSitesTo, proxie, countDogul, hoursDogul, usesDogul, hoursDelete, usesDelete, dayCreate, hoursWait, usesInOneWork}) {

  const res = await axios.post(url + `addProfile`, {
    selectedSites,
    procentMob,
    procentMan,
    procentNotYandex,
    timeBetweenReqMinutes,
    depth,
    timeWalk,
    yandexWalk,
    numSitesPerOneWalk,
    numSites,
    name,
    numProfiles,
    numProfilesPerOnce,
    numSitesFrom,
    numSitesTo,
    proxie,
    countDogul,
    hoursDogul,
    usesDogul,
    hoursDelete,
    usesDelete,
    dayCreate,
    hoursWait,
    usesInOneWork
  });


  return res.data;

}

async function editProfileCollection ({}, {id, selectedSites, procentMob, procentMan, procentNotYandex, timeBetweenReqMinutes, depth, timeWalk, yandexWalk,  numSitesPerOneWalk,  numSites,  name, numProfiles, numProfilesPerOnce,numSitesFrom,numSitesTo, proxie, countDogul, hoursDogul, usesDogul, hoursDelete, usesDelete, dayCreate, hoursWait, usesInOneWork}) {

  const res = await axios.post(url + `editProfile`, {
    id,
    selectedSites,
    procentMob,
    procentMan,
    procentNotYandex,
    timeBetweenReqMinutes,
    depth,
    timeWalk,
    yandexWalk,
    numSitesPerOneWalk,
    numSites,
    name,
    numProfiles,
    numProfilesPerOnce,
    numSitesFrom,
    numSitesTo,
    proxie,
    countDogul,
    hoursDogul,
    usesDogul,
    hoursDelete,
    usesDelete,
    dayCreate,
    hoursWait,
    usesInOneWork
  });


  return res.data;

}
async function deleteProfileCollection ({}, {id}) {
  console.log(id)

  const res = await axios.post(url + `deleteProfile`, {
    id,
  });


  return res.data;

}

async function addOrDeleteProfile({}, { profileId, val, taskId } ) {
  try {
    const res = await axios.post(url + `addOrDeleteProfile`, {
      profileId,
      val,
      taskId
    });

    return res.status;
  } catch (err) {
    return 'error';
  }
}



export {
  getProfiles,
  getUrls,
  addProfileCollection,
  deleteProfileCollection,
  addOrDeleteProfile,
  getProxieCollections,
  editProfileCollection,
}
