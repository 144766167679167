<template>
  <div class="stats-outter" @click="contextmenu = false">
    <transition name="slide-fade">
      <v-card
        max-width="400"
        tile
        v-if="contextmenu"
        :style="{
          top: contextmenu_top + 'px',
          left: contextmenu_left + 'px',
        }"
        class="contextmenu"
      >
        <v-card-title>Сервер {{ contextmenu_item.ip }}</v-card-title>

        <v-card-text>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in contextmenu_list"
              :key="i"
              @click="contextmenuCopy(i)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card-text>
      </v-card>
    </transition>

    <div class="stats-all" v-if="today_yesterday">
      <div class="item">
        <span class="count">
          {{ stats[0].entries_all }}
          <div class="count-yesterday">
            {{ stats[1].entries_all }}
          </div>
        </span>

        <span class="name">Загрузки</span>
      </div>
      <div class="item">
        <span class="count">
          {{ stats[0].shows_all }}
          <div class="count-yesterday">
            {{ stats[1].shows_all }}
          </div>
        </span>

        <span class="name">Зачеты</span>
      </div>
      <div class="item">
        <span class="count">
          {{ stats[0].recycling_all }}
          <div class="count-yesterday">
            {{ stats[1].recycling_all }}
          </div>
        </span>

        <span class="name">Утилизация</span>
      </div>

      <div class="item" v-if="!user.stats">
        <v-btn @click="isServers = !isServers">
          <span v-if="!isServers">Статистика по серверам</span>
          <span v-else>Статистика по ссылкам</span>
        </v-btn>
      </div>
    </div>

    <div class="stats-all" v-else-if="stats.length == 1">
      <div class="item">
        <span class="count">{{ stats[0].entries_all }}</span>
        <span class="name">Загрузки</span>
      </div>
      <div class="item">
        <span class="count">{{ stats[0].shows_all }}</span>
        <span class="name">Зачеты</span>
      </div>
      <div class="item">
        <span class="count">{{ stats[0].recycling_all }}</span>
        <span class="name">Утилизация</span>
      </div>

      <div class="item" v-if="!user.stats">
        <v-btn @click="isServers = !isServers">
          <span v-if="!isServers">Статистика по серверам</span>
          <span v-else>Статистика по ссылкам</span>
        </v-btn>
      </div>
    </div>

    <div class="stats-all" v-else>
      <div class="item">
        <span class="count">
          <div
            v-for="(stat, index) of stats"
            :key="stat.entries_all"
            :class="'count-' + index"
          >
            {{ stat.entries_all }}
          </div>
        </span>
        <span class="name">Загрузки</span>
      </div>
      <div class="item">
        <span class="count">
          <div
            v-for="(stat, index) of stats"
            :key="stat.shows_all"
            :class="'count-' + index"
          >
            {{ stat.shows_all }}
          </div>
        </span>
        <span class="name">Зачеты</span>
      </div>
      <div class="item">
        <span class="count">
          <div
            v-for="(stat, index) of stats"
            :key="stat.recycling_all"
            :class="'count-' + index"
          >
            {{ stat.recycling_all }}
          </div>
        </span>
        <span class="name">Утилизация</span>
      </div>

      <div class="item" v-if="!user.stats">
        <v-btn @click="isServers = !isServers">
          <span v-if="!isServers">Статистика по серверам</span>
          <span v-else>Статистика по ссылкам</span>
        </v-btn>
      </div>
    </div>

    <div class="days">
      <div class="item">
        <v-btn
          @click="setDate(1)"
          small
          :color="today || today_yesterday ? 'primary' : ''"
        >
          Сегодня
        </v-btn>
      </div>
      <div class="item">
        <v-btn
          @click.ctrl.stop.prevent="setComparasionWithYesterday()"
          @click.exact="setDate(0)"
          small
          :color="yesterday ? 'primary' : today_yesterday ? 'purple' : ''"
        >
          Вчера
        </v-btn>
      </div>
      <div class="item">
        <v-menu
          v-model="date_picker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon title="Выбрать дату">
              <v-icon :size="22" color="primary"> mdi-calendar </v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="dates"
            :events="date_colors"
            multiple
            color="primary"
            header-color="gray_800"
          ></v-date-picker>
        </v-menu>
      </div>

      <!-- <div class="item" v-if="!user.stats">
        <v-btn icon @click.stop.prevent="dialogDelete = true" title="Delete">
          <v-icon :size="22" class="icon"> mdi-delete </v-icon>
        </v-btn>
      </div>

      <v-dialog v-model="dialogDelete" persistent max-width="600px">
        <v-card>
          <v-card-title class="headline">
            Вы уверены, что хотите удалить статистку?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogDelete = false">Cancel</v-btn>
            <v-btn color="error" text @click="deleteConfirm" :loading="loading">
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </div>

    <div class="inner">
      <div style="width: 45%">
        <Chart :_series.sync="graphs[0]" :title="'Загрузки'" />
      </div>
      <div style="width: 45%">
        <Chart :_series.sync="graphs[1]" :title="'Зачеты'" />
      </div>
    </div>
    <div style="width: 80%; margin-bottom: 40px">
      <Chart :_series.sync="graphs[2]" :title="'Утилизация'" />
    </div>

    <div>
      <v-btn-toggle
        v-model="table_time"
        dense
        color="primary"
        borderless
        group
        mandatory
        tile
      >
        <v-btn
          :disabled="loading_stats && table_time != 0"
          :loading="loading_stats && table_time == 0"
        >
          За день
        </v-btn>
        <v-btn
          :disabled="loading_stats && table_time != 1"
          :loading="loading_stats && table_time == 1"
        >
          За час
        </v-btn>
        <v-btn
          :disabled="loading_stats && table_time != 2"
          :loading="loading_stats && table_time == 2"
        >
          За 10 минут
        </v-btn>
      </v-btn-toggle>
      <v-btn
        icon
        @click="changeShowMore"
        :loading="loading_stats"
        :title="getTitleCompare"
      >
        <v-icon :size="22" class="icon" v-if="!show_more">
          mdi-circle-off-outline
        </v-icon>
        <v-icon
          :size="22"
          class="icon"
          v-else-if="show_more == 1 && table_time"
        >
          mdi-circle-slice-1
        </v-icon>
        <v-icon :size="22" class="icon" v-else>
          mdi-circle-multiple-outline
        </v-icon>
      </v-btn>

      <v-btn
        icon
        @click="changeShowMoreType"
        :loading="loading_stats"
        :title="
          !show_more_type
            ? 'Отображение значений в сравнении'
            : 'Отображение процентов в сравнении'
        "
      >
        <v-icon :size="22" class="icon" v-if="show_more_type">
          mdi-percent-outline
        </v-icon>
        <v-icon :size="22" class="icon" v-else> mdi-numeric </v-icon>
      </v-btn>
    </div>

    <v-data-table
      :headers="isServers ? headersServers : headers"
      :items="tableData"
      :items-per-page="tableData.length"
      :sort-by="'shows'"
      :sort-desc="true"
      multi-sort
      class="table"
      :mobile-breakpoint="0"
      @contextmenu:row="openContextmenu"
    >
      <template v-slot:[`item.url`]="{ item }">
        <div
          style="display: flex; align-items: center"
          v-if="item.url && item.url.split(',').length == 1"
          @click.ctrl="filter(item, 'url')"
        >
          <span>
            {{ item.url }}
          </span>

          <v-spacer></v-spacer>
        </div>
        <v-tooltip v-else-if="item.url" bottom color="primary" min-width="100">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <span style="width: 120px">
                {{ item.url.split(',').length }}
                {{ sites(item.url.split(',').length) }}
              </span>
            </div>
          </template>
          <span style="opacity: 1 !important">
            <div v-for="url of item.url.split(',')" :key="url + Math.random()">
              {{ url }}
            </div>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.entries`]="{ item }">
        <div style="display: flex; align-items: flex-end">
          <div>
            {{ item.entries }}
          </div>

          <transition name="fade">
            <div
              style="display: flex; align-items: flex-end"
              class="ml-1"
              v-if="show_more && !loading_stats"
            >
              <v-icon
                v-if="
                  !show_more_type
                    ? item.entries > item.dif_entries
                    : item.dif_entries >= 0
                "
                small
                color="success"
              >
                mdi-chevron-up
              </v-icon>
              <v-icon v-else small color="error">mdi-chevron-down</v-icon>

              <div
                :style="{
                  fontSize: 12 + 'px',
                  color: !show_more_type
                    ? item.entries > item.dif_entries
                      ? '#4CAF50'
                      : '#FF4343'
                    : item.dif_entries >= 0
                      ? '#4CAF50'
                      : '#FF4343',
                }"
              >
                {{ item.dif_entries }}
              </div>
            </div>
          </transition>
        </div>
      </template>

      <template v-slot:[`item.shows`]="{ item }">
        <div style="display: flex; align-items: flex-end">
          <div>
            {{ item.shows }}
          </div>

          <transition name="fade">
            <div
              style="display: flex; align-items: flex-end"
              class="ml-1"
              v-if="show_more && !loading_stats"
            >
              <v-icon
                v-if="
                  !show_more_type
                    ? item.shows > item.dif_shows
                    : item.dif_shows >= 0
                "
                small
                color="success"
              >
                mdi-chevron-up
              </v-icon>
              <v-icon v-else small color="error">mdi-chevron-down</v-icon>

              <div
                :style="{
                  fontSize: 12 + 'px',
                  color: !show_more_type
                    ? item.shows > item.dif_shows
                      ? '#4CAF50'
                      : '#FF4343'
                    : item.dif_shows >= 0
                      ? '#4CAF50'
                      : '#FF4343',
                }"
              >
                {{ item.dif_shows }}
              </div>
            </div>
          </transition>
        </div>
      </template>

      <template v-slot:[`item.recycling`]="{ item }">
        <div style="display: flex; align-items: flex-end">
          <div>
            {{ item.recycling ? item.recycling.toFixed(2) : 0 }}
          </div>

          <transition name="fade">
            <div
              style="display: flex; align-items: flex-end"
              class="ml-1"
              v-if="show_more && !loading_stats"
            >
              <v-icon
                v-if="
                  !show_more_type
                    ? item.recycling >= item.dif_recycling
                    : item.dif_recycling >= 0
                "
                small
                color="success"
              >
                mdi-chevron-up
              </v-icon>
              <v-icon v-else small color="error">mdi-chevron-down</v-icon>

              <div
                :style="{
                  fontSize: 12 + 'px',
                  color: !show_more_type
                    ? item.recycling >= item.dif_recycling
                      ? '#4CAF50'
                      : '#FF4343'
                    : item.dif_recycling >= 0
                      ? '#4CAF50'
                      : '#FF4343',
                }"
              >
                {{ item.dif_recycling ? item.dif_recycling.toFixed(2) : 0 }}
              </div>
            </div>
          </transition>
        </div>
      </template>
    </v-data-table>

    <v-snackbar v-model="copy_snackbar" timeout="1500" rounded>
      <div style="text-align: center">
        <v-icon color="success" class="mr-2"> mdi-check-circle-outline </v-icon>
        <span>Скопировано</span>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Chart from './chart';

export default {
  name: 'Stats',

  props: ['url', 'setSSH', 'startItem'],

  data() {
    return {
      dialogDelete: false,
      loading: false,
      loading_stats: false,
      can_get: false,

      contextmenu: false,
      contextmenu_top: 0,
      contextmenu_left: 0,
      contextmenu_item: null,
      contextmenu_list: [
        'Скопировать название',
        'Скопировать IP',
        'Скопировать url',
      ],
      contextmenu_offset: 240,
      copy_snackbar: false,

      table_time: 0,

      headers: [
        {
          text: 'url',
          value: 'url',
        },
        {
          text: 'player',
          value: 'player',
        },
        { text: 'Загрузки', value: 'entries' },
        { text: 'Зачеты', value: 'shows' },
        { text: 'Утилизация', value: 'recycling' },
      ],

      headersServers: [
        {
          text: 'ip',
          value: 'ip',
        },
        { text: 'name', value: 'name' },
        {
          text: 'url',
          value: 'url',
        },
        {
          text: 'player',
          value: 'player',
        },
        { text: 'Загрузки', value: 'entries' },
        { text: 'Зачеты', value: 'shows' },
        { text: 'Утилизация', value: 'recycling' },
      ],

      show_more_title: [
        'Без сравнения',

        {
          0: 'Сравнение с предыдущим часом',
          1: 'Сравнение с предыдущими 10-ю минутами',
        },

        'Сравнение с предыдущим днем в то же время',
      ],

      show_more: 0,
      show_more_type: 0,

      isServers: false,

      date_picker: false,
      dates: [new Date(Date.now() + 10800000).toISOString().substr(0, 10)], // 10800000 for Moscow timezone
    };
  },

  components: {
    Chart,
  },

  computed: {
    ...mapState(['user', 'stats', 'graphs', 'stats_date']),

    getTitleCompare() {
      if (this.table_time == 0) {
        return this.show_more ? 'Сравнение с предыдущим днем' : 'Без сравнения';
      } else {
        return this.show_more != 1
          ? this.show_more_title[this.show_more]
          : this.show_more_title[this.show_more][this.table_time - 1];
      }
    },

    today() {
      return (
        this.dates.length == 1 &&
        new Date(Date.now() + 10800000).toISOString().substr(0, 10) ==
          this.dates[0]
      );
    },

    yesterday() {
      return (
        this.dates.length == 1 &&
        new Date(Date.now() + 10800000 - 86400000)
          .toISOString()
          .substr(0, 10) == this.dates[0]
      );
    },

    today_yesterday() {
      return (
        this.dates.length == 2 &&
        new Date(Date.now() + 10800000).toISOString().substr(0, 10) ==
          this.dates[0] &&
        new Date(Date.now() + 10800000 - 86400000)
          .toISOString()
          .substr(0, 10) == this.dates[1]
      );
    },

    _stats() {
      return this.isServers
        ? this.stats.map(stats => stats.stats.servers_stats)
        : this.stats.map(stats => stats.stats.urls_stats);
    },

    tableData() {
      return this.stats[0].tableData;
    },
  },

  async beforeMount() {
    if (this.user.admin) {
      this.headersServers = [
        {
          text: 'ip',
          value: 'ip',
        },
        { text: 'name', value: 'name' },
        { text: 'player', value: 'player' },
        {
          text: 'mobiles',
          value: 'mobiles',
        },
        {
          text: 'url',
          value: 'url',
        },
        {
          text: 'proxy',
          value: 'proxies',
        },
        { text: 'Загрузки', value: 'entries' },
        { text: 'Зачеты', value: 'shows' },
        { text: 'Утилизация', value: 'recycling' },
      ];
    }
    if (!this.user.user) {
      this.contextmenu_list = [
        'Подключиться по SSH',
        'Перезапустить',
        'Скопировать название',
        'Скопировать IP',
        'Скопировать url',
        'Скопировать пароль',
        'Скопировать IP и пароль',
      ];

      this.contextmenu_offset = 320;
    }

    if (JSON.parse(localStorage.getItem('today_yesterday')))
      this.dates = [
        new Date(Date.now() + 10800000).toISOString().substr(0, 10),
        new Date(Date.now() + 10800000 - 86400000).toISOString().substr(0, 10),
      ];

    this.table_time = JSON.parse(localStorage.getItem('table_time'))
      ? JSON.parse(localStorage.getItem('table_time'))
      : 0;

    this.isServers = JSON.parse(localStorage.getItem('isServers')) == '1';

    this.show_more = JSON.parse(localStorage.getItem('show_more'));
    this.show_more = parseInt(this.show_more) ? this.show_more : 0;

    this.show_more_type = JSON.parse(localStorage.getItem('show_more_type'));
    this.show_more_type = parseInt(this.show_more_type)
      ? this.show_more_type
      : 0;

    if (this.user.stats) {
      this.contextmenu_list = [];

      this.contextmenu_offset = 320;
      this.isServers = true;

      this.headersServers = [
        {
          text: 'proxy',
          value: 'proxies',
        },
        { text: 'Загрузки', value: 'entries' },
        { text: 'Зачеты', value: 'shows' },
        { text: 'Утилизация', value: 'recycling' },
      ];

      if (this.user._id == '631a1109e80a2be74d0d0411') {
        this.headersServers.splice(0, 1, {
          text: 'ip',
          value: 'ip',
        });
      }
    }

    this.setStatsDate(this.dates);
    this.setTableTime(this.table_time);
    this.setShowMore(this.show_more);
    this.setShowMoreType(this.show_more_type);
    this.setIsServersStats(this.isServers);

    this.loading_stats = true;
    await this.getStats();
    this.loading_stats = false;
    this.can_get = true;
  },

  async mounted() {
    window.addEventListener('keyup', this.getKeyboard);
  },

  watch: {
    async dates(newVal) {
      if (this.can_get) {
        if (newVal.length > 6) this.dates.splice(5, 1);
        if (newVal.length == 0)
          this.dates.push(
            new Date(Date.now() + 10800000).toISOString().substr(0, 10)
          );

        if (
          this.dates.length == 2 &&
          new Date(Date.now() + 10800000).toISOString().substr(0, 10) ==
            this.dates[0] &&
          new Date(Date.now() + 10800000 - 86400000)
            .toISOString()
            .substr(0, 10) == this.dates[1]
        )
          localStorage.setItem('today_yesterday', 'true');
        else localStorage.setItem('today_yesterday', 'false');

        this.setStatsDate(this.dates);

        await this.getStats();
      }
    },

    async table_time(newVal) {
      if (this.can_get) {
        this.loading_stats = true;
        this.setTableTime(newVal);
        await this.getStats();
        this.loading_stats = false;
      }

      localStorage.setItem('table_time', newVal);
    },

    async isServers(newVal) {
      this.setIsServersStats(newVal);

      this.loading_stats = true;
      await this.getStats();
      this.loading_stats = false;
      localStorage.setItem('isServers', newVal);
    },
  },

  methods: {
    ...mapMutations([
      'setStatsDate',
      'setTableTime',
      'setShowMore',
      'setShowMoreType',
      'setIsServersStats',
    ]),
    ...mapActions(['getStats', 'deleteStats']),

    openContextmenu(event, { item }) {
      if (this.isServers && !this.user.stats) {
        event.preventDefault();
        this.contextmenu_top =
          event.pageY + this.contextmenu_offset <
          window.pageYOffset + document.documentElement.clientHeight
            ? event.pageY - 85
            : event.pageY - this.contextmenu_offset - 85;

        this.contextmenu_left = event.pageX;
        this.contextmenu_item = item;
        this.contextmenu = true;
      }
    },
    async contextmenuCopy(i) {
      switch (i) {
        case 0: {
          this.setSSH(this.contextmenu_item);
          break;
        }
        case 1: {
          this.startItem(this.contextmenu_item);
          break;
        }
        case 2: {
          await navigator.clipboard.writeText(this.contextmenu_item.name);
          break;
        }
        case 3: {
          await navigator.clipboard.writeText(this.contextmenu_item.ip);
          break;
        }
        case 4: {
          await navigator.clipboard.writeText(
            this.contextmenu_item.url.split(',').join('\n')
          );
          break;
        }
        case 5: {
          await navigator.clipboard.writeText(this.contextmenu_item.password);
          break;
        }
        case 6: {
          await navigator.clipboard.writeText(
            this.contextmenu_item.ip + '\n' + this.contextmenu_item.password
          );
          break;
        }
      }

      if (i > 1) this.copy_snackbar = true;
    },

    getKeyboard: function (event) {
      if (event.keyCode == '81') {
        this.changeShowMore();
      }
      if (event.keyCode == '87') {
        this.changeShowMoreType();
      }
    },

    async changeShowMore() {
      if (this.can_get) {
        this.loading_stats = true;

        this.show_more = this.table_time
          ? (this.show_more + 1) % 3
          : (this.show_more + 1) % 2;

        localStorage.setItem('show_more', this.show_more);
        this.setShowMore(this.show_more);

        await this.getStats();
        this.loading_stats = false;
      }
    },

    async changeShowMoreType() {
      if (this.can_get) {
        this.loading_stats = true;

        this.show_more_type = (this.show_more_type + 1) % 2;

        localStorage.setItem('show_more_type', this.show_more_type);
        this.setShowMoreType(this.show_more_type);

        await this.getStats();
        this.loading_stats = false;
      }
    },

    checkUrl(url) {
      return url == undefined || url.split(',').length == 1;
    },

    async deleteConfirm() {
      this.loading = true;
      await this.deleteStats();
      this.loading = false;
      this.dialogDelete = true;
    },

    date_colors(date) {
      if (this.dates.indexOf(date) == 0) return '#E6457A';
      else if (this.dates.indexOf(date) == 1) return '#937ff5';
      else if (this.dates.indexOf(date) == 2) return '#2EBFE6';
      else if (this.dates.indexOf(date) == 3) return '#FFC64D';
      else if (this.dates.indexOf(date) == 4) return '#96E946';
      else if (this.dates.indexOf(date) == 5) return '#FA71A8';
    },

    setComparasionWithYesterday() {
      if (this.today_yesterday)
        this.dates = [
          new Date(Date.now() + 10800000).toISOString().substr(0, 10),
        ];
      else
        this.dates = [
          new Date(Date.now() + 10800000).toISOString().substr(0, 10),
          new Date(Date.now() + 10800000 - 86400000)
            .toISOString()
            .substr(0, 10),
        ];
    },

    setDate(today) {
      if (today)
        this.dates = [
          new Date(Date.now() + 10800000).toISOString().substr(0, 10),
        ];
      else
        this.dates = [
          new Date(Date.now() + 10800000 - 86400000)
            .toISOString()
            .substr(0, 10),
        ];
    },

    sites(n) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return 'сайтов';
      }
      if (n1 > 1 && n1 < 5) {
        return 'сайта';
      }
      if (n1 == 1) {
        return 'сайт';
      }
      return 'сайтов';
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.contextmenu {
  position: absolute;
  z-index: 9999999;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.stats-outter {
  width: 100%;
  margin: 10px 0 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  .table {
    min-width: 35%;
  }

  .stats-all {
    display: flex;
    justify-content: center;

    // width: 60%;
    margin-bottom: 20px;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: right;

      .count {
        color: $gray_100;
        font-size: 20px;
      }

      .count-yesterday {
        color: $gray_200;
        font-size: 18px;
      }

      .count-0 {
        color: $gray_100;
        font-size: 20px;
      }
      .count-1 {
        color: $gray_200;
        font-size: 18px;
      }
      .count-2 {
        color: $gray_300;
        font-size: 16px;
      }
      .count-3 {
        color: $gray_400;
        font-size: 16px;
      }
      .count-4 {
        color: $gray_500;
        font-size: 16px;
      }

      .name {
        margin-top: 5px;
        color: $gray_400;
        font-size: 14px;
      }
    }
    .item:not(:last-child) {
      margin-right: 40px;
    }
  }

  .days {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .item:not(:last-child) {
      margin-right: 20px;
    }
  }

  .inner {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 20px;

    .chart {
      width: 45%;
    }
  }
}
</style>

<style>
.apexcharts-theme-mine {
  background: #555;
}
</style>
