import axios from 'axios';
const url = 'api/proxiesStats/';

export default {
  namespaced: true,

  state: {
    isAll: false,
    groupedData: {},
    abortController: null,
  },

  mutations: {
    updateAbortController(state) {
      try {
        state.abortController.abort();
      } catch (err) {}

      state.abortController = new AbortController();
    },
  },

  actions: {
    async getData({ rootState, commit, state }) {
      state.abortController = new AbortController();

      state.groupedData = (
        await axios.get(`${url}${rootState._id}.${state.isAll}`, {
          signal: state.abortController.signal,
        })
      ).data;
    },

    changeIsAll({ dispatch, state }) {
      state.isAll = !state.isAll;
      dispatch('getData');
    },
  },
};
