<template>
  <div class="main">
    <div style="font-weight:bold;font-size:20px">{{ account }}</div>
    <div>
      <span>Количество заходов в день: </span>
      <span>{{ allPerDay }}</span>
    </div>
    <div>
      <span>Всего заходов в этом месяце: </span>
      <span>{{ monthStats }}</span>
    </div>
    <div>
      <span>Всего заходов в прошлом месяце: </span>
      <span>{{ lastMonthStats }}</span>
    </div>
    <div>
      <span>Среднее время на сайте: </span>
      <span>{{ timeAvg }}</span>
    </div>
    <div>
      <span>Средняя глубина: </span>
      <span>{{ depthAvg }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Account',

  props: ['account'],

  data() {
    return {
      allPerDay: 0,
      monthStats: 0,
      lastMonthStats: 0,
      timeAvg: 0,
      depthAvg: 0,
    };
  },

  async beforeMount() {
    [
      this.allPerDay,
      this.monthStats,
      this.lastMonthStats,
      this.timeAvg,
      this.depthAvg,
    ] = await this.getMironovTasks(this.account);
  },

  async mounted() {
    setInterval(async () => {
      [
        this.allPerDay,
        this.monthStats,
        this.lastMonthStats,
        this.timeAvg,
        this.depthAvg,
      ] = await this.getMironovTasks(this.account);
    }, 10 * 1000);
  },

  methods: {
    ...mapActions(['getMironovTasks']),
  },
};
</script>

<style scoped lang="scss">
.main {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
