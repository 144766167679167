<template>
  <div>
    <v-snackbar v-model="success" :timeout="5000" color="success">
      Сайты добавлены успешно.
    </v-snackbar>
    <v-snackbar v-model="error" :timeout="5000" color="error">
      Произошла ошибка при добавлении
    </v-snackbar>

    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">New Sites</v-card-title>
        <v-form v-model="formValid" ref="form">
          <v-container class="text-center">
            <v-textarea
              v-model="sites"
              :rules="sitesRules"
              autofocus
              auto-grow
              counter
              label="Сайты"
              required
            >
              <template v-slot:counter>
                {{ sitesCount }}
              </template>
            </v-textarea>
          </v-container>
        </v-form>
        <v-card-actions>
          <div
            @click="replaceSites = !replaceSites"
            class="d-flex"
            style="cursor: pointer"
          >
            <v-simple-checkbox v-model="replaceSites" color="primary" />
            <span>Заменить сайты</span>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('update:dialog', false)"
            :disabled="loading"
            text
          >
            Cancel
          </v-btn>
          <v-btn @click="save" :loading="loading" color="primary" text>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<!-- MARK: script -->
<script setup>
import { watch, ref, computed, defineEmits } from 'vue';
import axios from 'axios';

const props = defineProps({ dialog: Boolean, playerData: Object });
const emit = defineEmits(['update:dialog', 'update:playerData']);

const form = ref(null);
const sites = ref('');
const replaceSites = ref(false);

const formValid = ref(false);

const sitesRules = [
  v => !!v || 'Необходимо ввести данные',
  v => {
    if (v == undefined) return;

    const sites = v.split(v.includes(',') ? ',' : '\n');
    let flag = true;
    for (const str of sites) {
      if (
        !/^[\p{L}0-9-]+(\.[\p{L}0-9-]+)*\.[\p{L}]{2,}$/u.test(str) ||
        str == ''
      ) {
        flag = false;
        break;
      }
    }
    return flag ? !!v : 'Введены некорректные данные';
  },
];

const sitesCount = computed(() => {
  return (
    sites.value.split(sites.value.includes(',') ? ',' : '\n').length -
    (formValid.value ? 0 : 1)
  );
});

async function save() {
  form.value.validate();
  if (!formValid.value) return;

  loading.value = true;

  try {
    const newSites = replaceSites.value
      ? Array.from(new Set(sites.value.split('\n').map(site => site.trim())))
      : Array.from(
          new Set(
            sites.value
              .split('\n')
              .concat(props.playerData.sites.split(','))
              .map(site => site.trim())
          )
        );

    await axios.put('api/spoof/players/sites/DZ_LORD', {
      sites: newSites.join(','),
    });

    success.value = true;
    loading.value = false;

    emit('update:dialog', false);
    emit('update:playerData', {
      ...props.playerData,
      sites: newSites.join(','),
    });
  } catch (err) {
    console.log('🚀 ~ err:', err);
    error.value = true;
  } finally {
    loading.value = false;
  }
}

const loading = ref(false);
const success = ref(false);
const error = ref(false);

watch(
  () => props.dialog,
  val => {
    if (val) {
      formValid.value = false;
      sites.value = '';
      replaceSites.value = false;
      loading.value = false;
      success.value = false;
      error.value = false;
    }
  }
);

watch(sites, text => {
  // Регулярное выражение для поиска доменов с поддержкой кириллицы
  const domainRegex =
    /(?:https?:\/\/)?(?:www\.)?([\p{L}0-9.-]+)\.([\p{L}]{2,})/gu;

  // Извлечение доменов из текста
  const domains = [];
  let match;
  while ((match = domainRegex.exec(text)) !== null) {
    domains.push(match[0]); // изменим на match[0], чтобы захватывать полный домен с точками
  }

  sites.value = domains.join('\n');
});
</script>

<!-- MARK: style -->
<style scoped lang="scss"></style>
