<template>
  <div>
    <v-btn v-if="updating" icon class="mr5px" title="Updating" :loading="true">
      <v-icon :size="18"> mdi-circle-slice-8 </v-icon>
    </v-btn>
    <v-btn
      v-else-if="Server.device_type == 'desktop'"
      icon
      class="mr5px"
      title="Desktop"
      @contextmenu.prevent.stop="editServer_DeviceType_Handle"
      :disabled="Server.device_type == 'updating'"
      @click.ctrl="filter(Server, 'device_type')">
      <v-icon :size="18"> mdi-desktop-mac </v-icon>
    </v-btn>

    <v-btn
      v-else-if="Server.device_type == 'mobile'"
      icon
      class="mr5px"
      title="Mobile"
      @contextmenu.prevent.stop="editServer_DeviceType_Handle"
      :disabled="Server.device_type == 'updating'"
      @click.ctrl="filter(Server, 'device_type')">
      <v-icon :size="18"> mdi-cellphone </v-icon>
    </v-btn>

    <v-snackbar v-model="snackbar" :timeout="-1">
      Произошла ошибка при измении типа девайса

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'DeviceType',
  props: ['Server', 'filter'],

  data() {
    return {
      snackbar: false,
      updating: false,
    };
  },

  methods: {
    ...mapActions(['editServer_DeviceType']),

    async editServer_DeviceType_Handle() {
      let new_device_type =
        this.Server.device_type == 'mobile' ? 'desktop' : 'mobile';
      this.updating = true;

      let res = await this.editServer_DeviceType({
        server: this.Server,
        new_device_type,
      });

      if (res.status === 201) this.Server.device_type = new_device_type;
      else this.snackbar = true;

      this.updating = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.mr5px {
  margin-right: 5px;
}
</style>
