export default function () {
  return {
    urlRules: [
      v => !!v || 'Необходимо ввести домен',
      v =>
        /^[-a-zA-Z0-9а-яА-Я@:%._\+~#=]{1,256}\.[a-zA-Z0-9а-яА-Я()]{1,6}\b(?:[-a-zA-Z0-9а-яА-Я()@:%_\+.~#?&!\/=\[\]]*)$/i.test(
          v
        ) || 'Неверно введен домен',
    ],

    numberRules: [
      v => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return 'Необходимо ввести число';
      },
    ],

    hourRules: [
      v => {
        if (Number.isInteger(v) && v >= 0 && v < 24) return true;
        return 'Необходимо ввести число';
      },
    ],

    minuteRules: [
      v => {
        if (Number.isInteger(v) && v >= 0 && v < 60) return true;
        return 'Необходимо ввести число';
      },
    ],

    dateRules: [
      v => !!v || 'Необходимо выбрать дату',

      v => {
        return (
          new Date(this.dateEnd) > new Date(this.dateStart) ||
          'Дата окончания должна быть больше'
        );
      },
    ],
  };
}
