import axios from 'axios';
const url = 'api/spoof/';

export default {
  namespaced: true,

  state: {
    sites: [],
    abortController: null,
  },

  mutations: {
    updateAbortController(state) {
      try {
        state.abortController.abort();
      } catch (err) {}

      state.abortController = new AbortController();
    },
  },

  actions: {
    async getSites({ commit, state }) {
      state.abortController = new AbortController();

      state.sites = (
        await axios.get(`${url}sites`, { signal: state.abortController.signal })
      ).data;
    },

    async addSites({ commit, state }, sites) {
      commit('updateAbortController');
      const res = (await axios.post(`${url}sites`, { sites })).data;
      state.sites.push(...res);

      return res.length;
    },

    async editSites({ commit, state }, { ids, flag }) {
      commit('updateAbortController');
      const res = (
        await axios.put(url + 'sites', {
          ids,
          data: { hosts: flag },
        })
      ).data;
    },

    async deleteSites({ commit, state }, ids) {
      commit('updateAbortController');
      await axios.delete(`${url}sites`, { data: ids });

      state.sites = state.sites.filter(
        site => ids.findIndex(_id => _id === site._id) == -1
      );
    },
  },
};
