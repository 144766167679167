<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="800px">
    <!-- Карточка для формы создания нового интервала -->
    <v-card style="min-height: 200px">
      <v-card-title>
        <span class="headline">
          Интервалы для
          <b>{{ task.name }}</b>
        </span>
      </v-card-title>
      <div class="full-width-text">
        <pre>           От час:минута                       До час:минута            Проценты</pre>
      </div>
      <div class="px-5 pb-5">
        <div v-for="(interval, index) in intervals" :key="index" class="mt-2">
          <TaskInterval
            :interval="interval"
            :handleDelete="handleDelete"
            :updateIntervals="updateIntervals"
          />
        </div>
      </div>

      <div class="d-flex justify-center py-5 addBtn">
        <v-btn
          @click="handleAdd"
          :loading="loading"
          color="primary"
          class="mr-10"
          fab
          small
        >
          <v-icon title="Добавить">mdi-plus</v-icon>
        </v-btn>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

import TaskInterval from './TaskInterval.vue';

export default {
  name: 'TaskIntervals',

  data() {
    return {
      dialog: false,
      loading: false,

      task: null,

      intervals: [],

      // newIntervals: [],
      // newRequestsRules: [
      //   v => !!v || 'Необходимо ввести запросы',
      //   v => {
      //     if (v == undefined) return;
      //
      //     const check = v.split('\n');
      //     let flag = true;
      //     for (const str of check) {
      //       if (!/^(.+?)#(\d+)$/.test(str)) {
      //         flag = false;
      //         break;
      //       }
      //     }
      //     return flag ? !!v : 'Введены некорректные данные';
      //   },
      // ],
      addValid: false,
    };
  },

  components: { TaskInterval },

  computed: {},

  watch: {
    task(newVal) {
      this.dialog = !!newVal;
    },
    async dialog(newVal) {
      if (!newVal) {
        this.task = null;
        this.intervals = [];
      } else {
        this.updateIntervals();
      }
    },
  },

  async beforeMount() {},

  async mounted() {},

  methods: {
    ...mapActions('yandex', ['getIntervals', 'addInterval', 'deleteInterval']),

    async updateIntervals() {
      this.intervals = await this.getIntervals(this.task._id);
    },

    async handleDelete(intervalId) {
      this.intervals.splice(
        this.intervals.findIndex(interval => interval._id == intervalId),
        1
      );
      await this.deleteInterval(intervalId);
    },

    async handleAdd() {
      if (this.intervals.at(-1)?.percentage != 0) {
        const newInterval = await this.addInterval({ taskId: this.task._id })
        this.intervals.push(newInterval);
      }

    },

  },
};
</script>

<style scoped lang="scss">
.addBtn {
  position: sticky;
  bottom: -1px;
  right: 0;
  left: 0;
  margin: auto;

  background-color: #1e1e1e;
}
.full-width-text {
  width: 100%;
  margin-bottom: 10px;
}
</style>
