<template>
  <div>
    <v-spacer />
    <v-select
      v-model="proxies"
      :items="collections.map((item) => item.collection_name)"
      label="Коллекция прокси"
      required
    >
    </v-select>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Proxy",
  props: ["Server", "selectedItems"],

  data() {
    return {
      proxies: "",
      start: true,
    };
  },

  watch: {
    proxies(newVal) {
      if (!this.start) {
        this.editServers_Proxies({
          servers:
            this.selectedItems.length == 0 ? [this.Server] : this.selectedItems,
          proxies: newVal,
        });
      }
    },

    Server: {
      handler(val) {
        this.proxies = val.proxies;
      },
      deep: true,
    },
  },

  async beforeMount() {
    this.proxies = this.Server.proxies;

    this.$nextTick(() => {
      this.start = false;
    });
  },

  computed: {
    ...mapState(["collections"]),
  },

  methods: {
    ...mapActions(["editServers_Proxies"]),
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_colors.scss";
</style>
