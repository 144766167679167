<template>
  <div class="flex" style="">
    <v-spacer />
    <v-checkbox
      v-model="selected"
      style="margin-top: 0"
      label="yandex"
      value="mc.yandex"
      hide-details
      :dense="true"
    />
    <v-spacer />
    <v-checkbox
      v-model="selected"
      style="margin-top: 0"
      label="moat"
      value="moat"
      hide-details
      :dense="true"
    />
    <v-spacer />
    <v-checkbox
      v-model="selected"
      style="margin-top: 0"
      label="forensiq"
      value="fqtag"
      hide-details
      :dense="true"
    />
    <v-spacer />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Blocking",
  props: ["Server"],

  data() {
    return {
      selected: [],
      start: true,
    };
  },

  watch: {
    selected(val) {
      if (!this.start) {
        this.editServer_Blocking({
          server: this.Server,
          blocking: JSON.stringify(val),
        });
      }
    },
  },

  async beforeMount() {
    this.selected = JSON.parse(this.Server.blocking);

    this.$nextTick(() => {
      this.start = false;
    });
  },

  methods: {
    ...mapActions(["editServer_Blocking"]),
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_colors.scss";

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
