<template>
  <div>
    <v-snackbar v-model="success" :timeout="5000" color="success">
      Сайты удалены
    </v-snackbar>
    <v-snackbar v-model="error" :timeout="5000" color="error">
      Произошла ошибка при удалении
    </v-snackbar>

    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Delete Sites</v-card-title>
        <v-card-text>
          Удалить {{ ids.length }} {{ labels[declByNum(ids.length)] }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('update:dialog', false)"
            :disabled="loading"
            text>
            Cancel
          </v-btn>
          <v-btn @click="handleDelete" :loading="loading" color="error" text>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import declByNum from '@/resources/js/utils/declByNum.js';

export default {
  name: 'SpoofSitesNew',

  props: ['dialog', 'ids'],

  components: {},

  data() {
    return {
      labels: ['сайт', 'сайта', 'сайтов'],

      success: false,
      loading: false,
      error: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.success = false;
        this.loading = false;
        this.error = false;
      }
    },
  },

  methods: {
    ...mapActions('spoof', ['deleteSites']),
    declByNum,

    async handleDelete() {
      this.loading = true;
      try {
        await this.deleteSites(this.ids);

        this.success = true;
        this.loading = false;
        this.$emit('update:dialog', false);
      } catch (err) {
        console.log(err);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
