import axios from 'axios';
const url = 'api/';

export async function getProxiesCount({}, collection_name) {
  const res = await axios.get(url + `proxies/cnt/${collection_name}`);
  return res.data;
}

export async function getProxiesCollections({ state }) {
  const res = await axios.get(url + `proxies/collections/${state.user.login}`);

  state.collections = res.data;

  return res.data
}

export async function postProxies({}, { proxies, collection_name }) {
  const res = await axios.post(url + `proxies/`, {
    proxies,
    collection_name,
  });

  return res.data;
}

export async function addProxyCollection({ state }, { collection }) {
  try {
    const res = await axios.post(url + `proxies/add`, {
      collection,
      login: state.user.login,
    });

    return res.status;
  } catch (err) {
    return 'error';
  }
}

export async function addProxyCollections_byGroups({ state }, { groups }) {
  try {
    const res = await axios.post(url + `proxies/add_groups`, {
      groups,
      login: state.user.login,
    });

    return res.status;
  } catch (err) {
    return 'error';
  }
}

export async function editProxyCollectionMobile({}, item) {
  const res = await axios.post(url + `proxies/edit_mobile`, {
    mobile: item.mobile,
    _ids: [item._id],
  });

  return res.data;
}

export async function editProxyCollection({}, { id, collection }) {
  const res = await axios.post(url + `proxies/edit`, {
    id,
    collection,
  });

  return res.data;
}

export async function deleteProxyCollection({}, collection_name) {
  const res = await axios.delete(url + `proxies/${collection_name}`);

  return res.data;
}

export async function showProxies({ state }) {
  const res = await axios.post(url + `proxies/generate_code`, {
    user_login: state.user_login,
  });

  return res.status;
}

export async function downloadProxies({}, { code, collection_name }) {
  try {
    const res = await axios.get(
      url + `proxies/download/${code}.${collection_name}`
    );

    const blob = new Blob([res.data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `proxies - ${collection_name}.txt`;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (err) {
    console.error(err);
    throw err;
  }
}
