import axios from 'axios';
const url = 'api/yandex/';

import  {
  getIntervals,
  addInterval,
  editInterval,
  deleteInterval
} from './intervals'

import {
  getRequestsList,
  addNewRequest,
  deleteOneRequest,
  downloadOneRequest,
  editRequestionName,
  deleteOnlyOneRequest,
  editOneRequest,
  addOneRequest,
  addOrDeleteRequest
} from './requests';

import {
  getProfiles,
  getUrls,
  addProfileCollection,
  deleteProfileCollection,
  addOrDeleteProfile,
  getProxieCollections,
  editProfileCollection,
} from './profiles'

export default {
  namespaced: true,

  state: {
    _id: null,

    isReady: false,

    tasks: null,

    requests: null,

    profiles: [],
  },

  actions: {
    async getClicks({ rootState, state }, {date, id}) {
      const res = await axios.post(url + 'getClicks', { date, id });
      return res.data

      // return [10, [{title: 'Клики', stats: {1: 2, 3: 100, 40: 33}}], [{title: 'Найдено без доменов', stats: {1: 22, 3: 10, 40: 323}}], [{title: 'Доменные клики', stats: {21: 2, 33: 100, 40: 33}}]];
    },

    async getTable({ rootState, state }, {date, id}) {
      const res = await axios.post(url + 'getTable', { date, id });

      return res.data

      // return [10, [{title: 'Клики', stats: {1: 2, 3: 100, 40: 33}}], [{title: 'Найдено без доменов', stats: {1: 22, 3: 10, 40: 323}}], [{title: 'Доменные клики', stats: {21: 2, 33: 100, 40: 33}}]];
    },
    async getClicksTask({ rootState, state }, {date, id}) {
      const res = await axios.post(url + 'getClicksTask', { date, id });
      return res.data

      // return [10, [{title: 'Клики', stats: {1: 2, 3: 100, 40: 33}}], [{title: 'Найдено без доменов', stats: {1: 22, 3: 10, 40: 323}}], [{title: 'Доменные клики', stats: {21: 2, 33: 100, 40: 33}}]];
    },

    // async getTableTask({ rootState, state }, {date, id}) {
    //   const res = await axios.post(url + 'getTableTask', { date, id });
    //
    //   return res.data
    //
    //   // return [10, [{title: 'Клики', stats: {1: 2, 3: 100, 40: 33}}], [{title: 'Найдено без доменов', stats: {1: 22, 3: 10, 40: 323}}], [{title: 'Доменные клики', stats: {21: 2, 33: 100, 40: 33}}]];
    // },
    async getClicksProfile({ rootState, state }, {date, id}) {
      const res = await axios.post(url + 'getClicksProfile', { date, id });
      return res.data

      // return [10, [{title: 'Клики', stats: {1: 2, 3: 100, 40: 33}}], [{title: 'Найдено без доменов', stats: {1: 22, 3: 10, 40: 323}}], [{title: 'Доменные клики', stats: {21: 2, 33: 100, 40: 33}}]];
    },

    // async getTableProfile({ rootState, state }, {date, id}) {
    //   const res = await axios.post(url + 'getTableProfile', { date, id });
    //
    //   return res.data
    //
    //   // return [10, [{title: 'Клики', stats: {1: 2, 3: 100, 40: 33}}], [{title: 'Найдено без доменов', stats: {1: 22, 3: 10, 40: 323}}], [{title: 'Доменные клики', stats: {21: 2, 33: 100, 40: 33}}]];
    // },
    async getSearchItems({ rootState, state }) {

      const res = await axios.get(url + `getSearchItems/${state._id}`);

      return res.data

     // return [{id: "!1111", name: "aaa"},{id: "!3333", name: "bbb"},{id: "!5555", name: "vvv"}]
    },
    async getSearchProfileItems({ rootState, state }) {

      const res = await axios.get(url + `getSearchProfileItems`);

      return res.data

      // return [{id: "!1111", name: "aaa"},{id: "!3333", name: "bbb"},{id: "!5555", name: "vvv"}]
    },
    async getTasks({ rootState, state }, account) {
      if (!state._id)
        state._id =
          rootState.user.type == 'yandex'
            ? rootState._id
            : '6422d1919f27b94ae9826680';

      const res = await axios.get(url + `tasks/${state._id}`);

      state.tasks = res.data.tasks;
      state.isReady = true;
    },

    async newTask({ state, dispatch }, task) {
      try {
        await axios.post(url + 'new', { ...task, userId: state._id, });
        dispatch('getTasks');
        return 0;
      } catch (e) {
        console.error(e);
        return 1;
      }
    },
    async editTask({ state, dispatch }, task) {
      try {
        await axios.post(url + 'edit', task);
        dispatch('getTasks');
        return 0;
      } catch (e) {
        return 1;
      }
    },
    async editProxies({ state, dispatch }, { tasks, proxies }) {
      try {
        await axios.post(url + 'editProxy', {
          proxies,
          _ids: tasks.map(task => task._id),
        });
        dispatch('getTasks');
        return 0;
      } catch (e) {
        return 1;
      }
    },

    async deleteTask({ state, dispatch }, _id) {
      try {
        await axios.delete(url + _id);
        await dispatch('getTasks');
        return 0;
      } catch (e) {
        return 1;
      }
    },

    async getRequests({ rootState, state }, taskId ) {

      const res = await axios.get(url + `requestsFull/${taskId}`);
      state.requests = res.data;
      return res.data;
    },

    // async addRequest({}, { taskId, request, perDay }) {
    //   const res = await axios.post(url + `request/add/${taskId}`, {
    //     request,
    //     perDay,
    //   });
    //   return res.data;
    // },
    async editRequest({}, request) {
      const res = await axios.post(url + `request/edit`, { request });
    },
    async splitRequest({}, request) {
      const res = await axios.post(url + `request/split`, { request });
    },
    async deleteRequest({}, requestId) {
      const res = await axios.delete(url + `request/${requestId}`);

      return res.data;
    },

    getIntervals,
    addInterval,
    editInterval,
    deleteInterval,

    getRequestsList,
    addNewRequest,
    deleteOneRequest,
    downloadOneRequest,
    editRequestionName,
    deleteOnlyOneRequest,
    editOneRequest,
    addOneRequest,
    addOrDeleteRequest,

    getProfiles,
    getUrls,
    addProfileCollection,
    deleteProfileCollection,
    addOrDeleteProfile,
    getProxieCollections,

    editProfileCollection,
  },
};
