import axios from 'axios';
const url = 'api/mironov/';

export default {
  state: {
    mironovTasks: null,

    allPerDay: 0,
    monthStats: 0,
    lastMonthStats: 0,

    timeAvg: 0,
    depthAvg: 0,
  },

  actions: {
    async getMironovTasks({ rootState, state }, account) {
      const res = await axios.get(
        url + `tasks/${rootState._id}` + (account ? `/${account}` : '')
      );

      state.mironovTasks = res.data.tasks;
      state.isReady = true;

      const date = new Date();

      const tasks = (state.allPerDay = state.mironovTasks.filter(
        task => new Date(task.dateEnd) > date
      ));

      const allPerDay = tasks.reduce(
        (partialSum, a) => partialSum + a.perDay,
        0
      );

      state.allPerDay = allPerDay;

      state.monthStats = res.data.monthStats;
      state.lastMonthStats = res.data.lastMonthStats;

      const timeAvg = (
        tasks.reduce(
          (partialSum, task) =>
            partialSum + ((task.depth.min + task.depth.max) / 2) * task.time,
          0
        ) / tasks.length
      ).toFixed(2);

      state.timeAvg = timeAvg;

      const depthAvg = (
        tasks
          .map(task => (task.depth.min + task.depth.max) / 2)
          .reduce((partialSum, a) => partialSum + a, 0) / tasks.length
      ).toFixed(2);

      state.depthAvg = depthAvg;

      return [
        allPerDay,
        res.data.monthStats,
        res.data.lastMonthStats,
        timeAvg,
        depthAvg,
      ];
    },

    async newMironovTask({ rootState, dispatch }, task) {
      try {
        await axios.post(url + 'new', { ...task, userID: rootState._id });
        dispatch('getMironovTasks');
        return 0;
      } catch (e) {
        return 1;
      }
    },
    async editMironovTask({ state, dispatch }, task) {
      try {
        await axios.post(url + 'edit', task);
        dispatch('getMironovTasks');
        return 0;
      } catch (e) {
        return 1;
      }
    },
    async editTasks_Proxies({ state, dispatch }, { tasks, proxies }) {
      try {
        await axios.post(url + 'editProxy', {
          proxies,
          _ids: tasks.map(task => task._id),
        });
        dispatch('getMironovTasks');
        return 0;
      } catch (e) {
        return 1;
      }
    },

    async deleteMironovTask({ state, dispatch }, _id) {
      try {
        await axios.delete(url + _id);
        await dispatch('getMironovTasks');
        return 0;
      } catch (e) {
        return 1;
      }
    },
  },
};
