<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :rules="rules"
        :label="label"
        class="mr-5"
        persistent-hint
        readonly
        v-bind="attrs"
        v-on="on" />
    </template>
    <v-date-picker
      :model-value="date"
      @input="changeDate"
      color="primary"
      header-color="gray_800"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',

  model: {
    prop: 'date',
    event: 'change',
  },
  props: ['date', 'label', 'rules'],

  data() {
    return { menu: false };
  },

  components: {},

  computed: {
    // Форматирование даты для отображения в текстовых полях
    dateFormatted() {
      return this.formatDate(this.date);
    },
  },

  methods: {
    changeDate(newDate) {
      this.$emit('change', newDate);
      this.menu = false;
    },

    // Форматирование даты для отображения в текстовых полях
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
  },
};
</script>

<style scoped lang="scss"></style>
