<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Edit Task</span>
      </v-card-title>

      <v-form v-model="valid" ref="form">
        <v-container class="text-center">
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Название задачи"
            required
          />

          <v-text-field
            v-model.number="perDay"
            :rules="perDayRules"
            label="Количество заходов в день"
            required
            type="number"
          />

          <v-text-field
            v-model="url"
            :rules="urlRules"
            label="Ссылка"
            required
          />

          <v-text-field
            v-model.number="depth.min"
            :rules="perDayRules"
            label="Минимальная глубина"
            required
            type="number"
          />
          <v-text-field
            v-model.number="depth.max"
            :rules="perDayRules"
            label="Максимальная глубина"
            required
            type="number"
          />

          <v-text-field
            v-model.number="time"
            :rules="perDayRules"
            label="Время на сайте"
            required
            type="number"
          />

          <v-slider
            v-model.number="refusal"
            label="% Отказа"
            min="0"
            max="100"
            required
            thumb-label="always"
          />

          <v-text-field
            v-model="referer"
            :rules="refererRules"
            label="Referer"
          />

          <v-text-field v-model="selector" label="Selector" />
          <v-text-field v-model="selector_2" label="Selector 2" />

          <v-slider
            v-model.number="mobiles"
            label="% Телефонов"
            min="0"
            max="100"
            required
            thumb-label="always"
          />

          <v-menu
            v-model="menuStart"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStartFormatted"
                :rules="dateRules"
                label="Дата начала работы"
                persistent-hint
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateStart"
              @input="menuStart = false"
              color="primary"
              header-color="gray_800"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menuEnd"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEndFormatted"
                :rules="dateRules"
                label="Дата окончания работы"
                persistent-hint
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateEnd"
              @input="menuEnd = false"
              color="primary"
              header-color="gray_800"
            ></v-date-picker>
          </v-menu>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="save">OK</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import { nameRules, urlRules } from '@/resources/js/rules/cabinet.js';

export default {
  name: 'Start',

  props: ['_dialog', 'item', 'setDialog'],

  data() {
    return {
      valid: false,

      name: '',
      perDay: '',
      url: '',
      depth: { min: '', max: '' },
      time: '',
      refusal: '',
      referer: '',
      selector: '',
      selector_2: '',
      mobiles: 50,

      menuStart: false,
      menuEnd: false,

      dateStart: '',
      dateEnd: '',

      nameRules,
      urlRules: [
        v => !!v || 'Необходимо ввести url',
        v =>
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
            v
          ) || 'Неверно введен url',
      ],
      refererRules: [
        v =>
          !v ||
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
            v
          ) ||
          'Неверно введен referer',
      ],

      dateRules: [
        v => !!v || 'Необходимо выбрать дату',

        v =>
          new Date(this.dateEnd) > new Date(this.dateStart) ||
          'Дата окончания должна быть больше Даты начала',
      ],

      perDayRules: [
        v => {
          if (!isNaN(parseFloat(v)) && v >= 0) return true;
          return 'Необходимо ввести число';
        },
      ],
    };
  },

  computed: {
    dateStartFormatted() {
      return this.formatDate(this.dateStart);
    },
    dateEndFormatted() {
      return this.formatDate(this.dateEnd);
    },

    dialog: {
      get() {
        return this._dialog;
      },
      set(val) {
        this.setDialog(val);
      },
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.name = this.item.name;
        this.perDay = this.item.perDay;
        this.url = this.item.url;
        this.depth = this.item.depth;
        this.time = this.item.time;
        this.refusal = this.item.refusal;
        this.referer = this.item.referer;
        this.selector = this.item.selector;
        this.selector_2 = this.item.selector_2;
        this.mobiles = this.item.mobiles;

        this.dateStart = this.formatItemDate(
          new Date(this.item.dateStart).toLocaleDateString()
        );

        this.dateEnd = this.formatItemDate(
          new Date(this.item.dateEnd).toLocaleDateString()
        );
      } else {
        this.name = '';
        this.perDay = '';
        this.url = '';
        this.depth = '';
        this.time = '';
        this.refusal = '';
        this.referer = '';
        this.selector = '';
        this.selector_2 = '';
        this.mobiles = 50;

        this.dateStart = '';
        this.dateEnd = '';
      }
    },

    dateStart: 'validateField',
    dateEnd: 'validateField',
  },

  methods: {
    ...mapActions(['editMironovTask']),

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },

    formatItemDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('.');
      return `${year}-${month}-${day}`;
    },

    validateField() {
      if (this.$refs.form) this.$refs.form.validate();
    },

    async save() {
      this.validateField();

      if (this.valid) {
        const res = await this.editMironovTask({
          _id: this.item._id,
          name: this.name,
          perDay: this.perDay,
          url: this.url,
          depth: this.depth,
          time: this.time,
          refusal: this.refusal,
          referer: this.referer,
          selector: this.selector,
          selector_2: this.selector_2,
          mobiles: this.mobiles,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
        });

        if (!res) this.dialog = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
