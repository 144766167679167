<template>
  <div class="d-flex align-center">
    <v-text-field
      v-model.number="interval.from.h"
      @blur="handleEdit"
      :rules="hourRules"
      required
      type="number"
      dense
      outlined
      hide-spin-buttons
      hide-details >
    </v-text-field>
   <span class='mx-3'>:</span>
    <v-text-field
      v-model.number="interval.from.m"
      @blur="handleEdit"
      :rules="minuteRules"
      class='mr-6'
      required
      type="number"
      dense
      outlined
      hide-spin-buttons
      hide-details >
    </v-text-field>
    <v-text-field
      v-model.number="interval.to.h"
      @blur="handleEdit"
      :rules="hourRules"
      required
      type="number"
      dense
      outlined
      hide-spin-buttons
      hide-details >
    </v-text-field>
    <span class='mx-3'>:</span>
    <v-text-field
      v-model.number="interval.to.m"
      @blur="handleEdit"
      :rules="minuteRules"
      class='mr-6'
      required
      type="number"
      dense
      outlined
      hide-spin-buttons
      hide-details >
    </v-text-field>

    <v-text-field
      v-model.number="interval.percentage"
      @blur="handleEdit"
      :rules="numberRules"
      required
      type="number"
      dense
      outlined
      hide-spin-buttons
      hide-details />


    <v-btn @dblclick="handleDelete(interval._id)" icon>
      <v-icon title="Удалить" size="18px">mdi-delete</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import rules from '../rules';

export default {
  name: 'TaskInterval',

  props: ['interval', 'handleDelete', 'updateIntervals'],

  data() {
    return {
      ...rules(),

      saveTimeout: null,
    };
  },

  components: {},

  computed: {},

  watch: {},

  async beforeMount() {},

  async mounted() {},

  methods: {
    ...mapActions('yandex', ['editInterval']),

    handleEdit() {

      this.editInterval(this.interval);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.stats {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 65px;
  border-radius: 4px;

  border: 1px solid rgba(255, 255, 255, 0.24);
  padding: 2px 12px;

  font-size: 16px;

  .caption {
    font-size: 12px !important;
  }
}

.icon {
  cursor: pointer;
  transition: all 0.25 ease-in-out;

  &:hover {
    color: $pink;
    transform: scale(1.4);
  }
}
</style>
