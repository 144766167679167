<template>
  <div>
    <v-app-bar>
      <v-tabs v-model="tab" centered>
        <!-- <v-tab>Info</v-tab> -->
        <v-tab>Changelog</v-tab>
        <v-tab>Roadmap</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-tabs-items v-model="tab">
      <!-- <v-tab-item class="tab-outter"> <Info /> </v-tab-item> -->
      <v-tab-item style="margin-top: 10px"> <Changelog /> </v-tab-item>
      <v-tab-item style="margin-top: 10px"> <Roadmap /> </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Info from "./Info/Info";
import Changelog from "./Changelog/Changelog";
import Roadmap from "./Roadmap/Roadmap";

export default {
  name: "InfoTabs",

  data() {
    return {
      tab: 0,
    };
  },

  components: {
    // Info,
    Changelog,
    Roadmap,
  },

  computed: {},

  watch: {},

  async beforeMount() {},

  async mounted() {},

  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/_colors.scss";
.tab-outter {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>

