<template>
  <div>
    <v-btn
      @click="startCheck(item)"
      :loading="loading"
      :disabled="disabled"
      :color="checkBtnColor"
      icon
    >
      <v-icon title="Запустить проверку" class="icon">
        mdi-progress-check
      </v-icon>
    </v-btn>
    <v-btn
      @click="setAsInWork(item)"
      :disabled="disabled"
      :color="wrenchBtnColor"
      icon
    >
      <v-icon :title="wrenchBtnText" class="icon"> mdi-progress-wrench </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TableItemActions',

  props: ['item'],

  data() {
    return {};
  },

  watch: {},

  computed: {
    isChecking() {
      return this.item.checking;
    },
    isInWork() {
      return this.item.inWork;
    },

    isAvailSuccess() {
      return this.item.status == 'AvailSuccess';
    },
    isAvailFailed() {
      return this.item.status == 'AvailFailed';
    },

    loading() {
      return this.isChecking;
    },
    disabled() {
      return this.isChecking || this.isAvailSuccess;
    },

    checkBtnColor() {
      return this.isAvailFailed ? 'error' : 'white';
    },
    wrenchBtnColor() {
      return this.isInWork ? 'primary' : 'grey';
    },

    wrenchBtnText() {
      return this.isInWork
        ? 'Снять отметку "в работе"'
        : 'Поставить отметку "в работе"';
    },
  },

  methods: {
    ...mapActions('proxiesErrors', ['startCheck', 'setAsInWork']),
  },
};
</script>

<style scoped lang="scss"></style>
