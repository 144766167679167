<template>
  <v-container v-if="isReady">
    <v-data-table
      :headers="playerHeaders"
      :items="groupedData"
      item-key="player"
      class="elevation-1"
      disable-pagination
      hide-default-footer
      show-expand
      single-expand
    >
      <template v-slot:top>
        <div class="d-flex">
          <v-spacer />
          <v-btn @click="changeIsAll" icon>
            <v-icon :color="isAll ? 'primary' : 'grey'">mdi-set-all</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 0 50px">
          <v-data-table
            :headers="ipHeaders"
            :items="item.ips"
            item-key="ip"
            disable-pagination
            hide-default-footer
            show-expand
            single-expand
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                style="padding: 0 50px; padding-bottom: 25px"
              >
                <v-data-table
                  :headers="proxyHeaders"
                  :items="item.proxies"
                  item-key="proxy"
                  disable-pagination
                >
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: '',

  props: [],

  components: {},

  data() {
    return {
      isReady: false,

      playerHeaders: [
        { text: 'Player', value: 'player' },
        { text: 'Entries', value: 'entries' },
        { text: 'Shows', value: 'shows' },
        { text: 'Recycling (%)', value: 'recycling' },
      ],
      ipHeaders: [
        { text: 'IP', value: 'ip' },
        { text: 'Entries', value: 'entries' },
        { text: 'Shows', value: 'shows' },
        { text: 'Recycling (%)', value: 'recycling' },
      ],
      proxyHeaders: [
        { text: 'Proxy', value: 'proxy' },
        { text: 'Entries', value: 'entries' },
        { text: 'Shows', value: 'shows' },
        { text: 'Recycling (%)', value: 'recycling' },
      ],
    };
  },

  computed: {
    ...mapState('proxiesStats', ['groupedData', 'isAll']),
  },

  watch: {},

  async beforeMount() {
    await this.getData();

    this.isReady = true;
  },

  async mounted() {},

  methods: {
    ...mapActions('proxiesStats', ['getData', 'changeIsAll']),
  },
};
</script>

<style scoped lang="scss"></style>
