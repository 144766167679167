<template>
  <div class="main">
    <div v-if="_id == '5fbcc32523786b5cf0c4716e'">
      <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
            New Version
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">New Version</span>
          </v-card-title>
          <v-form v-model="valid" ref="form">
            <v-container class="text-center">
              <v-select
                v-model="type"
                :items="types"
                :rules="typeRules"
                label="Назначение"
                required
              ></v-select>
              <v-text-field
                v-model="version"
                :rules="versionRules"
                label="Версия"
                required
              ></v-text-field>
              <v-text-field
                v-model="date"
                :rules="dateRules"
                label="Дата"
                required
              ></v-text-field>
              <v-textarea v-model="markdown" label="Markdown"></v-textarea>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-btn text @click="close">Cancel</v-btn>
            <v-btn color="primary" text @click="save">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-tabs v-model="tab" centered>
      <v-tab>Adminer</v-tab>
      <v-tab>Worker</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="tab-outter">
        <h2>Список изменений</h2>
        <v-expansion-panels focusable inset multiple v-model="worker">
          <v-expansion-panel
            v-for="(data, index) in adminerVersions"
            :key="index + 'worker'"
          >
            <v-expansion-panel-header>
              {{ data.version }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Version :data="data" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
      <v-tab-item class="tab-outter">
        <h2>Список изменений</h2>
        <v-expansion-panels focusable inset multiple v-model="adminer">
          <v-expansion-panel
            v-for="(data, index) in workerVersions"
            :key="index + 'worker'"
          >
            <v-expansion-panel-header>
              {{ data.version }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Version :data="data" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Version from './Version';

export default {
  name: 'Changelog',

  data() {
    return {
      tab: 0,
      worker: [0],
      adminer: [0],
      workerVersions: [],
      adminerVersions: [],

      valid: false,
      dialog: false,

      type: '',
      types: ['Adminer', 'Worker'],
      typeRules: [(v) => !!v || 'Необходимо выбрать назначение'],

      version: '',
      versionRules: [
        (v) => !!v || 'Необходимо ввести номер версии',
        (v) =>
          /^[0-9]{1,}[.][0-9]{1,}[.][0-9]{1,}$/.test(v) ||
          'Неверно введена версия',
      ],

      date: '',
      dateRules: [
        (v) => !!v || 'Необходимо ввести дату',
        (v) =>
          /^(0[1-9]|1[0-9]|2[0-9]|3[01])[.](0[1-9]|1[012])[.][0-9]{4}$/.test(
            v
          ) || 'Неверно введена дата',
      ],

      markdown: '',
    };
  },

  components: { Version },

  computed: { ...mapState(['_id']) },

  watch: {},

  async beforeMount() {
    let data = await this.getVersions();

    for (let version of data) {
      if (version.type == 'Worker') this.workerVersions.push(version);
      else this.adminerVersions.push(version);
    }
  },

  async mounted() {
    this.$nextTick();
  },

  methods: {
    ...mapActions(['createVersion', 'getVersions']),

    close() {
      this.dialog = false;
      this.valid = false;
      this.type = '';
      this.version = '';
      this.date = '';
      this.markdown = '';
    },
    async save() {
      this.$refs.form.validate();
      if (this.valid) {
        let res = await this.createVersion({
          version: {
            type: this.type,
            version: this.version,
            date: this.date,
            markdown: this.markdown,
          },
        });
        if (res == '200') {
          this.close();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tab-outter {
    width: 600px;
    h2 {
      color: white;
    }
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
