<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="800px">
    <!-- Карточка для формы создания новой задачи -->
    <v-card style="min-height: 200px">
      <v-card-title>
        <span class="headline">
          Профиля для
          <b>{{ task.name }}</b>
        </span>
      </v-card-title>

      <div class="px-5 pb-5">
        <div v-for="(profile, index) in profiles" :key="index" class="mt-2">
          <TaskProfile
            :profile="profile"
            :taskProfiles='task'
            @checkbox-changed="handleCheckboxChange"
          />
        </div>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

import TaskProfile from './TaskProfile';
import profiles from '@/components/Cabinet/tabs/Yandex/Profiles.vue';

export default {
  name: 'TaskProfiles',

  data() {
    return {
      dialog: false,
      copySnackbar: false,
      loading: false,

      addingProfiles: false,

      task: null,

      profiles: [],

      newProfiles: '',
      newRequestsRules: [
        v => !!v || 'Необходимо ввести запросы',
        v => {
          if (v == undefined) return;

          const check = v.split('\n');
          let flag = true;
          for (const str of check) {
            if (!/^(.+?)#(\d+)$/.test(str)) {
              flag = false;
              break;
            }
          }
          return flag ? !!v : 'Введены некорректные данные';
        },
      ],
      addValid: false,
    };
  },

  components: { TaskProfile },

  computed: {
    textIcon() {
      return this.addingRequests ? 'mdi-text' : 'mdi-text-box-plus-outline';
    },
  },

  watch: {
    task(newVal) {
      this.dialog = !!newVal;
    },
    async dialog(newVal) {
      if (!newVal) {
        this.task = null;
        this.profiles = [];
      } else {
        await this.updateProfiles();
      }
    },
  },

  async beforeMount() {},

  async mounted() {},

  methods: {
    ...mapActions('yandex', ['getProfiles', 'addOrDeleteProfile']),

    async updateProfiles() {
      this.profiles = await this.getProfiles();
    },


    async handleCheckboxChange(data) {
      // Логика обработки изменения чекбокса
      // data.requestId - ID запроса
      // data.newValue - новое состояние чекбокса (true/false)
      await this.addOrDeleteProfile({profileId: data.profileId, val: data.newValue, taskId: data.taskId});
    },

  },
};
</script>

<style scoped lang="scss">
.addBtn {
  position: sticky;
  bottom: -1px;
  right: 0;
  left: 0;
  margin: auto;

  background-color: #1e1e1e;
}
</style>
