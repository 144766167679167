import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,

    themes: {
      dark: {
        background: '#FFFFFF',
        primary: '#E6457A',
        accent: '#40CBFF',
        secondary: '#ffe18d',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF4343',
        errorAccent: '#FF0000',
        purple: '#735ce6',

        gray_100: '#e1e3e6',
        gray_20: '#f9f9f9',
        gray_200: '#c4c8cc',
        gray_300: '#aaaeb3',
        gray_40: '#f2f3f5',
        gray_400: '#909499',
        gray_450: '#7f8285',
        gray_50: '#ebedf0',
        gray_500: '#76787a',
        gray_600: '#5d5f61',
        gray_700: '#454647',
        gray_750: '#363738',
        gray_800: '#2c2d2e',
        gray_850: '#232324',
        gray_900: '#19191a',
        gray_950: '#141414',
        gray_1000: '#0a0a0a',
      },
    },
  },
});
