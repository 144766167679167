<template>
  <div>
    <v-app-bar>
      <v-tabs v-model="tab" centered>
        <v-tab>Сайты</v-tab>
        <!-- <v-tab>VAST</v-tab> -->
      </v-tabs>
    </v-app-bar>

    <v-tabs-items v-model="tab" style="margin-top: 10px">
      <v-tab-item>
        <SpoofSites />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SpoofSites from './Sites/SpoofSites.vue';
// import SpoofVasts from './SpoofVasts.vue';

export default {
  name: 'Spoof',

  props: ['active'],

  data() {
    return { tab: 'Сайты', getSitesInterval: null };
  },

  components: { SpoofSites },

  computed: {},

  watch: {
    async active(val) {
      clearInterval(this.getSitesInterval);

      if (val) {
        await this.getSites();
        this.setInterval();
      }
    },
  },

  beforeMount() {
    this.getSites();
    this.setInterval();
  },

  beforeUnmount() {
    clearInterval(this.getSitesInterval);
  },

  deactivated() {
    clearInterval(this.getSitesInterval);
  },

  methods: {
    ...mapActions('spoof', ['getSites']),

    setInterval() {
      this.getSitesInterval = setInterval(async () => {
        const start = performance.now();

        await this.getSites();

        console.debug(
          `getSites took: ${((performance.now() - start) / 1000).toFixed(
            2
          )} sec`
        );
      }, 5000);
    },
  },
};
</script>

<style scoped lang="scss"></style>
