<template>
  <div>
  <v-dialog v-model="dialog" max-width="800px">
    <!-- Карточка для формы создания новой задачи -->
    <v-card style="min-height: 200px">
      <v-card-title>
        <span class="headline">
          Запросы для
          <b>{{ task.name }}</b>
        </span>
      </v-card-title>
      <div class="full-width-text">
      <pre>                Запрос                      Частота    Гео   Стр  Кликов сегодня/вчера</pre>
      </div>
      <div class="px-5 pb-5">
        <v-virtual-scroll
          :items="requests"
          ref="vScroll"
          height="600"
          item-height="50"
          max-width="100%">
          <template v-slot:default="{ item, index }">
            <div class="d-flex align-center">
              <!--                <v-textarea-->
              <!--                  v-model="index">-->
              <!--                  </v-textarea>-->
              <!-- Запрос-->
              <v-text-field
                v-model="item[0]"
                @focus="savePreviousValue('field1_' + index, item[0])"
                @blur="handleEdit('field1_' + index, item)"
                style="width: 200%"
                required
                dense
                outlined
                hide-details>
              </v-text-field>
              <!-- Частота-->
              <v-text-field
                v-model.number="item[1]"
                @focus="savePreviousValue('field1_' + index, item[0])"
                @blur="handleEdit('field1_' + index, item)"
                style="width: 40%"
                required
                type="number"
                dense
                outlined
                hide-spin-buttons
                hide-details />
              <!-- Гео-->
              <v-text-field
                v-model.number="item[2]"
                @focus="savePreviousValue('field1_' + index, item[0])"
                @blur="handleEdit('field1_' + index, item)"
                style="width: 40%"
                required
                type="number"
                dense
                outlined
                hide-spin-buttons
                hide-details />
              <!--  Страница-->
              <v-text-field
                v-model.number="item[3]"
                style="width: 30%"
                required
                type="number"
                dense
                outlined
                hide-spin-buttons
                hide-details
                readonly/>
              <!--  Запросы сегодня-->
              <v-text-field
                v-model.number="item[4]"
                style="width: 40%"
                required
                type="number"
                dense
                outlined
                hide-spin-buttons
                hide-details
                readonly/>
              <!--  Запросы вчера-->
              <v-text-field
                v-model.number="item[5]"
                style="width: 40%"
                required
                type="number"
                dense
                outlined
                hide-spin-buttons
                hide-details
                readonly/>
              <v-btn @dblclick="handleDelete(item[0])" icon>
                <v-icon title="Удалить" size="18px">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-virtual-scroll>
      </div>
      <div class="button-container">
        <div class="d-flex justify-center py-5 addBtn">
          <v-btn
            @click="handleAdd(task)"
            :loading="loading"
            color="primary"
            class="mr-10"
            fab
            small
          >
            <v-icon title="Добавить">mdi-plus</v-icon>
          </v-btn>
          <v-btn text @click.stop.prevent="downloadItem(task)" title="Upload">
            Выгрузить
          </v-btn>
          <v-btn text @click.stop.prevent="uploadItem()" title="Download">
            Загрузить новые
          </v-btn>
          <v-btn text @click.stop.prevent="deleteItem(task)" title="Delete">
            Удалить все запросы
          </v-btn>
        </div>
      </div>

    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogDownload" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">
        Вы уверены, что хотите выгрузить<pre> </pre>
        <span class="collection-name"><b>{{ task.name }}</b></span>
        ?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancel</v-btn>
        <v-btn color="error" text @click="downloadItemConfirm"> OK </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
    <v-dialog v-model="dialogAdd" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"> Добавить Запросы.</v-card-title>
        <p>Формат только .csv, кодировка - utf-8. </p>
        <p>Столбцы называются (первая строка так должна вглядеть) name;volume;geo</p>
        <p>Если что, можно выгрузить запросы и посмотреть, как они выглядят</p>
        <v-form v-model="addValid" ref="addForm">
          <v-container class="text-center">
            <input type="file" ref="fileInput" @change="handleFileUpload" class="grey black--text">
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" text @click="addItemConfirm">
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteAll" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Вы уверены, что хотите удалить ВСЕ запросы
          <pre> </pre>
          <span class="collection-name">{{  this.currentItem.reqName  }}</span>
          <pre> </pre>
          ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="deleteItemConfirm"> OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Papa from 'papaparse';

// import TaskRequest from './TaskRequest';

export default {
  name: 'TaskRequests',

  data() {
    return {
      dialog: false,
      copySnackbar: false,
      loading: false,
      dialogDownload: false,
      dialogAdd: false,
      addingRequests: false,
      dialogDeleteAll: false,

      task: null,

      currentItem: {},

      localRequest: "", // Локальная копия request

      originalRequests: {},
      requests: [],
      newRequests: '',
      newRequestsRules: [
        v => !!v || 'Необходимо ввести запросы',
        v => {
          if (v == undefined) return;

          const check = v.split('\n');
          let flag = true;
          for (const str of check) {
            if (!/^(.+?)#(\d+)$/.test(str)) {
              flag = false;
              break;
            }
          }
          return flag ? !!v : 'Введены некорректные данные';
        },
      ],
      addValid: false,
    };
  },

  components: {},

  computed: {
    textIcon() {
      return this.addingRequests ? 'mdi-text' : 'mdi-text-box-plus-outline';
    },
    sortedRequestMap() {
      if (!this.requests || !this.requests.requestMap) {
        return [];
      }

      // Преобразуем объект в массив, содержащий ключи и значения, и сортируем его
      return this.requests
    }
  },

  watch: {
    task(newVal) {
      this.dialog = !!newVal;
    },
    async dialog(newVal) {
      if (!newVal) {
        this.requests = [];
      } else {
        await this.updateRequests();
      }
    },
  },

  async beforeMount() {},

  async mounted() {},

  methods: {
    ...mapActions('yandex', ['getRequests', 'deleteRequest', 'addOrDeleteRequest',
      'addNewRequest',
      'deleteOneRequest',
      'downloadOneRequest',
      'editRequestionName',
      'deleteOnlyOneRequest',
      'addOneRequest',
      'editOneRequest']),

    async updateRequests() {

      this.requests = await this.getRequests(this.task._id);
    },


    savePreviousValue(key, value) {
      // Сохраняем текущее значение поля перед его изменением
      this.originalRequests[key] = value;

    },

    async handleEdit(key, item) {
      const previousValue = this.originalRequests[key];
      this.localRequest = item[0].trim();
      let geo = parseInt(item[2], 10)
      if (isNaN(geo)) {
        geo = -1
      }
      this.currentItem = await this.editOneRequest({originalReq: previousValue, request: this.localRequest, volume: item[1], geo: geo, page: item[3], todayWalk: item[4], currentId: this.currentItem._id, taskId: this.task._id});
    },

    async handleFileUpload(event) {
      const file = event.target.files[0]; // Получаем первый выбранный файл
      const reader = new FileReader();
      reader.onload = async (event) => {
        // Используем TextDecoder для декодирования в 'windows-1252'
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(event.target.result);

        const csvData = text; // Теперь у нас есть содержимое CSV файла в виде строки

        // Создаем локальную переменную для доступа к Vue компоненту внутри функции Papa.parse
        const vm = this;

        Papa.parse(csvData, {
          delimiter: ";",
          header: true,
          complete: function(results) {
            const hashMap = {};

            results.data.forEach(row => {
              if (Object.keys(row).length === 2 || Object.keys(row).length === 3) {
                const name = row.name;
                let volume = 0
                if (row.volume != "") {
                  volume = parseInt(row.volume);
                }

                if (!isNaN(volume) && Number.isInteger(volume) && volume >= 0) {
                  const data = { volume };
                  if (Object.keys(row).length === 3) {
                    data.geo = row.geo;
                  } else {
                    data.geo = -1
                  }

                  hashMap[name] = data;
                }
              }
            });

            vm.parsedData = hashMap;
          }
        });
      };

      // Читаем файл как ArrayBuffer для последующего декодирования
      reader.readAsArrayBuffer(file);
    },
    async addItemConfirm() {

      await this.addNewRequest({ taskId: this.task._id, parsedFile: this.parsedData} );
      this.dialogAdd = false;
      this.requests = await this.getRequests(this.task._id);
    },

    async close() {

      this.dialogAdd = false;
      this.dialogEdit = false;
      this.dialogDeleteAll = false;
      this.dialogAdd_area = false;
      this.dialog_show = false;
      this.dialogDownload = false;

      this.currentItem = {};
      this.reqName = '';
      this.code = '';
    },

    async downloadItem() {
      this.dialogDownload = true;
    },

    async uploadItem() {
      this.dialogAdd = true;
    },


    async downloadItemConfirm() {
      const file = await this.downloadOneRequest(this.task._id);

      // Создание ссылки для скачивания
      const blob = new Blob([file], { type: 'text/csv' }); // Тип файла - CSV
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.csv'); // Имя файла для скачивания
      document.body.appendChild(link);
      link.click();

      // Освобождаем ресурсы
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      this.close();
    },
    async deleteItem(item) {
      this.currentItem = item;
      this.dialogDeleteAll = true;
    },
    async deleteItemConfirm() {
      await this.deleteOneRequest(this.task._id);
      this.requests = await this.getRequests(this.task._id);
      this.close();
    },

    async handleDelete(key) {
      await this.deleteOnlyOneRequest({ name: key, id: this.task._id });
      this.requests = await this.getRequests(this.task._id);
    },
    async handleAdd() {
      await this.addOneRequest({ taskId: this.task._id});
      this.requests = await this.getRequests(this.task._id);
    },


  },
};
</script>

<style scoped lang="scss">
.addBtn {
  position: sticky;
  bottom: -1px;
  right: 0;
  left: 0;
  margin: auto;

  background-color: #1e1e1e;
}
</style>
