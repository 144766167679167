<template>
  <div class="outer">
    <div v-for="(account, index) in accounts" :key="index">
      <Account :account="account" />
    </div>
  </div>
</template>

<script>
import Account from './Account';

export default {
  name: 'Mironov',

  components: { Account },

  data() {
    return {
      accounts: ['Mironov', 'Guru'],
    };
  },

  computed: {},
};
</script>

<style scoped lang="scss">
.outer {
  display: flex;
  justify-content: center;

  gap: 50px;
}
</style>
