<template>
  <div>
    <v-spacer />
    <v-select
      v-model="proxies"
      :items="collections.map(item => item.collection_name)"
      label="Коллекция прокси"
      required
    >
    </v-select>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MironovProxy',
  props: ['Task', 'selectedItems'],

  data() {
    return {
      proxies: '',
      start: true,
    };
  },

  watch: {
    proxies(newVal) {
      if (!this.start) {
        this.editTasks_Proxies({
          tasks:
            this.selectedItems.length == 0 ? [this.Task] : this.selectedItems,
          proxies: newVal,
        });
      }
    },

    Task: {
      handler(val) {
        this.proxies = val.proxies;
      },
      deep: true,
    },
  },

  async beforeMount() {
    this.proxies = this.Task.proxies;

    this.$nextTick(() => {
      this.start = false;
    });
  },

  computed: {
    ...mapState(['collections']),
  },

  methods: {
    ...mapActions(['editTasks_Proxies']),
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/_colors.scss';
</style>
