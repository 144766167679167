<template>
  <v-dialog v-model="dialog" v-if="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">
        Вы уверены, что хотите удалить
        <span class="server-name">Задачу {{ deletingItem.name }}</span> ?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancel</v-btn>
        <v-btn
          color="error"
          text
          @click="confirm"
          :loading="deletingId == deletingItem._id">
          OK
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DeleteDialog',

  props: ['deletingItem', 'deletingId', 'selectedItems'],

  data() {
    return {
      dialog: false,
    };
  },

  components: {},

  computed: {},

  watch: {},

  async beforeMount() {},

  async mounted() {},

  methods: {
    ...mapActions('yandex', ['deleteTask']),

    async confirm() {
      if (this.selectedItems.length > 1) {
        this.$emit('update:deletingId', 'length');

        for (let task of this.selectedItems) {
          await this.deleteTask(task._id);
        }
      } else {
        this.$emit('update:deletingId', this.deletingItem._id);

        await this.deleteTask(this.deletingItem._id);
      }
      this.$emit('update:deletingId', null);
      this.$emit('update:deletingItem', null);
      this.dialog = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.$emit('update:deletingItem', null);
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
