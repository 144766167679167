export default [
  {
    text: 'Домен',
    value: 'site',
    align: 'center',
  },
  {
    text: 'Количество ссылок',
    value: 'urls',
    align: 'center',
  },
  {
    text: 'Добавлен',
    value: 'createdAt',
    divider: true,
    align: 'center',
  },

  {
    text: 'Status',
    value: 'status',
    width: 120,
    align: 'center',
    divider: true,
  },
  {
    text: 'hosts',
    value: 'hosts',
    align: 'center',
    divider: true,
  },
  {
    text: 'Actions',
    value: 'actions',
    align: 'center',
    sortable: false,
    width: 150,
  },
];
