<template>
  <v-container v-if="isReady">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-text>
          <v-text-field v-model="search" class="search" autofocus />

          <div class="logins">
            <v-btn
              v-for="login in filteredLogins"
              :key="login"
              @click="handleCheckLogin(login)"
            >
              <span>{{ login }}</span>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar>
      <v-tabs v-model="tab" centered>
        <v-btn
          @click="openDialog"
          :loading="loading"
          color="error"
          icon
          class="checkLogin"
        >
          <v-icon :size="22"> mdi-arm-flex </v-icon>
        </v-btn>
        <v-tab
          v-for="(_, groupName) in groupedData"
          :key="groupName"
          :tab-value="groupName"
        >
          {{ groupName }}
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-tabs-items v-model="tab" style="margin-top: 10px">
      <v-tab-item
        v-for="(collection, index) in groupedData"
        :key="index + 'tab'"
        :value="index"
      >
        <v-data-table
          v-model="_selectedItems"
          :items="collection"
          :headers="headers"
          :single-select="false"
          item-key="_id"
          class="elevation-1 table"
          disable-pagination
          hide-default-footer
          show-select
          :group-by="['inWork', 'status']"
          :custom-group="groupFunc"
        >
          <template v-slot:group.header="{ items, isOpen, toggle, group }">
            <th colspan="2" class="group">
              <v-btn @click="toggle" icon>
                <v-icon :size="22">
                  {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon
                >
              </v-btn>
              <span style="font-size: 14px; font-weight: normal">
                {{ group }}
              </span>
            </th>
          </template>

          <template v-slot:[`item.data`]="{ item }">
            <TableItemData :item="item" />
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <TableItemActions :item="item" />
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import TableItemData from './TableItemDataText.vue';
import TableItemActions from './TableItemActions.vue';

export default {
  name: 'Errors',

  props: [],

  components: { TableItemData, TableItemActions },

  data() {
    return {
      checkInterval: null,

      isReady: false,
      tab: 'All',

      headers: [
        { text: 'Proxy', value: 'data', divider: true },
        { text: 'Actions', value: 'actions' },
      ],

      dialog: false,
      search: '',
      loading: false,
    };
  },

  computed: {
    ...mapState('proxiesErrors', ['selectedItems', 'groupedData', 'logins']),

    _selectedItems: {
      get() {
        return this.selectedItems;
      },
      set(val) {
        this.setSelectedItems(val);
      },
    },

    filteredLogins() {
      return this.logins.filter(login =>
        login.toLowerCase().startsWith(this.search.toLowerCase())
      );
    },
  },

  watch: {},

  async beforeMount() {
    await this.getData();

    this.isReady = true;
  },

  mounted() {
    this.checkInterval = setInterval(async () => {
      await this.getData();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.checkInterval);
  },

  methods: {
    ...mapMutations('proxiesErrors', ['setSelectedItems']),
    ...mapActions('proxiesErrors', ['getData', 'checkLogin']),

    groupFunc(items) {
      const groups = { failed: [], 'in work': [], done: [] };

      for (const item of items) {
        if (item.inWork) groups['in work'].push(item);
        else if (item.status == 'AvailSuccess') groups['done'].push(item);
        else groups['failed'].push(item);
      }

      const formattedGroups = Object.keys(groups).map(groupName => {
        if (groups[groupName].length)
          return {
            name: groupName,
            items: groups[groupName],
          };
      });

      return formattedGroups.filter(item => item);
    },

    openDialog() {
      this.dialog = true;
    },

    async handleCheckLogin(login) {
      this.loading = true;
      this.dialog = false;

      await this.checkLogin(login);

      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  width: 960px;
  margin: auto;

  .AvailSuccess {
    text-decoration: line-through;
    color: grey;
  }
}

.checkLogin {
  margin: auto 0 auto;
}

.search {
  position: sticky;
}

.logins {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

@import '@/assets/_colors.scss';

.group {
  background-color: #272727;
  border-right: thin solid rgba(255, 255, 255, 0.12) !important;
}
</style>
